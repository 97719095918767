import React, { useState, useEffect } from "react";
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import ProfileIcon from "../../components/profile/ProfileIcon";
import './networkStyle/network.css';
import { useNavigate } from "react-router-dom";

const NetworkRole = ({ role, currentUserId }) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!role) return;

    // Fetch users by role (Exclude current user for recommendations)
    const roleQuery = query(
      collection(fireDB, "users"),
      where("role", "==", role)
    );

    const unsubscribe = onSnapshot(
      roleQuery,
      (snapshot) => {
        const fetchedUsers = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Filter out the current user's profile by matching user.id with currentUserId
        const filteredUsers = fetchedUsers.filter((user) => user.id !== currentUserId);

        // Optional: Apply further filtering or ranking for better recommendations
        // Example: recommend users with rank >= 5
        const recommendedUsers = filteredUsers.filter((user) => user.rank >= 5);

        // If no recommended users found, fallback to showing all filtered users
        setUsers(recommendedUsers.length > 0 ? recommendedUsers : filteredUsers);

        setLoading(false);
      },
      (error) => {
        console.error("Error fetching users by role:", error);
        setLoading(false);
      }
    );

    return () => {
      unsubscribe();
    };
  }, [role, currentUserId]); // Re-run when role or currentUserId changes

  if (loading) {
    return <p>Loading recommended profiles...</p>;
  }

  if (users.length === 0) {
    return <p>No recommended profiles found with the role {role}</p>;
  }

  return (
    <ul className="network-user-list">
      {users.map((user) => (
        <li key={user.id} className="network-user-item">
          <div className="network-user-profile"
         
                 
                  onClick={() =>
                    user.id &&
                    navigate(
                      `/profile/${user.name
                        .replace(/\s+/g, "")
                        .toLowerCase()}/${user.id}`
                    )
                  }
                >
            <ProfileIcon
              name={user.name || "Anonymous"}
              profileImage={user.profileImage || ""}
              className="network-profile-icon"
            />
            <p className="network-user-name">
              {user.name || `User ${user.id}`}
              <span className="user-rank">
                (Rank: {user.rank || "N/A"})
              </span>
            </p>
          </div>
        </li>
      ))}
    </ul>
  );
};

export default NetworkRole;
