import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { fireDB } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { timeAgo } from "../../../utils/Time";
import { useAuth } from "../../../hooks/useAuth";
import Spinner from "../../../spinner/Spinner";
import "./performance.css";
import RecommendationPost from "../../../components/recommendation/RecommendationPost";

const MostAgree = () => {
  const { userNames } = useAuth();
  const [trendingData, setTrendingData] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Calculate the timestamp for 7 days ago
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  // Ranking Calculation (Net Score: Agree - Disagree)
  const calculateRankScore = (agree, disagree, createdAt) => {
    const netScore = agree - disagree;
    const hoursSinceCreation =
      (Date.now() - createdAt.toMillis()) / (1000 * 60 * 60);
    return netScore / Math.pow(hoursSinceCreation + 2, 1.8); // Decay factor for recency
  };

  // Fetch questions with agree/disagree counts from the last 7 days
  const fetchMostAgreed = async () => {
    try {
      const limitPerPage = 1;
      let queryRef = query(
        collection(fireDB, "factQuestions"),
        where("createdAt", ">=", sevenDaysAgo),
        orderBy("createdAt", "desc"),
        limit(limitPerPage)
      );

      if (lastVisible) {
        queryRef = query(
          collection(fireDB, "factQuestions"),
          where("createdAt", ">=", sevenDaysAgo),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(limitPerPage)
        );
      }

      const snapshot = await getDocs(queryRef);

      if (snapshot.empty) {
        setHasMore(false); // No more data to fetch
        return;
      }

      const data = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const itemData = doc.data();
          const agree = itemData.facts?.agree || 0;
          const disagree = itemData.facts?.disagree || 0;
          const createdAt = itemData.createdAt;
          const rankScore = calculateRankScore(agree, disagree, createdAt);

          // Fetch user details including profileImage
          const userSnapshot = await getDocs(
            query(
              collection(fireDB, "users"),
              where("userId", "==", itemData.userId)
            )
          );
          const userData = userSnapshot.empty
            ? { name: "Unknown User", profileImage: "" }
            : userSnapshot.docs[0].data();

          return {
            id: doc.id,
            ...itemData,
            agree,
            disagree,
            rankScore,
            user: {
              name: userData.name,
              profileImage: userData.profileImage || "",
            }, // Add profileImage
          };
        })
      );

      // Sort by rankScore
      data.sort((a, b) => b.rankScore - a.rankScore);

      setTrendingData((prev) => [...prev, ...data]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load trending questions. Please try again later.");
    }
  };

  useEffect(() => {
    fetchMostAgreed();
  }, []);

  const handleNavigate = (id) => {
    navigate(`/fact/${id}`);
  };

  return (
    <div className="performance-page">
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div>
          <InfiniteScroll
            dataLength={trendingData.length}
            next={fetchMostAgreed}
            hasMore={hasMore}
            loader={<Spinner />}
            endMessage={
              <p className="end-message">You have seen all the posts!</p>
            }
          >
            <div className="trending-list">
              {trendingData.length > 0 ? (
                trendingData.map((item) => (
                  <div
                    key={item.id}
                    className="trending-card"
                    onClick={() => handleNavigate(item.id)}
                  >
                    <div className="card-header">
                      <ProfileIcon
                        name={item.user.name}
                        profileImage={item.user.profileImage || ""} // Use profileImage from the user object
                        className="profile-icon"
                      />
                      <span className="user-name">{item.user.name}</span>
                      <span className="created-at">
                        {item.createdAt?.seconds
                          ? timeAgo(new Date(item.createdAt.seconds * 1000))
                          : "Unknown Date"}
                      </span>
                    </div>
                    <div className="card-body">
                      <p
                        className="post-title"
                        style={{
                          fontSize: "14px",
                       
                          color: "#14171a",
                         
                        }}
                      >
                        {item.fact || "No Title"}
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="popularity">
                        <strong>Agree:</strong> {item.agree} |{" "}
                        <strong>Disagree:</strong> {item.disagree} |{" "}
                        <strong>Net Score:</strong> {item.agree - item.disagree}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-data-message">No trending questions found.</p>
              )}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default MostAgree;
