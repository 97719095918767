import React, { useState } from "react";
import {
  FaShareAlt,
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaLinkedin,
  FaTelegram,
  FaReddit,
  FaEnvelope,
} from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  RedditShareButton,
  EmailShareButton,
} from "react-share";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
// import '../componentsStyle/quizShare.css';

const PollShare = ({
  poll,
  toggleShareDropdown,
  shareDropdownOpen,
  setPolls,
}) => {
  const [isSharing, setIsSharing] = useState(false);

  // Handle share action and update Firebase
  const handleShare = async (pollId) => {
    if (isSharing) return; // Prevent multiple clicks
    setIsSharing(true);

    try {
      const pollRef = doc(fireDB, "pollQuestions", pollId);

      // Optimistic update locally
      setPolls((prevPolls) =>
        prevPolls.map((p) =>
          p.id === pollId ? { ...p, shares: p.shares + 1 } : p
        )
      );

      // Update Firestore
      await updateDoc(pollRef, {
        shares: poll.shares + 1,
      });
      console.log("Share count updated in Firestore.");
    } catch (error) {
      console.error("Error updating share count:", error);
    } finally {
      setIsSharing(false);
    }
  };

  // Share options including social media and email
  const shareOptions = [
    {
      Button: FacebookShareButton,
      url: `https://xnetous.com/poll/${poll.id}`,
      Icon: FaFacebook,
    },
    {
      Button: TwitterShareButton,
      url: `https://xnetous.com/poll/${poll.id}`,
      Icon: FaTwitter,
    },
    {
      Button: WhatsappShareButton,
      url: `https://xnetous.com/poll/${poll.id}`,
      Icon: FaWhatsapp,
    },
    {
      Button: LinkedinShareButton,
      url: `https://xnetous.com/poll/${poll.id}`,
      Icon: FaLinkedin,
    },
    {
      Button: TelegramShareButton,
      url: `https://xnetous.com/poll/${poll.id}`,
      Icon: FaTelegram,
    },
    {
      Button: RedditShareButton,
      url: `https://xnetous.com/poll/${poll.id}`,
      Icon: FaReddit,
    },
    {
      Button: EmailShareButton,
      url: `https://xnetous.com/poll/${poll.id}`,
      Icon: FaEnvelope,
    },
  ];

  return (
    <div className="share-container">
      {/* Share Button */}

      <div
        className="share-button"
        onClick={() => toggleShareDropdown(poll.id)}
      >
        <FaShareAlt size={16} />
        <span style={{ fontSize: "15px" }} className="share-count">
          {poll.shares}
        </span>
      </div>

      {/* Share Dropdown */}
      {shareDropdownOpen === poll.id && (
        <div className="share-dropdown">
          {shareOptions.map(({ Button, url, Icon }, index) => (
            <div key={index} className="share-option">
              {/* Correct usage of Button component from react-share */}
              <Button url={url} onClick={() => handleShare(poll.id)}>
                <Icon className="share-option-icon" />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default PollShare;
