import React from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { signOut } from "firebase/auth";
import { auth } from "../config/firebase";  // Correctly importing your initialized `auth`

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      // Attempt to sign out the user
      await signOut(auth);
      toast.success("Logged out successfully", { autoClose: 3000 });
      navigate("/login");  // Redirect after successful logout
    } catch (error) {
      console.error("Logout error:", error);
      toast.error(`Failed to log out: ${error.message}`, { autoClose: 3000 });
    }
  };

  return (
    <div className="logout-container">
      <h1>Logout</h1>
      <button onClick={handleLogout} className="logout-button">
        Logout
      </button>
    </div>
  );
};

export default Logout;
