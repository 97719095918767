import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { fireDB } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { timeAgo } from "../../../utils/Time";
import { useAuth } from "../../../hooks/useAuth";
import Spinner from "../../../spinner/Spinner";
import "./performance.css";

const MostVoted = () => {
  const { userNames } = useAuth();
  const [votes, setVotes] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Calculate the timestamp for 7 days ago
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

  // Ranking Calculation (Net Score: YesVotes - NoVotes)
  const calculateRankScore = (yesVotes, noVotes, createdAt) => {
    const netScore = yesVotes - noVotes;
    const hoursSinceCreation =
      (Date.now() - createdAt.toMillis()) / (1000 * 60 * 60);
    return netScore / Math.pow(hoursSinceCreation + 2, 1.8); // Decay factor for recency
  };

  // Fetch voted questions from the last 7 days
  const fetchVotes = async () => {
    try {
      const limitPerPage = 1;
      let queryRef = query(
        collection(fireDB, "voteQuestions"),
        where("createdAt", ">=", sevenDaysAgo),
        orderBy("createdAt", "desc"),
        limit(limitPerPage)
      );

      if (lastVisible) {
        queryRef = query(
          collection(fireDB, "voteQuestions"),
          where("createdAt", ">=", sevenDaysAgo),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(limitPerPage)
        );
      }

      const snapshot = await getDocs(queryRef);

      if (snapshot.empty) {
        setHasMore(false); // No more data to fetch
        return;
      }

      const data = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const itemData = doc.data();
          const yesVotes = itemData.options?.yes || 0;
          const noVotes = itemData.options?.no || 0;
          const createdAt = itemData.createdAt;
          const rankScore = calculateRankScore(yesVotes, noVotes, createdAt);

          // Fetch user details including profileImage
          const userSnapshot = await getDocs(
            query(collection(fireDB, "users"), where("userId", "==", itemData.userId))
          );
          const userData = userSnapshot.empty
            ? { name: "Unknown User", profileImage: "" }
            : userSnapshot.docs[0].data();

          return {
            id: doc.id,
            ...itemData,
            yesVotes,
            noVotes,
            rankScore,
            user: { name: userData.name, profileImage: userData.profileImage || "" }, // Add profileImage
          };
        })
      );

      // Sort by rankScore
      data.sort((a, b) => b.rankScore - a.rankScore);

      setVotes((prev) => [...prev, ...data]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to load voted questions. Please try again later.");
    }
  };

  useEffect(() => {
    fetchVotes();
  }, []);

  const handleNavigate = (id) => {
    navigate(`/vote/${id}`);
  };

  return (
    <div className="performance-page">
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div>
          <InfiniteScroll
            dataLength={votes.length}
            next={fetchVotes}
            hasMore={hasMore}
            loader={<Spinner />}
            endMessage={<p className="end-message">You have seen all the votes!</p>}
          >
            <div className="trending-list">
              {votes.length > 0 ? (
                votes.map((vote) => (
                  <div
                    key={vote.id}
                    className="trending-card"
                    onClick={() => handleNavigate(vote.id)}
                  >
                    <div className="card-header">
                      <ProfileIcon
                        name={vote.user.name}
                        profileImage={vote.user.profileImage || ""}
                        className="profile-icon"
                      />
                      <span className="user-name">{vote.user.name}</span>
                      <span className="created-at">
                        {vote.createdAt?.seconds
                          ? timeAgo(new Date(vote.createdAt.seconds * 1000))
                          : "Unknown Date"}
                      </span>
                    </div>
                    <div className="card-body">
                      <p className="post-title" style={{
                          fontSize: "15px",
                          color: "#14171a",
                        }}>{vote.vote || "No Title"}</p>
                    </div>
                    <div className="card-footer">
                      <div className="popularity">
                        <strong>Yes Votes:</strong> {vote.yesVotes} |{" "}
                        <strong>No Votes:</strong> {vote.noVotes} |{" "}
                        <strong>Net Score:</strong> {vote.yesVotes - vote.noVotes}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-data-message">No voted posts found.</p>
              )}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default MostVoted;
