import { useState } from "react";
import { FaComment, FaTrashAlt } from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import * as toxicity from "@tensorflow-models/toxicity";
import "./commentStyle/comment.css"; // Import the CSS file

const VoteComment = ({ vote, userId, isAuthenticated, updateVote }) => {
  const [newComment, setNewComment] = useState("");
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [localComments, setLocalComments] = useState(vote.comments || []);
  const [isLoadingToxicity, setIsLoadingToxicity] = useState(false);
  const maxCommentLength = 40;

  const checkToxicity = async (comment) => {
    setIsLoadingToxicity(true);
    try {
      const threshold = 0.9;
      const model = await toxicity.load(threshold);
      const predictions = await model.classify([comment]);
      const isToxic = predictions.some(
        (p) => p.results[0].match === true
      );
      return isToxic;
    } catch (error) {
      console.error("Error checking toxicity:", error);
      return false;
    } finally {
      setIsLoadingToxicity(false);
    }
  };

  const handleAddComment = async (voteId, comment) => {
    if (comment.trim() === "") return;
    if (comment.length > maxCommentLength) return;

    const isToxic = await checkToxicity(comment);
    if (isToxic) {
      alert("Your comment was flagged as toxic. Please modify it.");
      return;
    }

    const voteRef = doc(fireDB, "voteQuestions", voteId);
    const newComments = [...localComments, comment];

    try {
      await updateDoc(voteRef, {
        comments: newComments,
        commentCount: newComments.length,
      });

      setLocalComments(newComments);
      if (updateVote) {
        updateVote(voteId, newComments, "comments");
      }
      setNewComment("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleDeleteComment = async (voteId, commentIndex) => {
    const voteRef = doc(fireDB, "voteQuestions", voteId);
    const updatedComments = localComments.filter(
      (_, index) => index !== commentIndex
    );

    try {
      await updateDoc(voteRef, {
        comments: updatedComments,
        commentCount: updatedComments.length,
      });

      setLocalComments(updatedComments);
      if (updateVote) {
        updateVote(voteId, updatedComments, "comments");
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const toggleComments = () => {
    setIsCommentOpen(!isCommentOpen);
  };

  return (
    <div>
      <div className="comment-toggle" onClick={toggleComments}>
        <FaComment size={15} />
        <span>{isCommentOpen ? "hide" : "view"}</span>
        <span>({localComments.length})</span>
      </div>

      {isCommentOpen && (
        <div className="comment-section">
          {localComments.map((comment, index) => (
            <div key={index} className="comment">
              <p className="comment-text">{comment}</p>
              {isAuthenticated && (
                <FaTrashAlt
                  className="comment-delete"
                  onClick={() => handleDeleteComment(vote.id, index)}
                  size={18}
                />
              )}
            </div>
          ))}

          {isAuthenticated && (
            <div className="add-comment">
              <input
                type="text"
                value={newComment}
                onChange={(e) =>
                  setNewComment(e.target.value.slice(0, maxCommentLength))
                }
                placeholder="Write your comment here..."
              />
              <p className="remaining-characters">
                {maxCommentLength - newComment.length} characters remaining
              </p>
              <button
                onClick={() => handleAddComment(vote.id, newComment)}
                disabled={!newComment.trim() || isLoadingToxicity}
              >
                {isLoadingToxicity ? "Checking..." : "Add"}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default VoteComment;
