import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { useAuth } from "../../hooks/useAuth";
import ProfileIcon from "../../components/profile/ProfileIcon";
import { useNavigate } from "react-router-dom";
import "./networkStyle/network.css";

const Network = () => {
  const { userId, userNames } = useAuth(); // Current user's ID and names mapping
  const [users, setUsers] = useState([]); // All users
  const [removedUsers, setRemovedUsers] = useState([]); // Users removed by current user
  const [searchTerm, setSearchTerm] = useState(""); // Search term state
  const [locationFilter, setLocationFilter] = useState(""); // Location filter
  const [roleFilter, setRoleFilter] = useState(""); // Role filter
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) return;

    // Fetch all users
    const topUsersQuery = query(collection(fireDB, "users"));
    const unsubscribe = onSnapshot(topUsersQuery, (snapshot) => {
      const fetchedUsers = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((user) => user.id !== userId); // Exclude current user

      setUsers(fetchedUsers);
    });

    // Fetch removed users list
    const userRef = doc(fireDB, "users", userId);
    const unsubscribeRemoved = onSnapshot(userRef, (snapshot) => {
      const data = snapshot.data();
      setRemovedUsers(data?.removedUsers || []);
    });

    return () => {
      unsubscribe();
      unsubscribeRemoved();
    };
  }, [userId]);

  // Filter users based on search term, location, role, and removed users
  const filteredUsers = users.filter(
    (user) =>
      user.name?.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (!locationFilter ||
        user.location?.toLowerCase().includes(locationFilter.toLowerCase())) &&
      (!roleFilter ||
        user.role?.toLowerCase().includes(roleFilter.toLowerCase())) &&
      !removedUsers.includes(user.id) // Exclude removed users
  );

  // Remove User Functionality
  const handleRemoveUser = async (removedUserId) => {
    try {
      const userRef = doc(fireDB, "users", userId);
      await updateDoc(userRef, {
        removedUsers: arrayUnion(removedUserId),
      });
      setRemovedUsers((prev) => [...prev, removedUserId]);
    } catch (error) {
      console.error("Error removing user:", error);
    }
  };

  // Navigate to user profile
  const handleProfileClick = (userId, userName) => {
    const username =
      userNames[userId]?.replace(/\s+/g, "").toLowerCase() || "user";
    navigate(`/profile/${username}/${userId}`);
  };

  return (
    <div className="network-container">
      <h2 className="network-header">Find and Connect with People</h2>

      {/* Search Bar */}
      <div className="search-container">
        <input
          type="text"
          placeholder="🔍 Search users... 👤 "
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="search-input"
        />
      </div>

      {/* Filters for Location and Role */}
      <div className="filters-container">
        <input
          type="text"
          placeholder="🔍 Filter by role..."
          value={roleFilter}
          onChange={(e) => setRoleFilter(e.target.value)}
          className="filter-input"
        />
      </div>

      {/* User List */}
      {filteredUsers.length === 0 ? (
        <p className="no-users-message">No users found</p>
      ) : (
        <ul className="network-user-list">
          {filteredUsers.map((user) => (
            <li key={user.id} className="network-user-item">
              <div
                className="network-user-profile"
                onClick={() => handleProfileClick(user.id, user.name)}
              >
                <ProfileIcon
                  name={user.name || "Anonymous"}
                  profileImage={user.profileImage || ""}
                  className="network-profile-icon"
                />
                <div className="network-user-details">
                  <p className="network-user-name">
                    {user.name || `User ${user.id}`}
                  </p>
                  <p className="network-user-role">{user.role}</p>
                </div>
              </div>

              {/* Actions Section with Remove Button */}
              <div>
                <button
                  className="network-button"
                  onClick={() => handleRemoveUser(user.id)}
                >
                  Remove
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Network;
