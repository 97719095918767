import React, { useEffect, useState, useMemo } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth"; // Import useAuth hook

function ProfilePoll() {
  const { currentUser, loading: authLoading } = useAuth();
  const [postedPolls, setPostedPolls] = useState([]);
  const [loading, setLoading] = useState(true);  // Loading for fetching polls
  const [error, setError] = useState(null); // Error state

  useEffect(() => {
    if (!authLoading) {
      if (currentUser) {
        fetchPostedPolls(currentUser);
      } else {
        setLoading(false); // Stop loading if user is not logged in
      }
    }
  }, [currentUser, authLoading]); // Dependencies ensure it triggers only when authLoading or currentUser changes

  const fetchPostedPolls = async () => {
    setLoading(true);  // Start loading when fetching starts
    setError(null);  // Reset any previous errors

    try {
      const pollsQuery = query(
        collection(fireDB, "pollQuestions"),
        where("userId", "==", currentUser.uid)  // Filter polls based on userId
      );
      const pollsSnapshot = await getDocs(pollsQuery);
      const pollsList = pollsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPostedPolls(pollsList);  // Set the fetched polls
    } catch (error) {
      console.error("Error fetching polls:", error);
      setError("Failed to fetch polls. Please try again.");
      toast.error("Failed to fetch polls. Please try again.");
    } finally {
      setLoading(false);  // Finish loading once data is fetched or an error occurs
    }
  };

  const calculateEngagementScore = (poll) => {
    return (
      (poll.likes || 0) * 1.5 +
      ((poll.comments?.length || 0) * 1.2) +
      (poll.shares || 0) * 1.3
    );
  };

  const totalEngagements = useMemo(() => {
    return postedPolls.reduce((total, poll) => total + calculateEngagementScore(poll), 0);
  }, [postedPolls]);

  const highlightedPolls = useMemo(() => {
    return postedPolls.filter((poll) => {
      if (!poll.highlighted || !poll.highlightedAt) return false;
      const highlightedAt = poll.highlightedAt?.toMillis?.();
      if (!highlightedAt) return false;
      return (Date.now() - highlightedAt) / (1000 * 60 * 60) <= 72;
    });
  }, [postedPolls]);

  const pinnedPolls = useMemo(() => {
    return postedPolls.filter((poll) => poll.pinned === true);
  }, [postedPolls]);

  // 🛠 FIX: Loading condition now also includes authLoading check before rendering
  if (authLoading || loading) {
    return <Spinner />;  // Show spinner until both auth and data loading is complete
  }

  // Error handling
  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="posted-poll-container">
      <div className="total-stats">
        <h3>Total Polls: {postedPolls.length}</h3>
        <h3>Total Engagements: {totalEngagements.toFixed(2)}</h3>
      </div>

      <div className="pinned-polls">
        <h3>Pinned Polls</h3>
        {pinnedPolls.length > 0 ? (
          <ul className="polls-list">
            {pinnedPolls.map((poll) => (
              <li key={poll.id} className="poll-item pinned">
                <div className="poll-details">
                  <p><strong>Poll:</strong> {poll.question}</p>
                  <p><strong>Options:</strong> {poll.options?.join(", ") || "No options provided"}</p>
                  <div className="poll-performance">
                    <p><i className="fa fa-thumbs-up"></i> {poll.likes || 0} Likes</p>
                    <p><i className="fa fa-share-alt"></i> {poll.shares || 0} Shares</p>
                    <p><i className="fa fa-comment"></i> {poll.comments?.length || 0} Comments</p>
                    <p><strong>Engagement Score:</strong> {calculateEngagementScore(poll).toFixed(2)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No pinned polls available.</p>
        )}
      </div>

      <div className="highlighted-polls">
        <h3>Highlighted Polls (Last 72 Hours)</h3>
        {highlightedPolls.length > 0 ? (
          <ul className="polls-list">
            {highlightedPolls.map((poll) => (
              <li key={poll.id} className="poll-item highlighted">
                <div className="poll-details">
                  <p><strong>Poll:</strong> {poll.question}</p>
                  <p><strong>Options:</strong> {poll.options?.join(", ") || "No options provided"}</p>
                  <div className="poll-performance">
                    <p><i className="fa fa-thumbs-up"></i> {poll.likes || 0} Likes</p>
                    <p><i className="fa fa-share-alt"></i> {poll.shares || 0} Shares</p>
                    <p><i className="fa fa-comment"></i> {poll.comments?.length || 0} Comments</p>
                    <p><strong>Engagement Score:</strong> {calculateEngagementScore(poll).toFixed(2)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No highlighted polls available in the last 72 hours.</p>
        )}
      </div>
    </div>
  );
}

export default ProfilePoll;
