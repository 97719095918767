import { createContext, useState, useEffect, useContext } from "react";
import { useAuth } from "../hooks/useAuth";
import { doc, getDoc, setDoc } from "firebase/firestore"; // Firestore functions
import { fireDB } from "../config/firebase"; // Firestore instance

const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  const { currentUser } = useAuth(); // Get the current user
  const [theme, setTheme] = useState(() => {
    // Initialize from localStorage or default to "light"
    return localStorage.getItem("theme") || "light";
  });

  // Fetch user-specific theme preference from Firestore
  useEffect(() => {
    if (currentUser) {
      const fetchTheme = async () => {
        try {
          const themeDoc = await getDoc(doc(fireDB, "users", currentUser.uid));
          if (themeDoc.exists() && themeDoc.data().theme) {
            const userTheme = themeDoc.data().theme;
            setTheme(userTheme);
            localStorage.setItem("theme", userTheme); // Sync to localStorage
          }
        } catch (error) {
          console.error("Error fetching theme preference:", error);
        }
      };

      fetchTheme();
    }
  }, [currentUser]);

  // Save theme preference to Firestore and localStorage when it changes
  useEffect(() => {
    localStorage.setItem("theme", theme); // Save to localStorage

    if (currentUser) {
      const saveTheme = async () => {
        try {
          await setDoc(
            doc(fireDB, "users", currentUser.uid),
            { theme },
            { merge: true } // Merge with existing user data
          );
        } catch (error) {
          console.error("Error saving theme preference:", error);
        }
      };

      saveTheme();
    }
  }, [theme, currentUser]);

  // Apply theme class to <body> dynamically
  useEffect(() => {
    document.body.classList.remove("light-mode", "dark-mode");
    document.body.classList.add(theme === "light" ? "light-mode" : "dark-mode");
  }, [theme]);

  // Function to toggle theme
  const toggleTheme = () => {
    setTheme((prevTheme) => (prevTheme === "light" ? "dark" : "light"));
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => useContext(ThemeContext);
