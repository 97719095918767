import React, { useState } from "react";
import { FaRegThumbsUp, FaRegThumbsDown, FaFlag } from "react-icons/fa"; // Importing icons
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { fireDB } from "../../../config/firebase"; // Firebase configuration
import { toast } from "react-toastify"; // Toast notifications
import ViewTrack from "../../../hooks/useTrack";

const PollOption = ({ pollId, userId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  // Toggle the menu (show/hide the three dots menu)
  const toggleMenu = () => setIsOpen((prev) => !prev);

  // Handle "Like" action
  const handleLike = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const pollRef = doc(fireDB, "pollQuestions", pollId);
      await updateDoc(pollRef, {
        likedBy: arrayUnion(userId),
      });
      toast.success("You liked this poll.");
    } catch (error) {
      toast.error("Failed to like. Please try again.");
    } finally {
      setIsProcessing(false);
      setIsOpen(false);
    }
  };

  // Handle "Dislike" action
  const handleDislike = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const pollRef = doc(fireDB, "pollQuestions", pollId);
      await updateDoc(pollRef, {
        dislikedBy: arrayUnion(userId),
      });
      toast.success("You disliked this poll.");
    } catch (error) {
      toast.error("Failed to dislike. Please try again.");
    } finally {
      setIsProcessing(false);
      setIsOpen(false);
    }
  };

  // Handle "Report" action
  const handleReport = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const reason = prompt("Please provide a reason for reporting:");
      if (!reason) {
        toast.error("Reporting requires a reason.");
        return;
      }
      const pollRef = doc(fireDB, "pollQuestions", pollId);
      await updateDoc(pollRef, {
        reportedBy: arrayUnion(userId),
        reportReasons: arrayUnion(reason),
      });
      toast.success("This poll has been reported.");
    } catch (error) {
      toast.error("Failed to report the poll. Please try again.");
    } finally {
      setIsProcessing(false);
      setIsOpen(false);
    }
  };

  return (
    <div>
      {/* Button to toggle the three dots menu */}
      <div
        style={{ cursor: "pointer" }}
        onClick={toggleMenu}
        disabled={isProcessing}
      >
        ...
      </div>

      {/* If the menu is open, show options */}
      {isOpen && (
        <div className="menu">
          <div onClick={handleLike} disabled={isProcessing} className="menu-button">
            <FaRegThumbsUp /> Like
          </div>
          <div onClick={handleDislike} disabled={isProcessing} className="menu-button">
            <FaRegThumbsDown /> Dislike
          </div>
          <div onClick={handleReport} disabled={isProcessing} className="menu-button">
            <FaFlag /> Report
          </div>
          <div className="menu-button">
            <ViewTrack pollId={pollId} />
          </div>
        </div>
      )}
    </div>
  );
};

export default PollOption;
