import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { Chart } from "react-google-charts";
import Spinner from "../../spinner/Spinner";

// Helper function to classify emotions (replace with real logic/model)
const classifyEmotion = (text) => {
  const randomEmotions = ["Happy", "Neutral", "Sad", "Angry", "Stressed"];
  return randomEmotions[Math.floor(Math.random() * randomEmotions.length)];
};

const UserHealth = () => {
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [emotionStats, setEmotionStats] = useState({});
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Fetch posts from different collections (e.g., Fact, Poll, Quiz)
        const [factSnapshot, pollSnapshot, voteSnapshot, quizSnapshot] = await Promise.all([
          getDocs(query(collection(fireDB, "factQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "pollQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "voteQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "quizQuestions"), orderBy("createdAt", "desc"))),
        ]);
  
        const processSnapshot = (snapshot, type) =>
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            type,
          }));
  
        const [facts, polls, votes, quizzes] = [
          processSnapshot(factSnapshot, "Fact"),
          processSnapshot(pollSnapshot, "Poll"),
          processSnapshot(voteSnapshot, "Vote"),
          processSnapshot(quizSnapshot, "Quiz"),
        ];
  
        const combinedData = [...facts, ...polls, ...votes, ...quizzes];
  
        // Optimize user profile fetching
        const userIds = [...new Set(combinedData.map((item) => item.userId).filter(Boolean))];
        const userProfiles = await Promise.all(
          userIds.map((id) =>
            getDoc(doc(fireDB, "users", id)).then((snapshot) =>
              snapshot.exists() ? { id, ...snapshot.data() } : null
            )
          )
        );
  
        const userMap = Object.fromEntries(
          userProfiles.filter(Boolean).map((user) => [user.id, user])
        );
  
        const dataWithUsers = combinedData.map((item) => ({
          ...item,
          user: userMap[item.userId] || { name: "Unknown User", profileImage: "" },
        }));
  
        // Classify emotions for each post
        const postsWithEmotions = dataWithUsers.map((item) => ({
          ...item,
          emotion: classifyEmotion(item.fact || item.poll || item.vote || item.quiz || ""),
        }));

        setAllData(postsWithEmotions);

        // Calculate emotion statistics
        const stats = postsWithEmotions.reduce((acc, post) => {
          acc[post.emotion] = (acc[post.emotion] || 0) + 1;
          return acc;
        }, {});
        setEmotionStats(stats);

      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Prepare chart data
  const chartData = [
    ["Emotion", "Count"],
    ...Object.entries(emotionStats),
  ];

  return (
    <div className="user-health">
      <h2>Mental Health Insights</h2>

      {loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <>
          <div className="emotion-stats">
            <Chart
              chartType="PieChart"
              data={chartData}
              width={"100%"}
              height={"400px"}
              legendToggle
            />
          </div>

          <div className="suggestions">
            <h3>Suggestions</h3>
            {emotionStats["Sad"] > 2 || emotionStats["Stressed"] > 2 ? (
              <p>
                We’ve noticed some posts that might reflect stress or sadness. Remember, it’s okay to seek help. Consider exploring the following resources:{" "}
                <a
                  href="https://www.mentalhealth.gov/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Mental Health Resources
                </a>
              </p>
            ) : (
              <p>Your posts seem positive. Keep up the good work!</p>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default UserHealth;
