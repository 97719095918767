import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import ProfileIcon from "../profile/ProfileIcon";
import { useAuth } from "../../hooks/useAuth";
import './connectionStyle/followers.css'

const Followers = ({ userId }) => {
  const { user, loading: authLoading, error: authError } = useAuth(); // Use useAuth for authentication state
  const [followers, setFollowers] = useState([]);
  const [followersCount, setFollowersCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showFollowers, setShowFollowers] = useState(false); // Toggle state for displaying followers list

  const fetchFollowers = async () => {
    if (authLoading || !userId) return; // Wait for auth to load

    setLoading(true);
    setError(null);

    try {
      const userRef = doc(fireDB, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        const followersList = userData.followers || [];
        setFollowersCount(followersList.length);

        // Fetch follower details concurrently
        const followersDetails = await Promise.all(
          followersList.map(async (followerId) => {
            try {
              const followerRef = doc(fireDB, "users", followerId);
              const followerSnap = await getDoc(followerRef);
              if (followerSnap.exists()) {
                return { id: followerId, ...followerSnap.data() };
              }
            } catch (fetchError) {
              console.error(`Error fetching data for follower ID ${followerId}:`, fetchError);
            }
            return null;
          })
        );

        setFollowers(followersDetails.filter(Boolean));
      } else {
        setError("User data not found.");
      }
    } catch (fetchError) {
      console.error("Error fetching followers:", fetchError);
      setError("Failed to load followers. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const toggleFollowers = () => {
    if (!showFollowers) {
      // Only fetch followers if they are not already visible
      fetchFollowers();
    }
    setShowFollowers((prev) => !prev);
  };

  if (authLoading) return <p>Loading authentication...</p>;
  if (authError) return <p className="error-message">Authentication Error: {authError}</p>;

  return (
    <div>
      <p onClick={toggleFollowers}>
        Followers ({followersCount})
      </p>
      {showFollowers && (
        <div>
          {loading ? (
            <p>Loading followers...</p>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : (
            <ul>
              {followers.length > 0 ? (
                followers.map((follower) => (
                  <li key={follower.id}>
                    <div className="follower-icons">
                      <ProfileIcon
                        name={follower.name || "Anonymous"}
                        className="profile-icon"
                      />
                      <span>{follower.name || "No Name"}</span>
                    </div>
                  </li>
                ))
              ) : (
                <p>No followers yet</p>
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default Followers;


