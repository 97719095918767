// ClickTracking.js
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { fireDB } from "../config/firebase";

const ClickTracking = async (adId) => {
  try {
    const adRef = doc(fireDB, "ads", adId);
    const adDoc = await getDoc(adRef);
    if (adDoc.exists()) {
      // Increment the click count
      const newClickCount = adDoc.data().clicks + 1;
      await updateDoc(adRef, { clicks: newClickCount });
    }
  } catch (error) {
    console.error("Error tracking ad click:", error);
  }
};

export default ClickTracking;
