import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { collection, doc, setDoc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { fireDB } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase";
import "./post.css";

function PostPoll({ user, pollToEdit, setEditingPoll }) {
  const [poll, setPoll] = useState(""); // Poll question input
  const [category, setCategory] = useState(""); // Category input
  const [options, setOptions] = useState(["", ""]); // Two fixed options
  const [image, setImage] = useState(null); // Image for the poll
  const [imagePreview, setImagePreview] = useState(null); // Image preview for the poll
  const [loading, setLoading] = useState(false); // Loading state
  const [link, setLink] = useState(""); // Link input
  const [showPreview, setShowPreview] = useState(false); // Show preview toggle

  useEffect(() => {
    if (user && pollToEdit) {
      setPoll(pollToEdit.poll);
      setCategory(pollToEdit.category);
      setOptions(pollToEdit.options || ["", ""]); // Ensure two options exist
      setImage(pollToEdit.image || null);
      setImagePreview(pollToEdit.image || null); // Set the image preview
      setLink(pollToEdit.link || ""); // Set link if editing
    }
  }, [user, pollToEdit]);

  const handlePollSubmit = async (e) => {
    e.preventDefault();

    if (!poll || !category || options.some((option) => option.trim() === "")) {
      toast.error("Please enter a poll, category, and both options.");
      return;
    }

    if (poll.length > 220) {
      toast.error("Poll question cannot exceed 220 characters.");
      return;
    }

    if (options.some((option) => option.length > 30)) {
      toast.error("Each option cannot exceed 30 characters.");
      return;
    }

    setLoading(true);
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      const pollData = {
        poll,
        category,
        options,
        createdAt: new Date(),
        userId: currentUser.uid,
        votes: [0, 0], // Initialize votes for both options
        image: image || "",
        link: link || null, // Store link if provided
      };

      if (pollToEdit) {
        const pollRef = doc(fireDB, "pollQuestions", pollToEdit.id);
        await updateDoc(pollRef, pollData);
        toast.success("Poll updated successfully!");
        setEditingPoll(null);
      } else {
        const pollRef = doc(collection(fireDB, "pollQuestions"));
        await setDoc(pollRef, pollData);
        toast.success("Poll posted successfully!");
      }

      // Reset all states after posting
      setPoll("");
      setCategory("");
      setOptions(["", ""]);
      setImage(null);
      setImagePreview(null); // Reset image preview after posting
      setLink(""); // Reset link after submission
    } catch (error) {
      console.error("Error during poll submission:", error);
      toast.error("Failed to post poll.");
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = [...options];
    updatedOptions[index] = value;
    setOptions(updatedOptions);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      toast.error("Please upload a valid image file.");
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size should not exceed 2MB.");
      return;
    }

    // Display image preview before uploading
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result); // Set the preview image to the state
    };
    reader.readAsDataURL(file);

    // Upload image after preview
    const storageRef = ref(storage, `pollImages/${Date.now()}-${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        toast.error("Image upload failed.");
        console.error("Image upload error:", error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setImage(downloadURL); // Update with final uploaded image URL
        toast.success("Image uploaded successfully!");
      }
    );
  };

  const handleShowPreviewToggle = () => {
    setShowPreview(!showPreview);
  };

  return (
    <div className="container">
      <h3 className="post-header">
        {pollToEdit ? "Edit Poll" : "Create a New Poll"}
      </h3>
      <form onSubmit={handlePollSubmit} className="post-form">
        <div className="form-group">
          <label htmlFor="poll" className="form-label">
            Poll Question
          </label>
          <textarea
            id="poll"
            value={poll}
            onChange={(e) => setPoll(e.target.value)}
            placeholder="Enter your poll (max 220 characters)"
            maxLength={220}
            className="text-area"
          />
        </div>

        <div className="form-group">
          <label htmlFor="category" className="form-label">
            Category
          </label>
          <input
            type="text"
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)} // Ensure category is set directly
            placeholder="Enter a category"
            maxLength={50}
            className="input-field"
          />
        </div>

        <div className="form-group">
          <label>Options (max 30 characters per option)</label>
          {options.map((option, index) => (
            <input
              key={index}
              type="text"
              value={option}
              onChange={(e) => handleOptionChange(index, e.target.value)}
              placeholder={`Option ${index + 1}`}
              maxLength={30}
              className="input-field"
            />
          ))}
        </div>

        <div className="form-group">
          <label>Upload Image (Optional)</label>
          <input type="file" accept="image/*" onChange={handleImageChange} />
        </div>

        {imagePreview && (
          <div className="image-preview">
            <img src={imagePreview} alt="Uploaded Preview" className="image" />
            <button
              type="button"
              onClick={() => {
                setImage(null);
                setImagePreview(null);
              }}
              className="remove-button"
            >
              Remove Image
            </button>
          </div>
        )}

        <div className="form-group">
          <label className="form-label">
            <input
              type="checkbox"
              checked={showPreview}
              onChange={() => setShowPreview(!showPreview)}
              className="input-checkbox"
            />
            Show Preview
          </label>
        </div>
        {showPreview && (
          <div className="preview">
            <h4>Preview:</h4>
            <p>
              <strong>Poll Question:</strong> {poll}
            </p>
            <p>
              <strong>Category:</strong> {category}
            </p>
            <p>
              <strong>Option:</strong> {options}
            </p>
            <ul>
              {options.map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ul>
            {imagePreview && (
              <img
                src={imagePreview}
                alt="Preview"
                style={{ width: "200px", borderRadius: "8px" }}
              />
            )}
            {link && (
              <p>
                <strong>Link:</strong>{" "}
                <a href={link} target="_blank" rel="noopener noreferrer">
                  {link}
                </a>
              </p>
            )}
          </div>
        )}
        <button type="submit" className="post-button" disabled={loading}>
          {loading ? "Posting..." : pollToEdit ? "Update Fact" : "Post Fact"}
        </button>
      </form>
    </div>
  );
}

export default PostPoll;
