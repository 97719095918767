import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';

const SubscriptionStatus = ({ isSubscribed }) => {
    //console.log("Subscription status in SubscriptionStatus component:", isSubscribed); // Debugging log
  
    return (
      <div className="subscription-status">
        {isSubscribed ? (
          <FaCheckCircle color="green" size={24} title="Subscribed" />
        ) : (
          <p>Not Subscribed</p>
        )}
      </div>
    );
  };

export default SubscriptionStatus;
