import React, { useEffect, useState } from "react";
import { collection, query, onSnapshot } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import "./chatStyle/userList.css";
import ProfileIcon from "../../components/profile/ProfileIcon";

const UserList = () => {
  const { userId } = useAuth();
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userId) return;

    const usersQuery = query(collection(fireDB, "users"));
    const unsubscribe = onSnapshot(usersQuery, (snapshot) => {
      const fetchedUsers = snapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((user) => user.id !== userId);
      setUsers(fetchedUsers);
    });

    return () => unsubscribe();
  }, [userId]);

  const startChat = (otherUserId) => {
    if (!userId || !otherUserId) return;
    const chatId =
      userId < otherUserId ? `${userId}_${otherUserId}` : `${otherUserId}_${userId}`;
    navigate(`/chat/${chatId}`);
  };

  return (
    <div className="user-list-container">
      <h2 className="user-list-header">Start a Conversation</h2>
      {users.length === 0 ? (
        <p className="no-users-message">No users found</p>
      ) : (
        <ul className="user-list-items">
          {users.map((user) => (
            <li key={user.id} className="user-item" onClick={() => startChat(user.id)}>
              <div className="user-name">
                  <ProfileIcon name={user.name} profileImage={user.profileImage} />
               {user.name || `User ${user.id}`}{" "}
              </div>
             
              <p className="user-name">
              
                {user.online ? (
                  <span className="online-indicator">🟢 Online</span>
                ) : (
                  <span className="offline-indicator">🔴 Offline</span>
                )}
              </p>
             
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default UserList;
