import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import debounce from "lodash.debounce";
import ProfileIcon from "../../components/profile/ProfileIcon";
import { timeAgo } from "../../utils/Time";
import "./explore.css";
import Spinner from "../../spinner/Spinner";
import fetchData from "./ExploreNLP"; // Import the new module

const Explore = () => {
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [categories, setCategories] = useState([]);
  const [visibleCategories, setVisibleCategories] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [error, setError] = useState(null);
  const [emotionStats, setEmotionStats] = useState({});
  
  const navigate = useNavigate();
  const location = useLocation();

  const CATEGORIES_BATCH_SIZE = 6;

  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      category: params.get("category") || "",
      type: params.get("type") || "",
      id: params.get("id") || "",
    };
  };

  const { category, type, id } = getQueryParams();

  useEffect(() => {
    const fetchDataAndFilter = async () => {
      const filtered = await fetchData(category, type, id, setLoading, setError, setAllData, setEmotionStats, setCategories, setVisibleCategories, CATEGORIES_BATCH_SIZE);
      setFilteredData(filtered);
    };
  
    fetchDataAndFilter();
  }, [category, type, id]);

  const filteredResults = useMemo(() => {
    return allData.filter((item) => {
      const searchInFields = [item.vote, item.poll, item.quiz, item.fact]
        .filter(Boolean)
        .map((field) => field.toLowerCase());

      return (
        (!searchTerm ||
          searchInFields.some((field) => field.includes(searchTerm))) &&
        (!categoryFilter || item.category === categoryFilter)
      );
    });
  }, [allData, searchTerm, categoryFilter]);

  useEffect(() => {
    setFilteredData(filteredResults);
  }, [filteredResults]);

  const handleSearch = debounce((e) => {
    setSearchTerm(e.target.value.toLowerCase());
  }, 300);

  const handleCategoryChange = (selectedCategory) => {
    setCategoryFilter(selectedCategory);

    const queryParams = new URLSearchParams({
      category: selectedCategory,
      type: "",
      id: "",
    }).toString();

    navigate(`/explore?${queryParams}`);
  };

  const handleNavigate = (id, type, category) => {
    const queryParams = new URLSearchParams({
      category,
      type,
      id,
    }).toString();

    navigate(`/${type.toLowerCase()}/${id}?${queryParams}`);
  };

  const loadMoreCategories = () => {
    setVisibleCategories((prev) =>
      categories.slice(0, prev.length + CATEGORIES_BATCH_SIZE)
    );
  };

  const loadLessCategories = () => {
    setVisibleCategories((prev) =>
      prev.length > CATEGORIES_BATCH_SIZE
        ? prev.slice(0, prev.length - CATEGORIES_BATCH_SIZE)
        : prev
    );
  };

  const chartData = [
    ["Emotion", "Count"],
    ...Object.entries(emotionStats),
  ];

  return (
    <div className="explore-page">
      <div className="search-filter-section">
        <input
          type="text"
          placeholder="🔍 Search..."
          onChange={handleSearch}
          className="search-bar"
        />
        <div className="category-filter-container">
          {visibleCategories.map((category) => (
            <span
              key={category}
              className={`category-filter ${
                categoryFilter === category ? "active" : ""
              }`}
              onClick={() => handleCategoryChange(category)}
            >
              {category}
            </span>
          ))}
          <div className="category-buttons">
            {visibleCategories.length < categories.length && (
              <div className="load-more-button" onClick={loadMoreCategories}>
                more
              </div>
            )}
            {visibleCategories.length > CATEGORIES_BATCH_SIZE && (
              <div className="load-less-button" onClick={loadLessCategories}>
                Less
              </div>
            )}
          </div>
        </div>
      </div>

      {loading ? (
        <div className="loader">
          <Spinner />
        </div>
      ) : error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div className="explore-list">
          {filteredData.length > 0 ? (
            filteredData
              .slice(0, searchTerm ? filteredData.length : 10)
              .map((item) => (
                <div
                  key={item.id}
                  className="explore-data-card"
                  onClick={() => handleNavigate(item.id, item.type, item.category)}
                >
                  <div className="explore-card-header">
                    <ProfileIcon
                      name={item.user.name || "Anonymous"}
                      profileImage={item.user.profileImage || ""}
                      className="profile-icon"
                    />
                    <div className="explore-header-details">
                      <span className="user-name">
                        {item.user.name || "Anonymous"}
                      </span>
                      <span className="created-at">
                        {timeAgo(new Date(item.createdAt.seconds * 1000))}
                      </span>
                    </div>
                  </div>
                  <p className="explore-post-title">
                    {item.fact || item.poll || item.vote || item.quiz || "No Title"}
                  </p>
                  <p className="post-emotion">Emotion: {item.emotion}</p>
                </div>
              ))
          ) : (
            <p className="no-data-message">
              No data found matching your search or filter criteria.
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Explore;







































