// AdsComponent.js
import React from 'react';
import AdForm from './AdsForm';


const AdsComponent = () => {
    return (
        <div className="ads-component">
            <h2>Create an Ad</h2>
            <AdForm />
        </div>
    );
};

export default AdsComponent;