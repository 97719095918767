// components/ViewTrack.js
import React, { useState, useEffect } from "react";
import { MdAddChart} from "react-icons/md";  // Changed to MdVisibility
import { doc, getDoc, updateDoc, increment } from "firebase/firestore";
import { fireDB } from "../config/firebase";

const trackView = async (factId) => {
  try {
    const factRef = doc(fireDB, "factQuestions", factId);
    await updateDoc(factRef, {
      views: increment(1),
    });
    console.log(`View tracked for fact question ${factId}`);
    return true;
  } catch (error) {
    console.error("Error tracking view: ", error);
    return false;
  }
};

const ViewTrack = ({ factId }) => {
  const [viewCount, setViewCount] = useState(0);

  useEffect(() => {
    const fetchViewCount = async () => {
      try {
        const factRef = doc(fireDB, "factQuestions", factId);
        const docSnapshot = await getDoc(factRef);
        if (docSnapshot.exists()) {
          setViewCount(docSnapshot.data().views || 0);
        }
      } catch (error) {
        console.error("Error fetching view count: ", error);
      }
    };

    fetchViewCount();
  }, [factId]);

  const handleView = async () => {
    const success = await trackView(factId);
    if (success) {
      setViewCount((prev) => prev + 1);
    }
  };

  return (
    <div onClick={handleView} style={{ cursor: "pointer", display: "flex", alignItems: "center" }}>
      <MdAddChart style={{ marginRight: "8px" }} />
      <span>{viewCount}</span> Impression
    </div>
  );
};

export default ViewTrack;
