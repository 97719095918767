import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuth } from "../../hooks/useAuth";
import { AiFillLike, AiOutlineLike } from "react-icons/ai"; // Import like icons
import { useSpring, animated } from "@react-spring/web"; // Import react-spring

const LikeFact = ({ factId, initialLikes, initialLikedByUser }) => {
  const { user } = useAuth();
  const [likes, setLikes] = useState(initialLikes); // Fact's current like count
  const [hasLiked, setHasLiked] = useState(initialLikedByUser); // Check if the user already liked the fact

  // Animation for the like count and button
  const [likeCountAnimation, setLikeCountAnimation] = useSpring(() => ({
    opacity: 0,
    transform: "scale(0.5)",
  }));

  const [buttonAnimation, setButtonAnimation] = useSpring(() => ({
    color: "black",
  }));

  useEffect(() => {
    const checkIfLiked = async () => {
      if (user) {
        const factRef = doc(fireDB, "factQuestions", factId);
        const factDoc = await getDoc(factRef);
        const factData = factDoc.data();

        // Check if the user's ID is in the likedBy array
        if (factData.likedBy && factData.likedBy.includes(user.uid)) {
          setHasLiked(true);
        }
      }
    };

    checkIfLiked();
  }, [user, factId]);

  const handleFactLike = async () => {
    if (!user) {
      toast.error("You must be logged in to like.");
      return;
    }

    if (hasLiked) {
      toast.error("You've already liked this fact.");
      return;
    }

    try {
      const factRef = doc(fireDB, "factQuestions", factId);
      const factDoc = await getDoc(factRef);
      const factData = factDoc.data();

      // Add user ID to the likedBy array
      const updatedLikedBy = [...(factData.likedBy || []), user.uid];
      const updatedLikes = updatedLikedBy.length;

      await updateDoc(factRef, {
        likes: updatedLikes,
        likedBy: updatedLikedBy,
      });

      // Trigger the animation and update the local state
      setLikes(updatedLikes);
      setHasLiked(true);

      // Animate the like count and button when liked
      setLikeCountAnimation({
        opacity: 1,
        transform: "scale(1)",
        config: { tension: 150, friction: 10 },
      });

      // Update the button color to red when liked
      setButtonAnimation({
        color: "red",
      });
    } catch (error) {
      toast.error("Error liking the fact. Please try again.");
    }
  };

  return (
    <div className="like-fact-container">
      <animated.div
        className={`like-button ${hasLiked ? "liked" : ""}`}
        onClick={handleFactLike}
        disabled={hasLiked} // Disable the button if already liked
        style={{
          color: hasLiked ? "red" : buttonAnimation.color, // Apply red color when liked
          cursor: hasLiked ? "not-allowed" : "pointer", // Change cursor when button is disabled
        }}
      >
        {hasLiked ? (
          <AiFillLike size={18} style={{ marginTop: '20px' }} />

        ) : (
          <AiOutlineLike size={19} />
        )}
      </animated.div>
      
      {/* Animated Like Count */}
      <animated.span style={likeCountAnimation}>
        {likes} {likes === 1 ? "like" : "likes"}
      </animated.span>
    </div>
  );
};

export default LikeFact;
