// RoundCircleSpinner.js
import React from 'react';
import './spinner.css';

const Spinner = () => {
  return (
    <div className="round-circle-spinner-container">
      <div className="round-circle-spinner">
        <div className="circle-segment1"></div>
        <div className="circle-segment2"></div>
        <div className="circle-segment3"></div>
        <div className="circle-segment4"></div>
      
      </div>
     
    </div>
  );
};

export default Spinner;
