import React, { useState, useEffect } from "react";
import Spinner from "../../../spinner/Spinner";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { useAuth } from "../../../hooks/useAuth";
import { timeAgo } from "../../../utils/Time";
import { doc, getDoc, updateDoc, increment } from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import VoteChart from "./VoteChart";
import VoteLike from "../../../components/like/VoteLike";
import VoteRetweet from "../../../components/retweet/VoteRetweet";
import VoteComment from "../../../components/comment/VoteComment";
import "../detailPage.css";
import VoteShare from "../../../components/share/VoteShare";
import PostRecommendation from "../../../components/recommendation/PostRecommendation";

const VoteDetail = () => {
  const { id } = useParams(); // Get vote question ID from URL params
  const { user, loading: userLoading } = useAuth();
  const [voteDetail, setVoteDetail] = useState(null);
  const [hasVoted, setHasVoted] = useState(false); // Track if the user has voted
  const [shareDropdownOpen, setShareDropdownOpen] = useState(null);
  const navigate = useNavigate();

  // Fetch vote details from the database
  const fetchVoteDetail = async () => {
    try {
      const docRef = doc(fireDB, "voteQuestions", id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const userDoc =
          data.userId && (await getDoc(doc(fireDB, "users", data.userId)));

        const user = userDoc?.exists()
          ? { id: data.userId, ...userDoc.data() }
          : { name: "Unknown User", profileImage: "" };

        const vote = {
          id: docSnapshot.id,
          vote: data.vote,
          image: data.image || "", // Include the image field
          createdAt: data.createdAt,
          user,
          yesCount: data.Yes || 0,
          noCount: data.No || 0,
          votes: data.votes || {},
          likes: data.likes || 0,
          retweets: data.retweets || [],
          comments: data.comments || [],
        };

        setVoteDetail(vote);
      } else {
        toast.error("Vote question not found.");
      }
    } catch (error) {
      toast.error("Failed to fetch vote question. Please try again.");
    }
  };

  useEffect(() => {
    fetchVoteDetail();
  }, [id]);

  // Handle vote option
  const handleVote = async (option) => {
    if (!user) {
      toast.error("You must be logged in to vote.");
      return;
    }

    if (hasVoted) {
      toast.error("You have already voted on this question.");
      return;
    }

    try {
      const voteField = option === "Yes" ? "Yes" : "No";
      const voteRef = doc(fireDB, "voteQuestions", id);

      // Update the vote count for the selected option
      await updateDoc(voteRef, { [voteField]: increment(1) });

      // Update the vote counts locally
      setVoteDetail((prevDetail) => ({
        ...prevDetail,
        [voteField.toLowerCase() + "Count"]: prevDetail[voteField.toLowerCase() + "Count"] + 1,
      }));

      setHasVoted(true); // Mark user as having voted
      toast.success(`You voted: ${option}`);
    } catch (error) {
      console.error("Error updating vote:", error);
      toast.error("Failed to cast your vote. Please try again.");
    }
  };

  // Render vote options section
  const renderVotingSection = () => (
    <div className="options-container">
      <button
        className="option-button"
        onClick={() => handleVote("Yes")}
        disabled={hasVoted}
      >
        Yes ({voteDetail.yesCount})
      </button>
      <button
        className="option-button"
        onClick={() => handleVote("No")}
        disabled={hasVoted}
      >
        No ({voteDetail.noCount})
      </button>
    </div>
  );

  const toggleShareDropdown = (factId) => {
    setShareDropdownOpen(shareDropdownOpen === factId ? null : factId);
  };

  // Render engagement section
  const renderEngagementSection = () => (
    <div className="home-post-footer-icons">
      <VoteLike voteId={voteDetail.id} initialLikes={voteDetail.likes} />
      <VoteRetweet
        voteId={voteDetail.id}
        initialRetweets={voteDetail.retweets}
      />
      <VoteComment vote={voteDetail} isAuthenticated={!!user} />
      <VoteShare
        voteId={voteDetail.id}
        vote={voteDetail}
        toggleShareDropdown={() => setShareDropdownOpen(!shareDropdownOpen)}
        shareDropdownOpen={shareDropdownOpen}
      />
    </div>
  );

  // Render chart section if the user has voted
  const renderChartSection = () =>
    hasVoted && (
      <VoteChart
        yes={voteDetail.yesCount}
        no={voteDetail.noCount}
      />
    );

  if (userLoading) return <Spinner />; // Loading spinner while user data is being fetched

  if (!voteDetail) return <p>Loading...</p>; // Loading message if vote details are not fetched yet

  return (
    <div className="detail-home-post-card">
      <div className="home-post-header">
        <div
          className="user-avatar"
          onClick={() => {
            voteDetail.user.id &&
              navigate(
                `/profile/${voteDetail.user.name
                  .replace(/\s+/g, "")
                  .toLowerCase()}/${voteDetail.user.id}`
              );
          }}
        >
          <ProfileIcon
            name={voteDetail.user.name}
            profileImage={voteDetail.user.profileImage}
          />
        </div>
  
        <div className="user-details">
          <span className="user-name">{voteDetail.user.name}</span>
          <span className="post-timestamp">
            {timeAgo(new Date(voteDetail.createdAt.seconds * 1000))}
          </span>
        </div>
      </div>
  
      {/* Post Content */}
      <p className="detail-home-post-content">{voteDetail.vote}</p>
  
      {/* Image Section */}
      {voteDetail.image && (
        <div className="home-image-container">
          <img
            src={voteDetail.image} // Assuming voteDetail.image is the image URL from Firebase Storage
            alt={voteDetail.vote}
          />
        </div>
      )}
  
      {/* Additional Sections */}
      {renderVotingSection()}
      {renderEngagementSection()}
      {renderChartSection()}
  
      {/* Post Recommendation */}
      <PostRecommendation />
    </div>
  );
  
};

export default VoteDetail;
