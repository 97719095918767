import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import ProfileIcon from "../profile/ProfileIcon";
import { fireDB } from "../../config/firebase";
import { useAuth } from "../../hooks/useAuth";
import './connectionStyle/following.css';

const Following = ({ userId }) => {
  const { user, loading: authLoading, error: authError } = useAuth();
  const [following, setFollowing] = useState([]);
  const [followingCount, setFollowingCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showFollowing, setShowFollowing] = useState(false);

  const fetchFollowing = async () => {
    if (authLoading || !userId) return;

    setLoading(true);
    setError(null);

    try {
      const userRef = doc(fireDB, "users", userId);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        const followingList = userData.following || [];
        setFollowingCount(followingList.length);

        const followingDetails = await Promise.all(
          followingList.map(async (followedId) => {
            try {
              const followedRef = doc(fireDB, "users", followedId);
              const followedSnap = await getDoc(followedRef);
              if (followedSnap.exists()) {
                return { id: followedId, ...followedSnap.data() };
              }
            } catch (fetchError) {
              console.error(`Error fetching data for followed ID ${followedId}:`, fetchError);
            }
            return null;
          })
        );

        setFollowing(followingDetails.filter(Boolean));
      } else {
        setError("User data not found.");
      }
    } catch (fetchError) {
      console.error("Error fetching following:", fetchError);
      setError("Failed to load following. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const toggleFollowing = () => {
    if (!showFollowing) {
      fetchFollowing();
    }
    setShowFollowing((prev) => !prev);
  };

  if (authLoading) return <p>Loading authentication...</p>;
  if (authError) return <p className="error-message">Authentication Error: {authError}</p>;

  return (
    <div>
      <p onClick={toggleFollowing}>
        Following ({followingCount})
      </p>
      {showFollowing && (
        <div>
          {loading ? (
            <p>Loading following...</p>
          ) : error ? (
            <p className="error-message">{error}</p>
          ) : (
            <ul>
              {following.length > 0 ? (
                following.map((followed) => (
                  <li key={followed.id}>
                    <div className="follower-icons">
                      <ProfileIcon
                        name={followed.name || "Anonymous"}
                        className="profile-icon"
                      />
                      <span>{followed.name || "No Name"}</span>
                    </div>
                  </li>
                ))
              ) : (
                <p>Not following anyone yet</p>
              )}
            </ul>
          )}
        </div>
      )}
    </div>
  );
};

export default Following;
