import React, { useState, useEffect } from "react";
import { fetchRooms, createRoom, deleteRoom } from "./FirebaseUtils";
import { useAuth } from "../../hooks/useAuth";
import "./communityStyle/roomList.css";

const RoomsList = ({ onJoinRoom }) => {
  const { user, userId, userNames } = useAuth();
  const [rooms, setRooms] = useState([]);
  const [newRoomTopic, setNewRoomTopic] = useState("");

  useEffect(() => {
    const initialize = async () => {
      const { roomsData } = await fetchRooms();
      setRooms(roomsData);
    };
    initialize();
  }, []);

  const handleCreateRoom = async (e) => {
    e.preventDefault();
    if (!newRoomTopic.trim() || !userId) return;

    const newRoom = await createRoom(newRoomTopic, { userId, name: user.displayName });
    if (newRoom) {
      setRooms((prevRooms) => [...prevRooms, newRoom]);
      setNewRoomTopic("");
    }
  };

  const handleDeleteRoom = async (roomId, creatorId) => {
    if (userId !== creatorId) {
      alert("Only the room creator can delete this room!");
      return;
    }

    const success = await deleteRoom(roomId);
    if (success) {
      setRooms((prevRooms) => prevRooms.filter((room) => room.id !== roomId));
    }
  };

  return (
    <div className="rooms-list-container">
      <h2>Discussion Rooms</h2>
      <form onSubmit={handleCreateRoom}>
        <input
          type="text"
          value={newRoomTopic}
          onChange={(e) => setNewRoomTopic(e.target.value)}
          placeholder="Enter room topic"
          className="input-field"
        />
        <button type="submit" className="create-room-btn">Create Room</button>
      </form>

      <h3>Available Rooms</h3>
      <ul className="rooms-list">
        {rooms.map((room) => (
          <li key={room.id}>
            <span onClick={() => onJoinRoom(room.id)}>{room.topic} - Created by {userNames[room.creatorId] || "Unknown"}</span>
            {userId === room.creatorId && (
              <button onClick={() => handleDeleteRoom(room.id, room.creatorId)} className="delete-room-btn">🗑 Delete</button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RoomsList;
