import React, { useState } from "react";
import { FaShareAlt, FaFacebook, FaTwitter, FaWhatsapp, FaLinkedin, FaTelegram, FaReddit, FaEnvelope } from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  RedditShareButton,
  EmailShareButton,
} from "react-share";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";

const VoteShare = ({ vote, toggleShareDropdown, shareDropdownOpen, setVotes }) => {
  const [isSharing, setIsSharing] = useState(false);

  const handleShare = async (voteId) => {
    if (isSharing) return; // Prevent multiple clicks
    setIsSharing(true);

    try {
      const voteRef = doc(fireDB, "voteQuestions", voteId);

      // Optimistic update locally
      setVotes((prevVotes) =>
        prevVotes.map((v) =>
          v.id === voteId ? { ...v, shares: v.shares + 1 } : v
        )
      );

      // Update Firestore
      await updateDoc(voteRef, {
        shares: vote.shares + 1,
      });
      console.log("Share count updated in Firestore.");
    } catch (error) {
      console.error("Error updating share count:", error);
    } finally {
      setIsSharing(false);
    }
  };

  const shareOptions = [
    { label: "", Button: FacebookShareButton, url: `https://xnetous.com/vote/${vote.id}`, Icon: FaFacebook },
    { label: "", Button: TwitterShareButton, url: `https://xnetous.com/vote/${vote.id}`, Icon: FaTwitter },
    { label: "", Button: WhatsappShareButton, url: `https://xnetous.com/vote/${vote.id}`, Icon: FaWhatsapp },
    { label: "", Button: LinkedinShareButton, url: `https://xnetous.com/vote/${vote.id}`, Icon: FaLinkedin },
    { label: "", Button: TelegramShareButton, url: `https://xnetous.com/vote/${vote.id}`, Icon: FaTelegram },
    { label: "", Button: RedditShareButton, url: `https://xnetous.com/vote/${vote.id}`, Icon: FaReddit },
    { label: "", Button: EmailShareButton, url: `https://xnetous.com/vote/${vote.id}`, Icon: FaEnvelope },
  ];

  return (
    <div className="share-container">
      {/* Share Button */}
      <div
        className="share-button"
        onClick={() => toggleShareDropdown(vote.id)}
      >
        <FaShareAlt size={15}/>
        <span style={{ fontSize: '13px' }} className="share-count">{vote.shares}</span>
      </div>

      {/* Share Dropdown */}
      {shareDropdownOpen === vote.id && (
        <div className="share-dropdown">
          {shareOptions.map(({ label, Button, url, Icon }, index) => (
            <div
              key={index}
              url={url}
              className="share-option"
              onClick={() => handleShare(vote.id)}
            >
              <Icon className="share-option-icon" />
              {label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default VoteShare;
