import React, { useState, useEffect, useCallback } from "react";
import { doc, updateDoc, getDoc, arrayUnion, arrayRemove, collection, addDoc } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { fireDB } from "../../config/firebase";
import { useAuth } from "../../hooks/useAuth"; // Assuming useAuth is in hooks folder
import Spinner from "../../spinner/Spinner";
import "./connectionStyle/follow.css";

const Follow = () => {
  const { userId: targetUserId } = useParams();
  const { user: currentUser, loading: authLoading, error: authError } = useAuth();
  const [following, setFollowing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch follow status
  const fetchFollowStatus = useCallback(async () => {
    if (!currentUser || !targetUserId) return;

    setLoading(true);
    setError(null);

    try {
      const userRef = doc(fireDB, "users", currentUser.uid);
      const userSnap = await getDoc(userRef);

      if (userSnap.exists()) {
        const userData = userSnap.data();
        setFollowing(userData.following?.includes(targetUserId) || false);
      }
    } catch (err) {
      console.error("Error fetching follow status:", err);
      setError("Unable to fetch follow status.");
    } finally {
      setLoading(false);
    }
  }, [currentUser, targetUserId]);

  useEffect(() => {
    if (currentUser) fetchFollowStatus();
  }, [currentUser, fetchFollowStatus]);

  // Handle follow/unfollow action
  const updateFollowStatus = async (action) => {
    if (!currentUser || !targetUserId) {
      setError("Invalid user information.");
      return;
    }

    setLoading(true);
    setError(null);

    const userRef = doc(fireDB, "users", currentUser.uid);
    const targetUserRef = doc(fireDB, "users", targetUserId);
    const notificationsRef = collection(fireDB, "users", targetUserId, "notifications"); // Reference to notifications subcollection

    try {
      if (action === "follow") {
        // Update following and followers lists
        await updateDoc(userRef, { following: arrayUnion(targetUserId) });
        await updateDoc(targetUserRef, { followers: arrayUnion(currentUser.uid) });

        // Add notification to target user
        await addDoc(notificationsRef, {
          type: "follow",
          message: `${currentUser.displayName || "A user"} started following you.`,
          timestamp: new Date(),
        });

        setFollowing(true);
      } else {
        // Unfollow operation
        await updateDoc(userRef, { following: arrayRemove(targetUserId) });
        await updateDoc(targetUserRef, { followers: arrayRemove(currentUser.uid) });

        setFollowing(false);
      }
    } catch (err) {
      console.error(`Error during ${action} operation:`, err);
      setError(`Failed to ${action} user.`);
    } finally {
      setLoading(false);
    }
  };

  if (authLoading) return <p><Spinner /></p>;
  if (authError || !currentUser) return <p>{authError || "User not authenticated"}</p>;

  return (
    <div className="">
      <button
        className="follow-btn"
        onClick={() => updateFollowStatus(following ? "unfollow" : "follow")}
        disabled={loading}
      >
        {loading ? "Processing..." : following ? "Unfollow" : "Follow"}
      </button>
      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Follow;







