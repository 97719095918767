import React, { useEffect } from 'react';

function Console() {
  useEffect(() => {
    // Check if the environment is not 'production'
    if (process.env.NODE_ENV !== 'production') {
      console.log("This log is visible in development mode.");
    }
  }, []); // Empty dependency array ensures it runs only once on mount
  
  return (
    <div>
      <h1></h1>
    </div>
  );
}

export default Console;
