import React, { useState, useEffect } from 'react';
import { useAuth } from '../../hooks/useAuth';  // Importing the useAuth hook
import './otherStyle/support.css';

const Support = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const { user, loading, userNames, error } = useAuth();  // Getting user data from the useAuth hook

  // Handle search input
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  if (loading) {
    return <div>Loading...</div>; // Show loading state while the user data is being fetched
  }

  return (
    <div className="support-container">
      <div className="support-header">
        <h1>Customer Support</h1>
        <p>Your one-stop solution for all your questions and concerns.</p>
        <input 
          type="text" 
          placeholder="Search for answers..." 
          value={searchQuery} 
          onChange={handleSearch} 
          className="search-bar" 
        />
      </div>

      <div className="faq-section">
        <h2>Frequently Asked Questions (FAQ)</h2>
        <div className="faq-item">
          <h3>How can I reset my password?</h3>
          <p>Click on the 'Forgot Password' link on the login page or just go to settings and follow the instructions to reset your password.</p>
        </div>
        <div className="faq-item">
          <h3>How do I contact support?</h3>
          <p>Email us at <a href="mailto:xnetous2023@gmail.com">xnetous2023@gmail.com</a> or reach us through our live chat.</p>
        </div>
        <div className="faq-item">
          <h3>How can I track my post results?</h3>
          <p>Your post results are available on your dashboard. You can view and track them by logging into your account.</p>
        </div>
        <div className="faq-item">
          <h3>What should I do if I experience a bug or issue?</h3>
          <p>If you experience any bugs or issues, please report them by filling out the form below or contacting us directly via email.</p>
        </div>
      </div>

      <div className="contact-support">
        <h2>Contact Support</h2>
        <p>If you couldn't find an answer to your question or need further assistance, feel free to reach out to our support team:</p>
        
        <div className="contact-options">
          <div className="contact-item">
            <h4>Email</h4>
            <p><a href="mailto:xnetous2023@gmail.com">xnetous2023@gmail.com</a></p>
          </div>
          <div className="contact-item">
            <h4>Live Chat</h4>
            <p>Start a chat with us <a href="https://www.facebook.com/messages/t/113853808335308" target="_blank" rel="noopener noreferrer">Messanger</a>.</p>
          </div>
          <div className="contact-item">
            <h4>Social Media</h4>
            <p>Follow us on social media for updates and support:</p>
            <p>
              <a href="https://x.com/xnetous2023" target="_blank" rel="noopener noreferrer">Twitter</a> | 
              <a href="https://www.facebook.com/profile.php?id=100091451263391" target="_blank" rel="noopener noreferrer">Facebook</a> | 
              <a href="https://www.instagram.com/xnetous/" target="_blank" rel="noopener noreferrer">Instagram</a>
            </p>
          </div>
        </div>
      </div>

      <div className="submit-request">
        <h2>Submit a Support Request</h2>
        <p>If your issue is urgent, please fill out the form below to submit a support request.</p>
        <form className="support-form">
          <label htmlFor="issue-type">Issue Type:</label>
          <select id="issue-type">
            <option value="password">Password Reset</option>
            <option value="account">Account Issue</option>
            <option value="bug">Bug Report</option>
            <option value="general">General Inquiry</option>
          </select>

          <label htmlFor="description">Description of the Issue:</label>
          <textarea id="description" rows="5" placeholder="Describe your issue..."></textarea>

          <button type="submit">Submit Request</button>
        </form>
      </div>

      {/* Display User Info */}
      {user && (
        <div className="">
          <h3>Hello, {userNames[user.uid] || "User"}</h3>
          <p>User ID: {user.uid}</p>
          <p>Your current email: {user.email}</p>
        </div>
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default Support;
