import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { fireDB } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { timeAgo } from "../../../utils/Time";
import { useAuth } from "../../../hooks/useAuth";
import Spinner from "../../../spinner/Spinner";
import "./performance.css";

const MostPolled = () => {
  const { userNames } = useAuth();
  const [pollData, setPollData] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true); // Track if more data is available
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Time Decay Ranking Calculation
  const calculateRankScore = (totalPolls, createdAt) => {
    const hoursSinceCreation =
      (Date.now() - createdAt.toMillis()) / (1000 * 60 * 60);
    return (totalPolls - 1) / Math.pow(hoursSinceCreation + 2, 1.8); // Decay factor of 1.8
  };

  // Fetch polls from the last seven days
  const fetchMostPolled = async () => {
    try {
      const limitPerPage = 1;
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      let queryRef = query(
        collection(fireDB, "pollQuestions"),
        where("createdAt", ">=", sevenDaysAgo),
        orderBy("createdAt", "desc"),
        limit(limitPerPage)
      );

      if (lastVisible) {
        queryRef = query(
          collection(fireDB, "pollQuestions"),
          where("createdAt", ">=", sevenDaysAgo),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(limitPerPage)
        );
      }

      const snapshot = await getDocs(queryRef);

      if (snapshot.empty) {
        setHasMore(false); // No more data to fetch
        return;
      }

      const data = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const pollData = doc.data();
          const totalPolls = Object.values(pollData.polls || {}).reduce(
            (sum, count) => sum + count,
            0
          );
          const rankScore = calculateRankScore(totalPolls, pollData.createdAt);

          // Fetch user details including profileImage
          const userSnapshot = await getDocs(
            query(
              collection(fireDB, "users"),
              where("userId", "==", pollData.userId)
            )
          );
          const userData = userSnapshot.empty
            ? { name: "Unknown User", profileImage: "" }
            : userSnapshot.docs[0].data();

          return {
            id: doc.id,
            ...pollData,
            totalPolls,
            rankScore,
            user: {
              name: userData.name,
              profileImage: userData.profileImage || "",
            },
          };
        })
      );

      // Sort by rankScore
      data.sort((a, b) => b.rankScore - a.rankScore);

      setPollData((prev) => [...prev, ...data]);
      setLastVisible(snapshot.docs[snapshot.docs.length - 1]);
    } catch (error) {
      console.error("Error fetching polls:", error);
      setError("Failed to load most polled data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchMostPolled();
  }, []);

  const handleNavigate = (id) => {
    navigate(`/poll/${id}`);
  };

  return (
    <div className="performance-page">
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div>
          <InfiniteScroll
            dataLength={pollData.length}
            next={fetchMostPolled}
            hasMore={hasMore}
            loader={<Spinner />}
            endMessage={
              <p className="end-message">You have seen all the polls!</p>
            }
          >
            <div className="trending-list">
              {pollData.length > 0 ? (
                pollData.map((item) => (
                  <div
                    key={item.id}
                    className="trending-card"
                    onClick={() => handleNavigate(item.id)}
                  >
                    <div className="card-header">
                      <ProfileIcon
                        name={item.user.name}
                        profileImage={item.user.profileImage || ""} // Use profileImage from the user object
                        className="profile-icon"
                      />
                      <span className="user-name">{item.user.name}</span>
                      <span className="created-at">
                        {item.createdAt?.seconds
                          ? timeAgo(new Date(item.createdAt.seconds * 1000))
                          : "Unknown Date"}
                      </span>
                    </div>
                    <div className="card-body">
                      <p
                        className="post-title"
                        style={{
                          fontSize: "15px",

                          color: "#14171a",
                        }}
                      >
                        {item.poll || "No Title"}
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="popularity">
                        <strong>Total Polls:</strong> {item.totalPolls}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-data-message">No trending polls found.</p>
              )}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default MostPolled;
