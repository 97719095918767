import React from "react";
import { ThemeProvider } from "./context/ThemeContext";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import LinkGeneration from "./resources/LinkGeneration";
import Warning from "./console/Warning";
import Console from "./console/Console";


function App() {
  
  return (
    <div>
      <Warning/>
      <Console/>
      <ToastContainer />
      <LinkGeneration />
    </div>
  );
}

export default function WrappedApp() {
  return (
    <ThemeProvider>
      <App />
    </ThemeProvider>
  );
}
