import React, { useState, useEffect } from 'react';
import { fireDB, doc, getDoc, updateDoc, serverTimestamp } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import './subscriptionStyle/subscription.css';
import { useAuth } from '../../hooks/useAuth';
import PaymentGateway from './PaymentGateway'; // Import the PaymentGateway component

const SubscriptionPage = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [expirationDate, setExpirationDate] = useState(null);
  const navigate = useNavigate();

  const { userId, loading } = useAuth(navigate);

  useEffect(() => {
    if (userId) {
      fetchSubscriptionStatus(userId);
    }
  }, [userId]);

  const fetchSubscriptionStatus = async (uid) => {
    try {
      const userRef = doc(fireDB, 'users', uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.subscription) {
          setIsSubscribed(true);
          setExpirationDate(userData.expirationDate);
          const currentDate = new Date();
          if (new Date(userData.expirationDate) < currentDate) {
            setIsSubscribed(false);
            toast.info('Your subscription has expired.');
          }
        } else {
          setIsSubscribed(false);
        }
      } else {
        setIsSubscribed(false);
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      toast.error('Failed to fetch subscription status.');
    }
  };

  const handleUnsubscribe = async () => {
    try {
      const userRef = doc(fireDB, 'users', userId);
      await updateDoc(userRef, {
        subscription: null,
        expirationDate: null,
        timestamp: serverTimestamp(),
      });
      setIsSubscribed(false);
      setExpirationDate(null);
      toast.info('You have unsubscribed.');
    } catch (error) {
      console.error('Error unsubscribing:', error);
      toast.error('Failed to unsubscribe.');
    }
  };

  const handlePaymentSuccess = async () => {
    try {
      const userRef = doc(fireDB, 'users', userId);
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 1); // 1 month expiration

      await updateDoc(userRef, {
        subscription: true,
        expirationDate: expirationDate.toISOString(),
        timestamp: serverTimestamp(),
      });
      setIsSubscribed(true);
      setExpirationDate(expirationDate);
      toast.success('Subscription activated successfully!');
    } catch (error) {
      console.error('Error confirming subscription:', error);
      toast.error('Failed to activate subscription.');
    }
  };

  return (
    <div className="subscription-page">
      <h1>Manage Subscription</h1>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <p>Subscription Status: {isSubscribed ? 'Active' : 'Inactive'}</p>
          {isSubscribed && expirationDate && (
            <p>Expiration Date: {new Date(expirationDate).toLocaleDateString()}</p>
          )}
          {isSubscribed ? (
            <button onClick={handleUnsubscribe} disabled={isProcessing}>
              {isProcessing ? 'Processing...' : 'Unsubscribe'}
            </button>
          ) : (
            <PaymentGateway onPaymentSuccess={handlePaymentSuccess} />
          )}
        </div>
      )}
    </div>
  );
};

export default SubscriptionPage;  








/* import React, { useState, useEffect } from 'react';
import { fireDB, doc, getDoc, updateDoc, serverTimestamp } from '../../config/firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QRCodeCanvas } from 'qrcode.react';
import './subscription.css';
import { useAuth } from '../../hooks/useAuth';

const SubscriptionPage = () => {
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentURL, setPaymentURL] = useState('');
  const [paymentStatus, setPaymentStatus] = useState(false); // Track payment status (optional)
  const [expirationDate, setExpirationDate] = useState(null); // Track expiration date
  const navigate = useNavigate();

  const { userId, loading } = useAuth(navigate); // Assume useAuth provides userId and loading state.

  useEffect(() => {
    if (userId) {
      fetchSubscriptionStatus(userId);
    }
  }, [userId]);

  const fetchSubscriptionStatus = async (uid) => {
    try {
      const userRef = doc(fireDB, 'users', uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        if (userData.subscription) {
          setIsSubscribed(true);
          setExpirationDate(userData.expirationDate);
          const currentDate = new Date();
          if (new Date(userData.expirationDate) < currentDate) {
            setIsSubscribed(false); // Expired, set to inactive
            toast.info('Your subscription has expired.');
          }
        } else {
          setIsSubscribed(false);
        }
      } else {
        setIsSubscribed(false);
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      toast.error('Failed to fetch subscription status.');
    }
  };

  const handleSubscription = async () => {
    if (!userId) {
      toast.error('You need to log in to subscribe.');
      navigate('/login');
      return;
    }

    try {
      setIsProcessing(true);
      const userRef = doc(fireDB, 'users', userId);

      if (isSubscribed) {
        // Unsubscribe logic
        await updateDoc(userRef, {
          subscription: null,
          expirationDate: null,
          timestamp: serverTimestamp(),
        });
        setIsSubscribed(false);
        setExpirationDate(null);
        toast.info('You have unsubscribed.');
      } else {
        // Generate Payment URL for subscription (using a sandbox payment gateway URL)
        setPaymentURL(`upi://pay?pa=8072906182@ptsbi&pn=xnetous&am=1&cu=INR`);
        toast.success('Subscription payment pending. Scan QR code.');
      }
    } catch (error) {
      console.error('Error handling subscription:', error);
      toast.error('Failed to update subscription status.');
    } finally {
      setIsProcessing(false);
    }
  };

  const confirmSubscription = async () => {
    try {
      // Ideally, payment verification should happen here (through a webhook or polling)
      const userRef = doc(fireDB, 'users', userId);
      const expirationDate = new Date();
      expirationDate.setMonth(expirationDate.getMonth() + 1); // Set expiration to 1 month from now

      await updateDoc(userRef, {
        subscription: true,
        expirationDate: expirationDate.toISOString(),
        timestamp: serverTimestamp(),
      });
      setIsSubscribed(true);
      setExpirationDate(expirationDate);
      toast.success('Subscription activated successfully!');
    } catch (error) {
      console.error('Error confirming subscription:', error);
      toast.error('Failed to activate subscription.');
    }
  };

  return (
    <div className="subscription-page">
      <h1>Manage Subscription</h1>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <p>
            Subscription Status: {isSubscribed ? 'Active' : 'Inactive'}
          </p>
          {isSubscribed && expirationDate && (
            <p>
              Expiration Date: {new Date(expirationDate).toLocaleDateString()}
            </p>
          )}
          <button onClick={handleSubscription} disabled={isProcessing}>
            {isProcessing
              ? 'Processing...'
              : isSubscribed
              ? 'Unsubscribe'
              : 'Subscribe (214/month)'}
          </button>
        </div>
      )}


      {paymentURL && !isSubscribed && (
        <div className="qr-code-container">
          <h3>Scan to Pay</h3>
          <QRCodeCanvas value={paymentURL} size={256} />
        </div>
      )}

      {paymentStatus && !isSubscribed && (
        <div>
          <button onClick={confirmSubscription}>
            Confirm Subscription (After Payment)
          </button>
        </div>
      )}
    </div>
  );
};

export default SubscriptionPage;  */













