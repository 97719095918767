import React, { useState, useEffect } from "react";
import Spinner from "../../../spinner/Spinner";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { timeAgo } from "../../../utils/Time";
import { toast } from "react-toastify";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import QuizPerformance from "./QuizChart";
import { doc, getDoc, updateDoc, increment } from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import QuizOption from "./QuizOption";

const QuizDetail = () => {
  const { id } = useParams();
  const { user, loading: userLoading } = useAuth();
  const [quizDetail, setQuizDetail] = useState(null);
  const [hasAnswered, setHasAnswered] = useState({});
  const navigate = useNavigate();

  // Fetch quiz details from Firestore
  const fetchQuizDetail = async () => {
    try {
      const docRef = doc(fireDB, "quizQuestions", id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();

        console.log("Quiz Detail Data:", data); // ✅ Debugging log

        // Fetch user data in batch
        const userDoc =
          data.userId && (await getDoc(doc(fireDB, "users", data.userId)));
        const user = userDoc?.exists()
          ? { id: data.userId, ...userDoc.data() }
          : { name: "Unknown User", profileImage: "" };

        const quiz = {
          id: docSnapshot.id,
          quiz: data.quiz,
          createdAt: data.createdAt,
          user,
          yesCount: data.Yes || 0,
          noCount: data.No || 0,
          quizs: data.quizs || {},
          options: data.options || [], // ✅ Ensure options are set
          correctAnswer: data.correctAnswer || "", // ✅ Ensure correct answer is included
          link: data.link || "", // ✅ Ensure link is included
        };

        setQuizDetail(quiz);
      } else {
        toast.error("Quiz question not found.");
      }
    } catch (error) {
      console.error("Error fetching quiz question:", error);
      toast.error("Failed to fetch quiz question. Please try again.");
    }
  };

  // Handle the user's answer (Yes/No)
  const handleAnswer = async (quizId, option) => {
    if (!user) {
      toast.error("You must be logged in to answer.");
      return;
    }

    if (hasAnswered[quizId]) {
      toast.error("You have already answered this question.");
      return;
    }

    try {
      const quizRef = doc(fireDB, "quizQuestions", quizId);

      // Update the answer count for the question
      await updateDoc(quizRef, {
        [`answers.${option}`]: increment(1),
      });

      // Mark the user as having answered the question
      setHasAnswered((prevAnswers) => ({
        ...prevAnswers,
        [quizId]: option,
      }));

      toast.success(`You answered: ${option}`);
      fetchQuizDetail(); // Re-fetch questions to get updated counts
    } catch (error) {
      console.error("Error updating answer:", error);
      toast.error("Failed to cast your answer. Please try again.");
    }
  };

  useEffect(() => {
    fetchQuizDetail();
  }, [id]);

  // Render the quiz details
  const renderQuizDetails = () => {
    return (
      <div className="home-post-card">
        <div key={quizDetail.id} className="home-post-header">
          <div
            className="user-avatar"
            onClick={() => {
              quizDetail.user.id &&
                navigate(
                  `/profile/${quizDetail.user.name
                    .replace(/\s+/g, "")
                    .toLowerCase()}/${quizDetail.user.id}`
                );
            }}
          >
            <ProfileIcon
              name={quizDetail.user.name}
              profileImage={quizDetail.user.profileImage}
            />
            <span className="user-name">{quizDetail.user.name}</span>
            <span className="post-timestamp">
              <span>
                {timeAgo(new Date(quizDetail.createdAt.seconds * 1000))}
              </span>
            </span>
          </div>
          <div></div>

          {/* ✅ Display Related Link */}
          {quizDetail.link && quizDetail.link.trim() !== "" && (
            <div className="home-post-link">
              <a
                href={quizDetail.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                🔗 View Related Link
              </a>
            </div>
          )}

          <div>
            <p className="detail-home-post-content">{quizDetail.quiz}</p>
          </div>

          {/* Display Poll Image */}
          {quizDetail.image && (
            <div className="home-image-container">
              <img src={quizDetail.image} alt="Fact" className="home-image" />
            </div>
          )}

          {quizDetail.options.map((option, index) => (
            <button
              key={index}
              className="action-button"
              onClick={() => handleAnswer(quizDetail.id, option)}
              disabled={hasAnswered[quizDetail.id] === option}
            >
              {option}
            </button>
          ))}
        </div>

        {hasAnswered[quizDetail.id] && (
          <QuizPerformance correctAnswer={quizDetail.correctAnswer} />
        )}
      </div>
    );
  };

  if (userLoading) return <Spinner />;
  if (!quizDetail) return <p>Loading quiz...</p>;

  return renderQuizDetails();
};

export default QuizDetail;
