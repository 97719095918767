import { useState } from "react";
import { FaComment, FaTrashAlt } from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase"; // Adjust the import path if needed
import * as toxicity from "@tensorflow-models/toxicity"; // Import toxicity model

const FactComment = ({ fact, userId, isAuthenticated, updateFact }) => {
  const maxCommentLength = 40; // Define max length for a comment (can be passed as prop or adjusted globally)
  const toxicityThreshold = 0.9; // Toxicity model threshold (can be passed as prop)

  const [newComment, setNewComment] = useState("");
  const [isCommentOpen, setIsCommentOpen] = useState(false); // State to toggle comments dropdown
  const [localComments, setLocalComments] = useState(fact.comments || []); // Local state for comments
  const [isLoadingToxicity, setIsLoadingToxicity] = useState(false); // Loading state for toxicity check

  // Function to load and use the toxicity model
  const checkToxicity = async (comment) => {
    setIsLoadingToxicity(true);
    try {
      const model = await toxicity.load(toxicityThreshold);
      const predictions = await model.classify([comment]);
      const isToxic = predictions.some(
        (p) => p.results[0].match === true
      );
      return isToxic;
    } catch (error) {
      console.error("Error checking toxicity:", error);
      return false; // Default to non-toxic if there's an error
    } finally {
      setIsLoadingToxicity(false);
    }
  };

  const handleAddComment = async (factId, comment) => {
    if (comment.trim() === "") return; // Prevent adding empty comments
    if (comment.length > maxCommentLength) return; // Prevent overly long comments

    const isToxic = await checkToxicity(comment);
    if (isToxic) {
      alert("Your comment was flagged as toxic. Please modify it.");
      return;
    }

    const factRef = doc(fireDB, "factQuestions", factId);
    const newComments = [...localComments, comment];

    try {
      await updateDoc(factRef, {
        comments: newComments,
        commentCount: newComments.length,
      }); // Update Firestore with new comment count

      // Update local state after adding the comment
      setLocalComments(newComments);
      if (updateFact) {
        updateFact(factId, newComments, "comments"); // Update parent component's state if needed
      }
      setNewComment(""); // Clear the input field after adding
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleDeleteComment = async (factId, commentIndex) => {
    const factRef = doc(fireDB, "factQuestions", factId);
    const updatedComments = localComments.filter(
      (_, index) => index !== commentIndex
    );

    try {
      await updateDoc(factRef, {
        comments: updatedComments,
        commentCount: updatedComments.length,
      }); // Update Firestore with new comment count

      // Update local state after deleting the comment
      setLocalComments(updatedComments);
      if (updateFact) {
        updateFact(factId, updatedComments, "comments"); // Update parent component's state if needed
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const toggleComments = () => {
    setIsCommentOpen(!isCommentOpen); // Toggle the comment section
  };

  return (
    <div>
      {/* Toggle Comments Button */}
      <div className="comment-toggle" onClick={toggleComments}>
        <FaComment size={15} />
        <span className="comment-toggle-text">
          {isCommentOpen ? "hide" : "view "}
        </span>
      </div>

      {/* Comments Section */}
      {isCommentOpen && (
        <div className="comment-section">
          {localComments.map((comment, index) => (
            <div key={index} className="comment">
              <p className="comment-text">{comment}</p>
              {isAuthenticated && (
                <FaTrashAlt
                  className="comment-delete"
                  onClick={() => handleDeleteComment(fact.id, index)}
                  size={15}
                />
              )}
            </div>
          ))}

          {/* Add New Comment */}
          {isAuthenticated && (
            <div className="add-comment">
              <input
                type="text"
                value={newComment}
                onChange={(e) =>
                  setNewComment(e.target.value.slice(0, maxCommentLength))
                }
                placeholder="Write your comment here..."
                className="comment-input"
              />
              <p className="remaining-characters">
                {maxCommentLength - newComment.length} characters remaining
              </p>
              <button
                onClick={() => handleAddComment(fact.id, newComment)}
                disabled={!newComment.trim() || isLoadingToxicity}
                className="comment-button"
              >
                {isLoadingToxicity ? "Checking..." : "Add"}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default FactComment;
