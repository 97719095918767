import React, { useState, useEffect } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import * as use from "@tensorflow-models/universal-sentence-encoder";
import * as tf from "@tensorflow/tfjs";
import ProfileIcon from "../../components/profile/ProfileIcon";
import LoadingSkeleton from "../../spinner/LoadingSkeleton";

const RecommendationCard = ({ item, handleNavigate, userNames }) => (
  <div
    className="recommendation-card"
    onClick={() => handleNavigate(item.id, item.category)}
  >
    <h3>{item.title || "No Title"}</h3>
   
    <p>
      <strong>Created At:</strong>{" "}
      {item.createdAt
        ? new Date(item.createdAt.seconds * 1000).toLocaleString()
        : "Unknown"}
    </p>
    <p>
      <strong>Similarity:</strong> {item.similarity?.toFixed(2) || "N/A"}
    </p>
    <div
      className="user-link"
      onClick={(e) => {
        e.stopPropagation(); // Prevent triggering card click
        handleNavigate(`/profile/${item.userId}`);
      }}
    >
      <ProfileIcon name={userNames[item.userId] || "Anonymous"} />
      <span>{userNames[item.userId] || "Anonymous"}</span>
    </div>
  </div>
);

const RecommendationPost = () => {
  const navigate = useNavigate();
  const { userNames, loading: authLoading } = useAuth();
  const [recommendations, setRecommendations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecommendations = async () => {
      setLoading(true);
      setError(null);
      try {
        const collections = [
          "factQuestions",
          "pollQuestions",
          "voteQuestions",
          "quizQuestions",
        ];

        const promises = collections.map(async (collectionName) => {
          const postsQuery = query(
            collection(fireDB, collectionName),
            orderBy("createdAt", "desc")
          );
          const querySnapshot = await getDocs(postsQuery);
          return querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
        });

        const results = await Promise.all(promises);
        const flatResults = results.flat();
        const userPreferences = ["technology", "sports"]; // Replace with dynamic preferences
        const rankedPosts = await rankPostsWithML(flatResults, userPreferences);
        setRecommendations(rankedPosts);
      } catch (err) {
        console.error("Error fetching recommendations:", err);
        setError("Failed to load recommendations. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchRecommendations();
  }, []);

  const rankPostsWithML = async (posts, preferences) => {
    const model = await use.load();
    const rankedPosts = await Promise.all(
      posts.map(async (post) => {
        const similarity = await computePostSimilarity(model, post, preferences);
        return { ...post, similarity };
      })
    );
    return rankedPosts.sort((a, b) => b.similarity - a.similarity);
  };

  const computePostSimilarity = async (model, post, preferences) => {
    const postContent = `${post.title} ${post.description || ""}`;
    const preferenceText = preferences.join(" ");
    return computeTextSimilarity(model, postContent, preferenceText);
  };

  const computeTextSimilarity = async (model, text1, text2) => {
    const embeddings = await model.embed([text1, text2]);
    const [embedding1, embedding2] = tf.split(embeddings, 2);
    const similarity = tf.losses.cosineDistance(embedding1, embedding2, 0).dataSync()[0];
    return 1 - similarity; // Convert to similarity
  };

  const handleNavigate = (id, category) => {
    navigate(`/recommendation/${id}?category=${category}`);
  };

  return (
    <div className="recommendation-page">
      <h2>Recommendations</h2>
      {authLoading || loading ? (
        <LoadingSkeleton />
      ) : error ? (
        <div className="error-message">
          <p>{error}</p>
          <button onClick={() => window.location.reload()}>Retry</button>
        </div>
      ) : (
        <div className="recommendations-list">
          {recommendations.length > 0 ? (
            recommendations.map((item) => (
              <RecommendationCard
                key={item.id}
                item={item}
                handleNavigate={handleNavigate}
                userNames={userNames}
              />
            ))
          ) : (
            <p>No recommendations available at the moment.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default RecommendationPost;
