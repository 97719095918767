import React, { useState, useEffect } from "react";
import Spinner from "../../../spinner/Spinner";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { useAuth } from "../../../hooks/useAuth";
import { timeAgo } from "../../../utils/Time";
import { doc, getDoc, updateDoc, increment } from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FactChart from "./FactChart";
import FactLike from "../../../components/like/FactLike";
import FactRetweet from "../../../components/retweet/FactRetweet";
import FactComment from "../../../components/comment/FactComment";
import "../detailPage.css";
import FactShare from "../../../components/share/FactShare";
import PostRecommendation from "../../../components/recommendation/PostRecommendation";


const FactDetail = () => {
  const { id } = useParams();
  const { user, loading: userLoading } = useAuth();
  const [factDetail, setFactDetail] = useState(null);
  const [hasSelected, setHasSelected] = useState(null);
  const [shareDropdownOpen, setShareDropdownOpen] = useState(null);
  const navigate = useNavigate();

  // Fetch the fact details
  const fetchFactDetail = async () => {
    try {
      const docRef = doc(fireDB, "factQuestions", id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const userDoc =
          data.userId && (await getDoc(doc(fireDB, "users", data.userId)));

        const user = userDoc?.exists()
          ? { id: data.userId, ...userDoc.data() }
          : { name: "Unknown User", profileImage: "" };

        const fact = {
          id: docSnapshot.id,
          fact: data.fact,
          createdAt: data.createdAt,
          user,
          facts: data.facts || {},
          likes: data.likes || 0,
          retweets: data.retweets || [],
          comments: data.comments || [],
          link: data.link || "", // Ensure link is included
          image: data.image || "", // Ensure image is included
          rankingScore: (data.facts?.agree || 0) - (data.facts?.disagree || 0),
        };

        setFactDetail(fact);
      } else {
        toast.error("Fact question not found.");
      }
    } catch (error) {
      console.error("Error fetching fact question:", error);
      toast.error("Failed to fetch the fact question. Please try again.");
    }
  };

  useEffect(() => {
    fetchFactDetail();
  }, [id]);

  const handleFactSelect = async (option) => {
    if (!user) {
      toast.error("You must be logged in to participate.");
      return;
    }

    if (hasSelected) {
      toast.error("You have already participated in this fact.");
      return;
    }

    try {
      const questionRef = doc(fireDB, "factQuestions", id);
      await updateDoc(questionRef, {
        [`facts.${option}`]: increment(1),
      });

      setHasSelected(option);
      toast.success(`You selected: ${option}`);
    } catch (error) {
      console.error("Error updating fact:", error);
      toast.error("Failed to record your selection. Please try again.");
    }
  };

  const calculatePercentage = (count, total) =>
    total === 0 ? 0 : (count / total) * 100;

  if (userLoading) return <Spinner />;
  if (!factDetail) return <p>Loading fact question...</p>;

  return (
    <div className="detail-home-post-card">
      <div className="home-post-header">
        {/* User Profile */}
        <div
          className="user-avatar"
          onClick={() => {
            factDetail.user.id &&
              navigate(
                `/profile/${factDetail.user.name
                  .replace(/\s+/g, "")
                  .toLowerCase()}/${factDetail.user.id}`
              );
          }}
        >
          <ProfileIcon
            name={factDetail.user.name}
            profileImage={factDetail.user.profileImage}
          />
        </div>

        <div className="user-details">
          <span className="user-name">{factDetail.user.name}</span>
          <span className="post-timestamp">
            {" "}
            {timeAgo(new Date(factDetail.createdAt.seconds * 1000))}
          </span>
        </div>
      </div>

      {/* User Details */}

      {/* Display Related Link */}
      {factDetail.link && (
        <div className="home-post-link">
          <a href={factDetail.link} target="_blank" rel="noopener noreferrer">
            🔗 View Related Link
          </a>
        </div>
      )}

      {/* Fact Content */}
      <div>
        <p className="detail-home-post-content">{factDetail.fact}</p>
      </div>

      {/* Display Poll Image */}
      {factDetail.image && (
        <div className="home-image-container">
          <img src={factDetail.image} alt="Fact" className="home-image" />
        </div>
      )}

      {/* Engagement Section */}
      <div className="home-post-footer-icons">
        <FactLike factId={factDetail.id} initialLikes={factDetail.likes} />
        <FactRetweet
          factId={factDetail.id}
          initialRetweets={factDetail.retweets}
        />
        <FactComment fact={factDetail} isAuthenticated={!!user} />
        <FactShare
          factId={factDetail.id}
          fact={factDetail}
          toggleShareDropdown={() => setShareDropdownOpen(!shareDropdownOpen)}
          shareDropdownOpen={shareDropdownOpen}
        />
      </div>

      {/* Voting Section */}
      <div className="home-post-actions">
        <button
          className="action-button"
          onClick={() => handleFactSelect("agree")}
          disabled={hasSelected}
        >
          👍 Agree
        </button>
        <button
          className="action-button"
          onClick={() => handleFactSelect("disagree")}
          disabled={hasSelected}
        >
          👎 Disagree
        </button>
      </div>

      {/* Render Chart Section */}
      {hasSelected && (
        <FactChart
          agreePercentage={calculatePercentage(
            factDetail.facts.agree || 0,
            (factDetail.facts.agree || 0) + (factDetail.facts.disagree || 0)
          )}
          disagreePercentage={calculatePercentage(
            factDetail.facts.disagree || 0,
            (factDetail.facts.agree || 0) + (factDetail.facts.disagree || 0)
          )}
        />
      )}
      <PostRecommendation />
    </div>
  );
};

export default FactDetail;
