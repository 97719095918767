// ProfileLock.js (Settings Component)
import React, { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth"; // Assuming useAuth is in hooks directory

const ProfileLock = () => {
  const { userId, isProfileLocked, toggleProfileLock } = useAuth(); // Using useAuth hook
  const [loading, setLoading] = useState(false);

  const handleLockToggle = async () => {
    setLoading(true);
    try {
      // Toggle the profile lock status
      await toggleProfileLock(userId, !isProfileLocked); // Update profile lock status
    } catch (err) {
      console.error("Error toggling profile lock:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="profile-lock-settings">
      <h3>Profile Lock Settings</h3>
      <p>
        Lock your profile to make it visible only to your followers.
      </p>
      <button onClick={handleLockToggle} disabled={loading}>
        {isProfileLocked ? "Unlock Profile" : "Lock Profile"}
      </button>
    </div>
  );
};

export default ProfileLock;
