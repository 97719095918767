import React from "react";
import './otherStyle/privacyPolicy.css';

const PrivacyPolicy = () => {
  const lastUpdated = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric"
  });

  return (
    <div className="privacy-policy">
      <div className="privacy-container">
        <h1 className="privacy-title">Privacy Policy</h1>

        <p className="privacy-paragraph">
          Welcome to Xnetous! This privacy policy explains how we handle your personal information when you use our platform. By accessing or using Xnetous, you agree to the terms outlined here. We are committed to ensuring that your privacy is protected while you enjoy our advanced social networking features.
        </p>

        <h2 className="privacy-subtitle">1. Information We Collect</h2>

        <p className="privacy-paragraph">
          We collect the following types of information:
          <ul>
            <li><strong>Account Details:</strong> Name, email, password, and profile information (picture, bio, rank).</li>
            <li><strong>Platform Activity:</strong> Likes, comments, shares, quiz attempts, voting participation, and premium membership details.</li>
            <li><strong>Device and Usage Information:</strong> IP address, browser type, and device identifiers to enhance platform security.</li>
          </ul>
        </p>

        <h2 className="privacy-subtitle">2. How We Use Your Information</h2>

        <p className="privacy-paragraph">
          We use your data for:
          <ul>
            <li><strong>Personalization:</strong> To tailor your feed with recommendations powered by ML and NLP.</li>
            <li><strong>Insights:</strong> Generating platform analytics, user ranking, and engagement statistics.</li>
            <li><strong>Notifications:</strong> Informing you about updates, new features, or opportunities like premium plans.</li>
            <li><strong>Security:</strong> Detecting and mitigating fraudulent or malicious activity.</li>
          </ul>
        </p>

        <h2 className="privacy-subtitle">3. Data Sharing and Security</h2>

        <p className="privacy-paragraph">
          Your data is shared only:
          <ul>
            <li>To comply with legal obligations or enforce terms of use.</li>
            <li>With partners supporting operational functions under confidentiality agreements.</li>
          </ul>
          Robust encryption and authentication methods protect your information.
        </p>

        <h2 className="privacy-subtitle">4. User Rights</h2>

        <p className="privacy-paragraph">
          You have the right to:
          <ul>
            <li>Access and update your information via account settings.</li>
            <li>Request deletion of your data through account deactivation.</li>
          </ul>
          Contact support for assistance.
        </p>

        <h2 className="privacy-subtitle">5. Updates to the Privacy Policy</h2>

        <p className="privacy-paragraph">
          Changes to this policy will reflect evolving features or legal standards. Major updates will be communicated to users directly.
        </p>

        <p className="privacy-paragraph">Last Updated: {lastUpdated}</p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;