import React, { useState, useEffect } from "react";
import "./trends.css";
import LatestRespond from "./performance/LatestRespond";
import MostAgree from "./performance/MostAgree";
import MostPolled from "./performance/MostPolled";
import MostVoted from "./performance/MostVoted";
import MostLiked from "./performance/MostLiked";

function Trends() {
  const [activeTab, setActiveTab] = useState("mostAgree");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const tabs = [
    { id: "latestRespond", label: "Respond" },
    { id: "mostAgree", label: "Agree" },
    { id: "mostPolled", label: "Polled" },
    { id: "mostVote", label: "Vote" },
    { id: "mostLiked", label: "Like" },
  ];

  // Simulate data fetching and update loading and error state
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        // Simulate data fetching with a delay
        setTimeout(() => {
          // Simulate successful data fetch
          setLoading(false);

          // Uncomment the next line to simulate an error
          // setError("Failed to load data. Please try again.");
        }, 2000);
      } catch (error) {
        setLoading(false);
        setError("An error occurred while fetching data.");
      }
    };

    fetchData();
  }, []);

  return (
    <div className="trends-container">
      {/* Tab Navigation */}
      <div className="tab-navigation">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab ${activeTab === tab.id ? "active" : ""}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>

      {/* Error Message */}
      {error && <div className="error-message">{error}</div>}

      {/* Loading Spinner */}
      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div className="tab-content">
          {/* Conditional rendering for active tab content */}
          {activeTab === "latestRespond" && <LatestRespond />}
          {activeTab === "mostAgree" && <MostAgree />}
          {activeTab === "mostPolled" && <MostPolled />}
          {activeTab === "mostVote" && <MostVoted />}
          {activeTab === "mostLiked" && <MostLiked />}
        </div>
      )}
    </div>
  );
}

export default Trends;
