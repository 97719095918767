import React, { useState } from 'react';
import { auth, facebookProvider, googleProvider } from '../config/firebase'; // Import Google provider
import { signInWithPopup } from 'firebase/auth'; // Import Firebase's signInWithPopup function

function SocialLogin() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // Handle Facebook login
  const handleFacebookLogin = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Sign in with Facebook popup
      const result = await signInWithPopup(auth, facebookProvider);
      const user = result.user;
      console.log("Facebook login successful:", user);
      // After successful login, you can redirect or do any action
      // navigate("/home"); // You can use navigate here if you're using react-router-dom
      
    } catch (err) {
      console.error("Error during Facebook login:", err);
      setError("Failed to log in with Facebook. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle Google login
  const handleGoogleLogin = async () => {
    setLoading(true);
    setError(null);
    
    try {
      // Sign in with Google popup
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      console.log("Google login successful:", user);
      // After successful login, you can redirect or do any action
      // navigate("/home");
      
    } catch (err) {
      console.error("Error during Google login:", err);
      setError("Failed to log in with Google. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      <button onClick={handleFacebookLogin} disabled={loading}>
        {loading ? "Logging in..." : "Signup with Facebook"}
      </button>
      <br />
      <button onClick={handleGoogleLogin} disabled={loading}>
        {loading ? "Logging in..." : "Signup with Google"}
      </button>
    </div>
  );
}

export default SocialLogin;
