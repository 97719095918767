import React, { useState, useEffect } from 'react';
import { fireDB } from "../config/firebase";
import { doc, updateDoc, getDocs, collection, deleteDoc } from 'firebase/firestore';
import Payout from '../components/subscription/Payout';
//import '../authStyle/adminDashboard.css'; // Import the CSS file

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState(''); // Search query state

  // Fetch users from Firestore
  const fetchUsers = async () => {
    try {
      const userSnapshot = await getDocs(collection(fireDB, "users"));
      const userData = [];
      userSnapshot.forEach((doc) => {
        userData.push({ id: doc.id, ...doc.data() });
      });
      setUsers(userData);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoading(false);
    }
  };

  // Deactivate or activate a user
  const toggleUserStatus = async (userId, isActive) => {
    try {
      const userDocRef = doc(fireDB, "users", userId);
      await updateDoc(userDocRef, {
        isActive: !isActive, // Toggle the isActive status
      });
      fetchUsers(); // Refresh the users list after updating
    } catch (error) {
      console.error("Error updating user status:", error);
    }
  };

  // Delete a user
  const deleteUser = async (userId) => {
    try {
      const userDocRef = doc(fireDB, "users", userId);
      await deleteDoc(userDocRef); // Delete the user document
      fetchUsers(); // Refresh the users list after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  // Search filter
  const filteredUsers = users.filter((user) => {
    const lowercasedSearchQuery = searchQuery.toLowerCase();
    return (
      user.name.toLowerCase().includes(lowercasedSearchQuery) ||
      user.email.toLowerCase().includes(lowercasedSearchQuery)
    );
  });

  // Fetch users when the component mounts
  useEffect(() => {
    fetchUsers();
  }, []);

  return (
    <div className="dashboard-container">
      <h1>Admin Dashboard</h1>
      <h2>User Management</h2>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search users by name or email"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />

      <Payout/>

      {loading ? (
        <div className="loading">
          <p>Loading users...</p>
        </div>
      ) : (
        <div className="user-list">
          {filteredUsers.map((user) => (
            <div key={user.id} className="user-card">
              <h3>{user.name}</h3>
              <p><strong>Email:</strong> {user.email}</p>
              <p><strong>Country:</strong> {user.country}</p>
              <p><strong>Institution:</strong> {user.institution}</p>
              <p><strong>Role:</strong> {user.role}</p>
              <p><strong>Subscription:</strong> {user.subscription ? 'Active' : 'Inactive'}</p>
              <p><strong>Profile Views:</strong> {user.profileViews}</p>
              <p><strong>Rank:</strong> {user.rank}</p>
              <p><strong>Score:</strong> {user.score}</p>
              <p><strong>Accuracy:</strong> {user.accuracy}</p>
              <p><strong>Description:</strong> {user.description}</p>
              <p><strong>Followers:</strong> {user.followers?.length || 0}</p>
              <p><strong>Following:</strong> {user.following?.length || 0}</p>
              <p><strong>Posted Questions:</strong> {user.postedQuestions?.length || 0}</p>
              <p><strong>Posted Quizzes:</strong> {user.postedQuizzes?.length || 0}</p>

              {/* Deactivate/Activate Button */}
              <button
                onClick={() => toggleUserStatus(user.id, user.isActive)}
                className={user.isActive ? 'deactivate' : 'activate'}
              >
                {user.isActive ? 'Deactivate' : 'Activate'}
              </button>

              {/* Delete Button */}
              <button
                onClick={() => deleteUser(user.id)}
                className="delete"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
