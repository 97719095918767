import React, { useState } from "react";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { setDoc, doc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { auth, fireDB } from "../config/firebase";
import "../authStyle/signup.css";

const Signup = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    confirmPassword: "", // Added confirmPassword
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleConfirmPassword = (e) => {
    setFormData({
      ...formData,
      confirmPassword: e.target.value,
    });
  };

  const validatePasswordMatch = () => {
    if (formData.password !== formData.confirmPassword) {
      toast.error("Passwords do not match!", { autoClose: 3000 });
      return false;
    }
    return true;
  };

  const signup = async () => {
    if (!validatePasswordMatch()) return; // Check if passwords match

    setLoading(true);
    const { name, email, password } = formData;

    try {
      // Create user with email and password
      const result = await createUserWithEmailAndPassword(auth, email, password);
      const user = result.user;

      // Save user data to Firestore
      await setDoc(doc(fireDB, "users", user.uid), {
        userId: user.uid,
        name,
        email: user.email,
        time: new Date(),
      });

      toast.success("Signup successful!", { autoClose: 3000 });

      // Redirect to login after successful signup
      navigate("/login");
    } catch (error) {
      let errorMessage;
      switch (error.code) {
        case "auth/email-already-in-use":
          errorMessage = "Email already in use. Please login instead.";
          navigate("/login"); // Redirect to login if email already in use
          break;
        case "auth/invalid-email":
          errorMessage = "Invalid email address. Please try again.";
          break;
        case "auth/weak-password":
          errorMessage = "Weak password. Please use a stronger password.";
          break;
        default:
          errorMessage = `Signup failed: ${error.message}`;
      }
      toast.error(errorMessage, { autoClose: 3000 });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="center-container">
      <div className="signup-card">
        <h1 className="signup-header">Signup</h1>

        <label htmlFor="name"></label>
        <input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          className="signup-input-field"
          placeholder="Enter your name"
          aria-label="Name"
          required
        />

        <label htmlFor="email"></label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          className="signup-input-field"
          placeholder="Enter your email"
          aria-label="Email"
          required
        />

        <label htmlFor="password"></label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          className="signup-input-field"
          placeholder="Enter your password"
          aria-label="Password"
          required
        />

        <label htmlFor="confirmPassword"></label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          value={formData.confirmPassword}
          onChange={handleConfirmPassword}
          className="signup-input-field"
          placeholder="Confirm your password"
          aria-label="Confirm Password"
          required
        />

        <button onClick={signup} className="signup-button" disabled={loading}>
          {loading ? "Signing up..." : "Signup"}
        </button>

        <p className="signup-link">
          Already have an account?{" "}
          <span onClick={() => navigate("/login")}>Login here</span>
        </p>
      </div>
    </div>
  );
};

export default Signup;
