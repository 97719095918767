import React, { useState } from "react";
import {
  FaBars,
  FaSignOutAlt,
  FaAngleDown,
  FaAngleUp,
  FaSearch,
} from "react-icons/fa";
import {
  FaHome,
  FaHashtag,
  FaCompass,
  FaCrown,
  FaRegClock,
  FaNetworkWired,
  FaTh,
  FaComments,
  FaInfoCircle,
  FaLifeRing,
  FaUsers,
  FaAd,
  FaCogs,
} from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";

import "./sidebar.css";

const routes = [
  { path: "/", name: "Home", icon: <FaHome /> },
  { path: "/trends", name: "Trends", icon: <FaRegClock /> },
  { path: "/explore", name: "Explore", icon: <FaSearch /> },
  { path: "/network", name: "Network", icon: <FaNetworkWired /> },
  { path: "/chating/*", name: "Chat", icon: <FaComments /> },
  { path: "/premium", name: "Premium", icon: <FaCrown /> },
  { path: "/discussion-rooms", name: "Rooms", icon: <FaUsers /> },
  { path: "/dashboard", name: "Dashboard", icon: <FaTh /> },
  { path: "/settings", name: "Settings", icon: <FaCogs /> },
  { path: "/create-ads", name: "Ads", icon: <FaAd /> },
  { path: "/about", name: "About", icon: <FaInfoCircle /> },
  { path: "/support", name: "Support", icon: <FaLifeRing /> },
  { path: "/support", name: "Support", icon: <FaLifeRing /> },
];

const Sidebar = ({ children, auth }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const navigate = useNavigate();

  const toggleSidebar = () => setSidebarOpen((prev) => !prev);
  const toggleMore = () => setShowMore((prev) => !prev);

  return (
    <div className="sidebar-container">
      <div
        className={`sidebar ${isSidebarOpen ? "visible" : "hidden"} ${
          showMore ? "scrollable" : ""
        }`}
      >
        <h2 className="sidebar-title"></h2>
        <div className="sidebar-links">
          {routes.slice(0, 7).map(({ path, name, icon }, index) => (
            <NavLink
              key={index}
              to={path}
              onClick={() => setSidebarOpen(false)} // Close sidebar on mobile
              className={({ isActive }) =>
                isActive ? "sidebar-link active" : "sidebar-link"
              }
            >
              <div className="sidebar-item">
                <span className="icon">{icon}</span>
                <span className="name">{name}</span>
              </div>
            </NavLink>
          ))}

          <div onClick={toggleMore} className="sidebar-link more-button">
            <div className="sidebar-item">
              <span className="icon">
                {showMore ? <FaAngleUp /> : <FaAngleDown />}
              </span>
              <span className="name">More</span>
            </div>
          </div>

          {showMore && (
            <div className="additional-links">
              {routes.slice(8).map(({ path, name, icon }, index) =>
                name === "Logout" ? (
                  // Handle Logout link to navigate to the `/logout` route
                  <NavLink
                    key={index}
                    to="/logout" // Navigate to the logout route
                    onClick={() => setSidebarOpen(false)} // Close sidebar on mobile
                    className="sidebar-link logout"
                    role="button"
                    aria-label="Logout"
                  >
                    <div className="sidebar-item">
                      <span className="icon">{icon}</span>
                      <span className="name">{name}</span>
                    </div>
                  </NavLink>
                ) : (
                  <NavLink
                    key={index}
                    to={path}
                    onClick={() => setSidebarOpen(false)} // Close sidebar on mobile
                    className={({ isActive }) =>
                      isActive ? "sidebar-link active" : "sidebar-link"
                    }
                  >
                    <div className="sidebar-item">
                      <span className="icon">{icon}</span>
                      <span className="name">{name}</span>
                    </div>
                  </NavLink>
                )
              )}
            </div>
          )}
        </div>
      </div>

      <div className="hamburger-container">
        <div
          className="hamburger"
          onClick={toggleSidebar}
          aria-label="Toggle sidebar"
          aria-expanded={isSidebarOpen}
        >
          <FaBars />
        </div>
      </div>

      <div className={`content ${isSidebarOpen ? "sidebar-visible" : ""}`}>
        {children}
      </div>
    </div>
  );
};

export default Sidebar;
