import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { collection, doc, setDoc, getDocs, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { fireDB } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebase";
import "./post.css";

function PostQuiz({ user }) {
  const [quiz, setQuiz] = useState(""); // Quiz question input
  const [category, setCategory] = useState(""); // Category input
  const [options, setOptions] = useState(["", "", "", ""]); // Four options for quiz
  const [correctOption, setCorrectOption] = useState(null); // Correct option input
  const [image, setImage] = useState(null); // Image for the quiz
  const [imagePreview, setImagePreview] = useState(null); // Image preview for the quiz
  const [loading, setLoading] = useState(false); // Loading state
  const [link, setLink] = useState(""); // Link input
  const [showPreview, setShowPreview] = useState(false); // Show preview toggle

  useEffect(() => {
    if (user) {
      fetchQuizzes();
    }
  }, [user]);

  const fetchQuizzes = async () => {
    try {
      const quizzesQuery = query(
        collection(fireDB, "quizQuestions"),
        where("userId", "==", user.uid)
      );
      const querySnapshot = await getDocs(quizzesQuery);
      const quizzesList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // You can remove the line below if you no longer want to display quizzes
      // setQuizzes(quizzesList);
    } catch (error) {
      toast.error("Failed to fetch quizzes.");
    }
  };

  const handleQuizSubmit = async (e) => {
    e.preventDefault();

    if (!quiz || !category || options.some((option) => option.trim() === "") || correctOption === null) {
      toast.error("Please enter a quiz, category, all options, and select a correct option.");
      return;
    }

    setLoading(true);
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;
      const quizRef = doc(collection(fireDB, "quizQuestions"));
      await setDoc(quizRef, {
        quiz,
        category,
        options: options.filter((option) => option.trim() !== ""), // Filter empty options
        correctOption: correctOption, // Save the correct option
        createdAt: new Date(),
        userId: currentUser.uid,
        image: image || "", // Store image if exists
        link: link || null, // Store link if provided
      });
      toast.success("Quiz posted successfully!");
      setQuiz("");
      setCategory("");
      setOptions(["", "", "", ""]);
      setCorrectOption(null); // Reset correct option
      setImage(null);
      setImagePreview(null); // Reset image preview after posting
      setLink(""); // Reset link after submission
      fetchQuizzes();
    } catch (error) {
      toast.error("Failed to post quiz.");
    } finally {
      setLoading(false);
    }
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = options.map((option, i) => (i === index ? value : option));
    setOptions(updatedOptions);
  };

  const handleCorrectOptionChange = (index) => {
    setCorrectOption(index); // Set the selected option as correct
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    if (!file.type.startsWith("image/")) {
      toast.error("Please upload a valid image file.");
      return;
    }

    if (file.size > 2 * 1024 * 1024) {
      toast.error("Image size should not exceed 2MB.");
      return;
    }

    // Display image preview before uploading
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);

    // Upload image after preview
    const storageRef = ref(storage, `quizImages/${Date.now()}-${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        toast.error("Image upload failed.");
        console.error("Image upload error:", error);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setImage(downloadURL); // Set uploaded image URL
        toast.success("Image uploaded successfully!");
      }
    );
  };

  const handleShowPreviewToggle = () => {
    setShowPreview(!showPreview);
  };

  return (
    <div>
      <h3 className="post-header">Post a New Quiz</h3>
      <form onSubmit={handleQuizSubmit} className="post-form">
        <div className="form-group">
          <label htmlFor="quiz" className="form-label">Quiz Question</label>
          <textarea
            id="quiz"
            value={quiz}
            onChange={(e) => setQuiz(e.target.value)}
            placeholder="Enter your quiz question"
            maxLength={220}
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label htmlFor="category" className="form-label">Category (e.g., #tech, #sports)</label>
          <input
            id="category"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            placeholder="Enter a category (e.g., #tech)"
            className="input-field"
          />
        </div>
        <div className="form-group">
          <label className="form-label">Options (max 30 characters per option)</label>
          {options.map((option, index) => (
            <div key={index}>
              <input
                type="text"
                value={option}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                placeholder={`Option ${index + 1}`}
                maxLength={30}
                className="input-field"
              />
              <label>
                <input
                  type="radio"
                  checked={correctOption === index}
                  onChange={() => handleCorrectOptionChange(index)}
                />
                Correct Option
              </label>
            </div>
          ))}
        </div>
        <div className="form-group">
          <label className="form-label">Upload Image (Optional)</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="input-field"
          />
        </div>
        {imagePreview && (
          <div style={{ marginTop: "10px" }}>
            <img
              src={imagePreview}
              alt="Uploaded Preview"
              style={{ width: "200px", borderRadius: "8px" }}
              className="input-field"
            />
            <button
              type="button"
              onClick={() => {
                setImage(null);
                setImagePreview(null);
              }}
              style={{ display: "block", marginTop: "10px", color: "red" }}
            >
              Remove Image
            </button>
          </div>
        )}
        <div className="form-group">
          <label htmlFor="link" className="form-label">Link (Optional)</label>
          <input
            type="url"
            id="link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Enter a link (optional)"
            className="input-field"
          />
        </div>

        {showPreview && (
          <div className="preview">
            <h4>Preview:</h4>
            <p><strong>Quiz Question:</strong> {quiz}</p>
            <p><strong>Category:</strong> {category}</p>
            <p><strong>Options:</strong></p>
            <ul>
              {options.map((option, index) => (
                <li key={index}>{option}</li>
              ))}
            </ul>
            {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: "200px", borderRadius: "8px" }} />}
            {link && <p><strong>Link:</strong> <a href={link} target="_blank" rel="noopener noreferrer">{link}</a></p>}
          </div>
        )}
        
        <button type="submit" className="post-button" disabled={loading}>
          {loading ? "Posting..." : "Post Quiz"}
        </button>
      </form>
      <button className="post-button" onClick={handleShowPreviewToggle}>
        {showPreview ? "Hide Preview" : "Show Preview"}
      </button>
    </div>
  );
}

export default PostQuiz;
