import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { getDoc, doc, getDocs, collection, updateDoc, setDoc, serverTimestamp } from "firebase/firestore";
import { fireDB, auth } from "../config/firebase";

export const useAuth = (navigate) => {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userNames, setUserNames] = useState({});
  const [error, setError] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isProfileLocked, setIsProfileLocked] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [isCreateAccount, setIsCreateAccount] = useState(false);

  // Fetch user names from Firestore
  const fetchUsers = async () => {
    try {
      setError(null);
      const userSnapshot = await getDocs(collection(fireDB, "users"));
      const userData = {};
      userSnapshot.forEach((doc) => {
        const user = doc.data();
        userData[doc.id] = user.name || "Anonymous"; // Set default name if not provided
      });
      setUserNames(userData);
    } catch (err) {
      console.error("Error fetching users:", err);
      setError("loading...");
    }
  };

  // Fetch subscription and profile lock data
  const fetchSubscriptionData = async (userId) => {
    try {
      const userDocRef = doc(fireDB, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setSubscriptionData(userData.subscription || { isSubscribed: false });
        setIsProfileLocked(userData.isProfileLocked || false);
      }
    } catch (err) {
      console.error("Error fetching subscription data:", err);
    }
  };

  // Toggle profile lock status
  const toggleProfileLock = async (userId, lockStatus) => {
    try {
      const userDocRef = doc(fireDB, "users", userId);
      await updateDoc(userDocRef, { isProfileLocked: lockStatus });
      setIsProfileLocked(lockStatus);
    } catch (err) {
      console.error("Error updating profile lock status:", err);
    }
  };

  // Track user online/offline status
  const updateOnlineStatus = async (userId, status) => {
    if (!userId) return;
    const userRef = doc(fireDB, "users", userId);
    await setDoc(
      userRef,
      { online: status, lastActive: serverTimestamp() },
      { merge: true }
    );
  };

  // Add user to Firestore if they don't exist
  const createUserIfNotExist = async (currentUser) => {
    const userDocRef = doc(fireDB, "users", currentUser.uid);
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {
      await setDoc(userDocRef, {
        name: "Anonymous", // Default name
        email: currentUser.email || "No email",
        createdAt: serverTimestamp(),
        online: true,
        isProfileLocked: false,
        subscription: { isSubscribed: false },
      });
    }
  };

  // Monitor authentication state
  useEffect(() => {
    setLoading(true);
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        setUserId(currentUser.uid);
        await createUserIfNotExist(currentUser); // Ensure user exists in Firestore
        fetchSubscriptionData(currentUser.uid);
        updateOnlineStatus(currentUser.uid, true); // Set user online
      } else {
        setUser(null);
        setUserId(null);
        setSubscriptionData(null);
        setIsProfileLocked(false);
        if (
          window.location.pathname !== "/create-account" &&
          window.location.pathname !== "/signup" &&
          window.location.pathname !== "/login"
        ) {
          setIsCreateAccount(true);
          navigate("/create-account");
        }
      }
      setLoading(false);
    });

    // Update user offline status when they close the tab
    const handleOffline = () => {
      if (userId) updateOnlineStatus(userId, false);
    };

    window.addEventListener("beforeunload", handleOffline);
    return () => {
      unsubscribe();
      window.removeEventListener("beforeunload", handleOffline);
      if (userId) updateOnlineStatus(userId, false); // Ensure offline update when component unmounts
    };
  }, [navigate, isSignup, isCreateAccount, userId]);

  // Fetch user data on component mount
  useEffect(() => {
    fetchUsers();
  }, []);

  // Handle switching between login, signup, and create account
  const handleCreateAccountRedirect = () => {
    setIsCreateAccount(true);
    navigate("/create-account");
  };

  const handleSignupRedirect = () => {
    setIsSignup(true);
    navigate("/signup");
  };

  const handleLoginRedirect = () => {
    setIsSignup(false);
    setIsCreateAccount(false);
    navigate("/login");
  };

  return {
    user,
    userId,
    loading,
    userNames,
    error,
    subscriptionData,
    isProfileLocked,
    toggleProfileLock,
    handleCreateAccountRedirect,
    handleSignupRedirect,
    handleLoginRedirect,
  };
};


