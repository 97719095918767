import React, { useEffect, useState } from "react";
import { useAuth } from '../../hooks/useAuth';  // Importing the useAuth hook
import './otherStyle/termAndCondition.css';

const TermsAndConditions = () => {
  const { user, userNames, loading } = useAuth();  // Getting user data from the useAuth hook
  const [userName, setUserName] = useState("");

  // Setting the user name when the user data is available
  useEffect(() => {
    if (user && userNames[user.uid]) {
      setUserName(userNames[user.uid]);
    }
  }, [user, userNames]);

  if (loading) {
    return <div>Loading...</div>; // Show loading state while the user data is being fetched
  }

  return (
    <div className="terms-conditions">
      <div className="terms-container">
        <h1 className="terms-title">Terms and Conditions</h1>

        {user && (
          <p className="user-info">
            Welcome, <strong>{userName || "User"}</strong>! By using our services, you agree to comply with the following terms and conditions.
          </p>
        )}

        <p className="terms-paragraph">
          Welcome to our quiz social networking platform! By using our services, you agree to comply with the following terms and conditions. Please read them carefully.
        </p>

        <h2 className="terms-subtitle">1. Acceptance of Terms</h2>
        <p className="terms-paragraph">
          By accessing or using our platform, you acknowledge that you have read, understood, and agree to be bound by these Terms and Conditions. If you do not agree, please do not use our platform.
        </p>

        <h2 className="terms-subtitle">2. Account Registration</h2>
        <p className="terms-paragraph">
          To access certain features, you may need to register for an account. You are responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account.
        </p>

        <h2 className="terms-subtitle">3. Use of the Platform</h2>
        <p className="terms-paragraph">
          You agree to use our platform only for lawful purposes. You are prohibited from using the platform to:
          <ul>
            <li>Upload, post, or transmit any content that is unlawful, harmful, or objectionable.</li>
            <li>Engage in any behavior that violates the rights of other users or interferes with the functionality of the platform.</li>
            <li>Attempt to gain unauthorized access to any portion of the platform or its systems.</li>
          </ul>
        </p>

        <h2 className="terms-subtitle">4. Intellectual Property</h2>
        <p className="terms-paragraph">
          All content on the platform, including quizzes, designs, text, and graphics, is the property of our platform or its content creators. You may not use, modify, or distribute any content without our prior written permission.
        </p>

        <h2 className="terms-subtitle">5. User-Generated Content</h2>
        <p className="terms-paragraph">
          By posting content on the platform, including quizzes, comments, and feedback, you grant us a non-exclusive, royalty-free, and worldwide license to use, modify, and display such content for the purposes of operating the platform. You represent that you have the necessary rights to post any content that you submit.
        </p>

        <h2 className="terms-subtitle">6. Premium Features</h2>
        <p className="terms-paragraph">
          Our platform offers premium features for advanced quizzes in physics, chemistry, and math. By purchasing a premium subscription, you agree to pay the associated fees. Premium content is for personal use only and may not be shared or redistributed.
        </p>

        <h2 className="terms-subtitle">7. Termination</h2>
        <p className="terms-paragraph">
          We reserve the right to terminate your account at any time, without notice, if you violate these Terms and Conditions or engage in any activity that harms the platform or its users. You may also terminate your account at any time by contacting us.
        </p>

        <h2 className="terms-subtitle">8. Disclaimer of Warranties</h2>
        <p className="terms-paragraph">
          Our platform is provided on an "as is" and "as available" basis. We do not warrant that the platform will be uninterrupted, secure, or free from errors. To the fullest extent permitted by law, we disclaim all warranties, express or implied.
        </p>

        <h2 className="terms-subtitle">9. Limitation of Liability</h2>
        <p className="terms-paragraph">
          In no event shall we be liable for any direct, indirect, incidental, or consequential damages arising from the use of the platform or the inability to use the platform, even if we have been advised of the possibility of such damages.
        </p>

        <h2 className="terms-subtitle">10. Changes to Terms</h2>
        <p className="terms-paragraph">
          We may modify these Terms and Conditions at any time. Any changes will be effective immediately upon posting. By continuing to use the platform after changes are made, you agree to be bound by the updated terms.
        </p>

        <p className="terms-paragraph">Last Updated: [11.9.2024]</p>
      </div>
    </div>
  );
};

export default TermsAndConditions;
