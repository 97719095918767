import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { collection, doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { fireDB, storage } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import './post.css'

function PostFacts({ user, factToEdit }) {
  const [fact, setFact] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(""); 
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if (user && factToEdit) {
      setFact(factToEdit.fact);
      setCategory(factToEdit.category);
      setImagePreview(factToEdit.image || null);
      setLink(factToEdit.link || ""); 
    }
  }, [user, factToEdit]);

  const handlePostFact = async (e) => {
    e.preventDefault();
    if (!fact || !category) {
      toast.error("Please enter a fact and category.");
      return;
    }

    setLoading(true);
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        toast.error("User not authenticated. Please log in.");
        return;
      }

      const factData = {
        fact,
        category,
        createdAt: new Date(),
        userId: currentUser.uid,
        options: ["Agree", "Disagree"], 
        link: link || null,
      };

      if (image) {
        const storageRef = ref(storage, `factimages/${new Date().toISOString()}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        await uploadTask;
        const imageURL = await getDownloadURL(storageRef);
        factData.image = imageURL;
      }

      const factRef = factToEdit
        ? doc(fireDB, "factQuestions", factToEdit.id) 
        : doc(collection(fireDB, "factQuestions"));

      await setDoc(factRef, factData, { merge: !!factToEdit });

      toast.success(factToEdit ? "Fact updated successfully!" : "Fact posted successfully!");
      setFact("");
      setCategory("");
      setImage(null);
      setImagePreview(null);
      setLink(""); 
    } catch (error) {
      toast.error("Failed to post fact.");
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    if (value.length > 50) {
      toast.error("Category cannot exceed 50 characters.");
      return;
    }
    setCategory(value); // Simply set the category as plain text
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    } else {
      toast.error("Please select a valid image file.");
    }
  };

  return (
    <div className="container">
    <h3 className="post-header">{factToEdit ? "Edit Fact" : "Create a New Fact"}</h3>
    <form onSubmit={handlePostFact} className="post-form">
      <div className="form-group">
        <label htmlFor="fact" className="form-label">Fact Question</label>
        <textarea
          id="fact"
          value={fact}
          onChange={(e) => setFact(e.target.value)}
          placeholder="Enter your fact (max 220 characters)"
          maxLength={220}
          className="text-area"
        />
      </div>
      <div className="form-group">
        <label htmlFor="category" className="form-label">Category</label>
        <input
          type="text"
          id="category"
          value={category}
          onChange={handleCategoryChange}
          placeholder="Enter a category (e.g., science)"
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="link" className="form-label">Optional Link</label>
        <input
          type="url"
          id="link"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          placeholder="Enter an optional link (e.g., https://example.com)"
          className="input-field"
        />
      </div>
      <div className="form-group">
        <label htmlFor="image" className="form-label">Upload Image (Optional)</label>
        <input
          type="file"
          id="image"
          accept="image/*"
          onChange={handleImageChange}
          className="input-file"
        />
      </div>
      {imagePreview && (
        <div className="image-preview">
          <img
            src={imagePreview}
            alt="Uploaded Preview"
            className="image"
          />
          <button
            type="button"
            onClick={() => {
              setImage(null);
              setImagePreview(null);
            }}
            className="remove-button"
          >
            Remove Image
          </button>
        </div>
      )}
      <div className="form-group">
        <label className="form-label">
          <input
            type="checkbox"
            checked={showPreview}
            onChange={() => setShowPreview(!showPreview)}
            className="input-checkbox"
          />
          Show Preview
        </label>
      </div>
      {showPreview && (
        <div className="preview">
          <h4>Preview:</h4>
          <p><strong>Fact:</strong> {fact}</p>
          <p><strong>Category:</strong> {category}</p>
          {imagePreview && <img src={imagePreview} alt="Preview" className="image" />}
          {link && <p><strong>Link:</strong> <a href={link} target="_blank" rel="noopener noreferrer">{link}</a></p>}
        </div>
      )}
      <button type="submit" className="post-button" disabled={loading}>
        {loading ? "Posting..." : factToEdit ? "Update Fact" : "Post Fact"}
      </button>
    </form>
  </div>
  
  );
}

export default PostFacts;
