import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  startAfter,
  query,
  orderBy,
  limit,
  doc,
  getDoc,
  where,
} from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const PAGE_SIZE = 3;

const FactFetch = () => {
  const [factQuestions, setFactQuestions] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);

  // Firebase Functions to call the ML model
  const callMLModel = async (factText) => {
    const functions = getFunctions();
    const predictFactPopularity = httpsCallable(functions, "predictFactPopularity");

    try {
      const result = await predictFactPopularity({ text: factText });
      return result.data;
    } catch (error) {
      console.error("Error calling ML model:", error);
      return null;
    }
  };

  const fetchFactQuestions = async () => {
    try {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      const q = lastVisible
        ? query(
            collection(fireDB, "factQuestions"),
            where("createdAt", ">=", sevenDaysAgo),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(PAGE_SIZE)
          )
        : query(
            collection(fireDB, "factQuestions"),
            where("createdAt", ">=", sevenDaysAgo),
            orderBy("createdAt", "desc"),
            limit(PAGE_SIZE)
          );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const factQuestions = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const factText = data.fact;
            const popularityPrediction = await callMLModel(factText);

            return {
              id: doc.id,
              userId: data.userId || "Unknown User",
              createdAt: data.createdAt,
              fact: data.fact,
              image: data.image || "",
              link: data.link || "", // Include the link field
              tags: data.tags || [], // Include the tags field
              likes: data.likes || 0,
              facts: data.facts || {},
              retweets: data.retweets || [],
              comments: data.comments || [],
              popularityPrediction,
              rankingScore: (data.facts?.[0] || 0) - (data.facts?.[1] || 0),
            };
          })
        );

        const uniqueUserIds = [
          ...new Set(factQuestions.map((f) => f.userId).filter((id) => id)),
        ];

        const userDocs = await Promise.all(
          uniqueUserIds.map((id) =>
            getDoc(doc(fireDB, "users", id)).then((userDoc) =>
              userDoc.exists() ? { id, ...userDoc.data() } : null
            )
          )
        );

        const userMap = Object.fromEntries(
          userDocs.filter(Boolean).map((user) => [user.id, user])
        );

        const newFacts = factQuestions.map((fact) => ({
          ...fact,
          user: userMap[fact.userId] || { name: "Unknown User", profileImage: "" },
        }));

        // Sorting by ranking score and createdAt for newer facts to be on top
        newFacts.sort((a, b) => {
          if (b.rankingScore === a.rankingScore) {
            return b.createdAt.seconds - a.createdAt.seconds;
          }
          return b.rankingScore - a.rankingScore;
        });

        // Prevent adding the same facts again (checking if the fact already exists by id)
        setFactQuestions((prev) => {
          const updatedFactQuestions = [...prev];
          newFacts.forEach((newFact) => {
            if (!updatedFactQuestions.find((fact) => fact.id === newFact.id)) {
              updatedFactQuestions.push(newFact);
            }
          });
          return updatedFactQuestions;
        });

        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      setError("Failed to fetch fact questions. Please try again later.");
      console.error(error);
    }
  };

  useEffect(() => {
    fetchFactQuestions();
  }, []);

  return {
    factQuestions,
    hasMore,
    error,
    fetchFactQuestions,
  };
};

export default FactFetch;
