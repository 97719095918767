import React, { useEffect, useState, useMemo } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth"; // Import useAuth hook

function ProfileQuiz() {
  const { currentUser, loading: authLoading } = useAuth();
  const [postedQuestions, setPostedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // Error state

 
  useEffect(() => {
      if (!authLoading) {
        if (currentUser) {
          fetchPostedQuestions(currentUser);
        } else {
          setLoading(false); // Stop loading if user is not logged in
        }
      }
    }, [currentUser, authLoading]);

  const fetchPostedQuestions = async () => {
    setLoading(true);
    setError(null); // Reset any previous errors

    try {
      const questionsQuery = query(
        collection(fireDB, "quizQuestions"),
        where("userId", "==", currentUser.uid) // Replace with dynamic user ID logic if needed
      );
      const questionsSnapshot = await getDocs(questionsQuery);
      const questionsList = questionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPostedQuestions(questionsList);
    } catch (error) {
      console.error("Error fetching posted questions:", error);
      setError("Failed to fetch questions. Please try again.");
      toast.error("Failed to fetch questions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const calculateEngagementScore = (question) => {
    return (
      (question.likes || 0) * 1.5 +
      ((question.comments?.length || 0) * 1.2) +
      (question.shares || 0) * 1.3
    );
  };

  const totalEngagements = useMemo(() => {
    return postedQuestions.reduce(
      (total, question) => total + calculateEngagementScore(question),
      0
    );
  }, [postedQuestions]);

  const highlightedQuestions = useMemo(() => {
    return postedQuestions.filter((question) => {
      if (!question.highlighted || !question.highlightedAt) return false;
      const highlightedAt = question.highlightedAt?.toMillis?.();
      if (!highlightedAt) return false;
      return (Date.now() - highlightedAt) / (1000 * 60 * 60) <= 72;
    });
  }, [postedQuestions]);

  const pinnedQuestions = useMemo(() => {
    return postedQuestions.filter((question) => question.pinned === true);
  }, [postedQuestions]);

  if (loading) {
    return <Spinner />;
  }

  if (error) {
    return <p className="error-message">{error}</p>;
  }

  return (
    <div className="dashboard-container">
      <div className="total-stats">
        <h3>Total Questions: {postedQuestions.length}</h3>
        <h3>Total Engagements: {totalEngagements.toFixed(2)}</h3>
      </div>

      <div className="highlighted-questions">
        <h3>Highlighted Questions (Last 72 Hours)</h3>
        {highlightedQuestions.length > 0 ? (
          <ul className="questions-list">
            {highlightedQuestions.map((question) => (
              <li key={question.id} className="question-item highlighted">
                <div className="question-details">
                  <p><strong>Q:</strong> {question.question}</p>
                  <p><strong>Options:</strong> {question.options.join(", ")}</p>
                  <p><strong>Correct Answer:</strong> {question.correctAnswer}</p>
                  <p><strong>Engagement Score:</strong> {calculateEngagementScore(question).toFixed(2)}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No highlighted questions available in the last 72 hours.</p>
        )}
      </div>

      <div className="pinned-questions">
        <h3>Pinned Questions</h3>
        {pinnedQuestions.length > 0 ? (
          <ul className="questions-list">
            {pinnedQuestions.map((question) => (
              <li key={question.id} className="question-item pinned">
                <div className="question-details">
                  <p><strong>Q:</strong> {question.question}</p>
                  <p><strong>Options:</strong> {question.options.join(", ")}</p>
                  <p><strong>Correct Answer:</strong> {question.correctAnswer}</p>
                  <p><strong>Engagement Score:</strong> {calculateEngagementScore(question).toFixed(2)}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No pinned questions available.</p>
        )}
      </div>
    </div>
  );
}

export default ProfileQuiz;
