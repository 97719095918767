import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fireDB, auth } from "../config/firebase";
import './saveButton.css'

const SaveButton = ({ questionId, questionData }) => {
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(false); // Track loading state

  useEffect(() => {
    const checkIfSaved = async () => {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      try {
        const savedPostDoc = await getDoc(doc(fireDB, "savedPosts", userId));
        if (savedPostDoc.exists()) {
          const savedPosts = savedPostDoc.data();
          setIsSaved(savedPosts[questionId] ? true : false);
        }
      } catch (error) {
        console.error("Error checking save status:", error);
      }
    };

    checkIfSaved();
  }, [questionId]);

  const handleSave = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error("User not logged in.");
      return;
    }

    setLoading(true); // Set loading before the save operation

    try {
      const userSavedPostsRef = doc(fireDB, "savedPosts", userId);

      // Fetch existing saved posts to avoid overwriting
      const savedPostsDoc = await getDoc(userSavedPostsRef);
      let savedPosts = savedPostsDoc.exists() ? savedPostsDoc.data() : {};

      if (!savedPosts[questionId]) {
        savedPosts[questionId] = {
          question: questionData.question,
          createdAt: questionData.createdAt,
          userId: questionData.userId,
        };

        // Update saved posts with the new post added
        await setDoc(userSavedPostsRef, savedPosts, { merge: true });
        setIsSaved(true);
      }
    } catch (error) {
      console.error("Error saving post:", error);
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="saved">
        <button onClick={handleSave} disabled={isSaved || loading}>
      {loading ? "Saving..." : isSaved ? "Saved" : "Save"}
    </button>
    </div>
    
  );
};

export default SaveButton;
