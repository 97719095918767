import {
  collection,
  getDocs,
  query,
  orderBy,
  getDoc,
  doc,
} from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import nlp from 'compromise';

// Ensure that the calculateRank function is defined at the top level
const calculateRank = (item) => {
  if (item.type === "fact") return item.Agree - item.Disagree;
  if (item.type === "poll") return item.polls[1] - item.polls[0];
  if (item.type === "vote") return item.Yes - item.No;
  if (item.type === "quiz") return item.correctAnswerShown ? 1 : 0;
  return 0;
};

// Preprocess text for consistency
const preprocessText = (text) => {
  return text.toLowerCase().replace(/[^a-z0-9\s]/g, ""); // Remove non-alphanumeric except spaces
};

// Classify the emotion of the given text using only `compromise`
const classifyEmotion = async (text) => {
  const processedText = preprocessText(text);
  console.log("Processed Text:", processedText);

  try {
      // Emotion classification using Compromise
      const doc = nlp(processedText);
      
      // Check for specific emotions based on keyword matching
      if (doc.match('angry').found || doc.match('rage').found || doc.match('furious').found) return "Angry";
      if (doc.match('happy').found || doc.match('joy').found || doc.match('delighted').found) return "Happy";
      if (doc.match('sad').found || doc.match('unhappy').found || doc.match('depressed').found) return "Sad";
      if (doc.match('disappointed').found) return "Disappointed";

      // If no match, default to Neutral
      console.log("Returning Neutral due to no detected emotion");
      return "Neutral";
  } catch (error) {
      console.error("Error with emotion classification:", error);
      return "Neutral";
  }
};

// Fetch data and classify emotions
const fetchData = async (
  category,
  type,
  id,
  setLoading,
  setError,
  setAllData,
  setEmotionStats,
  setCategories,
  setVisibleCategories,
  CATEGORIES_BATCH_SIZE
) => {
  setLoading(true);
  setError(null);

  try {
      const [factSnapshot, pollSnapshot, voteSnapshot, quizSnapshot] = await Promise.all([
          getDocs(query(collection(fireDB, "factQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "pollQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "voteQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "quizQuestions"), orderBy("createdAt", "desc"))),
      ]);

      const processSnapshot = (snapshot, type) =>
          snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data(), type }));

      const [facts, polls, votes, quizzes] = [
          processSnapshot(factSnapshot, "Fact"),
          processSnapshot(pollSnapshot, "Poll"),
          processSnapshot(voteSnapshot, "Vote"),
          processSnapshot(quizSnapshot, "Quiz"),
      ];

      const combinedData = [...facts, ...polls, ...votes, ...quizzes];

      const userIds = [...new Set(combinedData.map((item) => item.userId).filter(Boolean))];
      const userProfiles = await Promise.all(
          userIds.map((id) =>
              getDoc(doc(fireDB, "users", id)).then((snapshot) =>
                  snapshot.exists() ? { id, ...snapshot.data() } : null
              )
          )
      );

      const userMap = Object.fromEntries(userProfiles.filter(Boolean).map((user) => [user.id, user]));

      const dataWithUsers = combinedData.map((item) => ({
          ...item,
          user: userMap[item.userId] || { name: "Unknown User", profileImage: "" },
      }));

      const postsWithEmotions = await Promise.all(
          dataWithUsers.map(async (item) => ({
              ...item,
              emotion: await classifyEmotion(item.fact || item.poll || item.vote || item.quiz || ""),
              rank: calculateRank(item),  // `calculateRank` should be in scope here
          }))
      );

      postsWithEmotions.sort((a, b) => b.rank - a.rank);

      setAllData(postsWithEmotions);

      const stats = postsWithEmotions.reduce((acc, post) => {
          acc[post.emotion] = (acc[post.emotion] || 0) + 1;
          return acc;
      }, {});
      setEmotionStats(stats);

      const allCategories = Array.from(
          new Set(postsWithEmotions.map((item) => item.category).filter(Boolean))
      ).slice(0, 100);
      setCategories(allCategories);
      setVisibleCategories(allCategories.slice(0, CATEGORIES_BATCH_SIZE));

      let filtered = postsWithEmotions;
      if (category) filtered = filtered.filter((item) => item.category === category);
      if (type) filtered = filtered.filter((item) => item.type.toLowerCase() === type.toLowerCase());
      if (id) filtered = filtered.filter((item) => item.id === id);

      return filtered;
  } catch (error) {
      console.error("Error fetching data:", error);
      setError(`Failed to load data. Error: ${error.message}`);
  } finally {
      setLoading(false);
  }
};

export default fetchData;
