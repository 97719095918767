import React, { useEffect, useState } from "react";
import { useAuth } from "../../hooks/useAuth"; // Path to the useAuth hook
import { getDocs, collection } from "firebase/firestore"; // Firebase Firestore functions
import { fireDB } from "../../config/firebase";
import './subscriptionStyle/payout.css';

const Payout = () => {
  const { userNames, loading, error, userId } = useAuth(); // Fetch user names and userId from the useAuth hook
  const [posts, setPosts] = useState([]); // Define state to store posts
  const [totalEngagementScore, setTotalEngagementScore] = useState(0);
  const [userPayouts, setUserPayouts] = useState([]);

  // Engagement weights
  const engagementWeight = {
    likes: 1.0,
    retweets: 2.0,
    comments: 1.5,
    shares: 3.0,
  };

  // Calculate engagement for a single post based on type
  const calculateEngagement = (post) => {
    let engagement = 0;

    switch (post.type) {
      case "fact":
        engagement =
          (post.likes || 0) * engagementWeight.likes +
          (post.retweets || 0) * engagementWeight.retweets +
          (post.comments?.length || 0) * engagementWeight.comments +
          (post.shares?.length || 0) * engagementWeight.shares;
        break;

      case "poll":
        engagement =
          (post.likes || 0) * engagementWeight.likes +
          (post.retweets || 0) * engagementWeight.retweets +
          (post.comments?.length || 0) * engagementWeight.comments + 
          (post.shares?.length || 0) * engagementWeight.shares;
        break;

      case "vote":
        engagement =
          (post.retweets || 0) * engagementWeight.retweets +
          (post.likes || 0) * engagementWeight.likes + 
          (post.comments?.length || 0) * engagementWeight.comments + 
          (post.shares?.length || 0) * engagementWeight.shares;
        break;

      case "quiz":
        engagement =
          (post.likes || 0) * engagementWeight.likes +
          (post.retweets || 0) * engagementWeight.retweets +
          (post.comments?.length || 0) * engagementWeight.comments + 
          (post.shares?.length || 0) * engagementWeight.shares;
        break;

      default:
        break;
    }

    return engagement;
  };

  // Calculate total engagement score across all posts
  const calculateTotalEngagementScore = () => {
    return posts.reduce((total, post) => total + calculateEngagement(post), 0);
  };

  // Calculate payouts for all users
  const calculateUserPayouts = () => {
    const totalScore = calculateTotalEngagementScore();
    setTotalEngagementScore(totalScore);

    // Create a map of userId to their engagement score to avoid looping through posts multiple times
    const userEngagementMap = posts.reduce((map, post) => {
      if (map[post.userId]) {
        map[post.userId] += calculateEngagement(post);
      } else {
        map[post.userId] = calculateEngagement(post);
      }
      return map;
    }, {});

    const payouts = Object.entries(userNames).map(([userId, name]) => {
      const userEngagementScore = userEngagementMap[userId] || 0;
      const payout = totalScore > 0 ? (userEngagementScore / totalScore) * 126 : 0;
      return {
        userId,
        name,
        engagementScore: userEngagementScore,
        payout: payout.toFixed(2),
      };
    });

    setUserPayouts(payouts);
  };

  // Fetch posts data from Firestore
  const fetchPosts = async () => {
    try {
      const factPostsSnapshot = await getDocs(collection(fireDB, "factQuestions"));
      const pollPostsSnapshot = await getDocs(collection(fireDB, "pollQuestions"));
      const votePostsSnapshot = await getDocs(collection(fireDB, "voteQuestions"));
      const quizPostsSnapshot = await getDocs(collection(fireDB, "quizQuestions"));

      // Combine all posts into a single array
      const allPosts = [
        ...factPostsSnapshot.docs.map((doc) => ({ ...doc.data(), type: "fact", postId: doc.id })),
        ...pollPostsSnapshot.docs.map((doc) => ({ ...doc.data(), type: "poll", postId: doc.id })),
        ...votePostsSnapshot.docs.map((doc) => ({ ...doc.data(), type: "vote", postId: doc.id })),
        ...quizPostsSnapshot.docs.map((doc) => ({ ...doc.data(), type: "quiz", postId: doc.id }))
      ];

      // Set the fetched posts into the state
      setPosts(allPosts);
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  // Fetch posts on component mount
  useEffect(() => {
    fetchPosts();
  }, []);

  useEffect(() => {
    if (!loading && userId && Object.keys(userNames).length > 0 && posts.length > 0) {
      calculateUserPayouts();
    }
  }, [userNames, posts, loading, userId]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="payout-component">
      <h1>Payout Distribution</h1>
      <h3>Total Engagement Score: {totalEngagementScore.toFixed(2)}</h3>

      <table>
        <thead>
          <tr>
            <th>User Name</th>
            <th>Engagement Score</th>
            <th>Payout (₹)</th>
          </tr>
        </thead>
        <tbody>
          {userPayouts.map((user) => (
            <tr key={user.userId}>
              <td>{user.name}</td>
              <td>{user.engagementScore.toFixed(2)}</td>
              <td>₹{user.payout}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Payout;
