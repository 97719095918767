import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { fireDB } from "../config/firebase";
import PostedFacts from "../components/posted/PostedFacts";
import PostedPoll from "../components/posted/PostedPoll";
import PostedQuiz from "../components/posted/PostedQuiz";
import PostedVote from "../components/posted/PostedVote";
import "../authStyle/postDashboard.css";

function PostDashboard() {
  const [activeTab, setActiveTab] = useState("facts");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State to store fetched data
  const [postedFacts, setPostedFacts] = useState([]);
  const [postedPolls, setPostedPolls] = useState([]);
  const [postedVotes, setPostedVotes] = useState([]);
  const [postedQuestions, setPostedQuestions] = useState([]);

  // Tab options
  const tabs = [
    { id: "facts", label: "Facts" },
    { id: "polls", label: "Polls" },
    { id: "votes", label: "Votes" },
    { id: "quizzes", label: "Quizzes" },
  ];

  // Fetch data from Firebase Firestore
  useEffect(() => {
    const fetchPostedData = async () => {
      try {
        setLoading(true);
        const factsSnapshot = await getDocs(collection(fireDB, "postedFacts"));
        const pollsSnapshot = await getDocs(collection(fireDB, "postedPolls"));
        const votesSnapshot = await getDocs(collection(fireDB, "postedVotes"));
        const quizzesSnapshot = await getDocs(collection(fireDB, "postedQuizzes"));

        setPostedFacts(factsSnapshot.docs.map((doc) => doc.data()));
        setPostedPolls(pollsSnapshot.docs.map((doc) => doc.data()));
        setPostedVotes(votesSnapshot.docs.map((doc) => doc.data()));
        setPostedQuestions(quizzesSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching posted data:", error);
        setError("Failed to load data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchPostedData();
  }, []);

  return (
    <div className="post-container">
      {/* Tab Navigation */}
      <div className="tab-navigation">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`tab ${activeTab === tab.id ? "active" : ""}`}
          >
            {tab.label}
          </div>
        ))}
      </div>

      {/* Error Message */}
      {error && <div className="error-message">{error}</div>}

      {/* Loading Spinner */}
      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div>
          {activeTab === "facts" && <PostedFacts data={postedFacts} />}
          {activeTab === "polls" && <PostedPoll data={postedPolls} />}
          {activeTab === "votes" && <PostedVote data={postedVotes} />}
          {activeTab === "quizzes" && <PostedQuiz data={postedQuestions} />}
        </div>
      )}
    </div>
  );
}

export default PostDashboard;
