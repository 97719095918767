import React, { useState, useEffect } from 'react';
import { fireDB, doc, getDoc, updateDoc, serverTimestamp } from '../config/firebase';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QRCodeCanvas } from 'qrcode.react';
//import './budget.css';
import { useAuth } from '../hooks/useAuth';

const AdsBudget = () => {
  const [isSubscribed, setIsSubscribed] = useState(false); // Subscription status
  const [paymentURL, setPaymentURL] = useState(''); // Payment URL for subscription
  const [isProcessing, setIsProcessing] = useState(false); // Loading state for subscription
  const navigate = useNavigate();
  const { userId, loading } = useAuth(navigate); // Assuming `useAuth` gives us the current user ID

  useEffect(() => {
    if (userId) {
      fetchSubscriptionStatus(userId); // Fetch subscription status when userId is available
    }
  }, [userId]);

  // Fetch the subscription status of the user
  const fetchSubscriptionStatus = async (uid) => {
    try {
      const userRef = doc(fireDB, 'users', uid);
      const docSnap = await getDoc(userRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        setIsSubscribed(!!userData.subscription); // Set subscription status from user data
      } else {
        setIsSubscribed(false);
      }
    } catch (error) {
      console.error('Error fetching subscription status:', error);
      toast.error('Failed to fetch subscription status.');
    }
  };

  // Handle subscription payment
  const handleSubscription = async () => {
    if (!userId) {
      toast.error('You need to log in to subscribe.');
      navigate('/login');
      return;
    }

    try {
      setIsProcessing(true);
      const userRef = doc(fireDB, 'users', userId);

      if (isSubscribed) {
        // Unsubscribe logic
        await updateDoc(userRef, {
          subscription: null,
          timestamp: serverTimestamp(),
        });
        setIsSubscribed(false);
        toast.info('You have unsubscribed.');
      } else {
        // Subscription payment logic
        setPaymentURL(`https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay`);
        toast.success('Subscription payment pending. Scan QR code');
      }
    } catch (error) {
      console.error('Error handling subscription:', error);
      toast.error('Failed to update subscription status.');
    } finally {
      setIsProcessing(false);
    }
  };

  // Confirm subscription after payment
  const confirmSubscription = async () => {
    try {
      const userRef = doc(fireDB, 'users', userId);
      await updateDoc(userRef, {
        subscription: true,
        timestamp: serverTimestamp(),
      });
      setIsSubscribed(true);
      toast.success('Subscription activated successfully!');
    } catch (error) {
      console.error('Error confirming subscription:', error);
      toast.error('Failed to activate subscription.');
    }
  };

  return (
    <div className="budget-page">
      <h1>Manage Your Budget</h1>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {isSubscribed ? (
            <p>Your subscription is active. You can access all features.</p>
          ) : (
            <div>
              <p>Your subscription is inactive. Please subscribe to continue.</p>
              <button onClick={handleSubscription} disabled={isProcessing}>
                {isProcessing ? 'Processing...' : 'Subscribe (126/month)'}
              </button>
            </div>
          )}
        </div>
      )}

      {paymentURL && !isSubscribed && (
        <div className="qr-code-container">
          <h3>Scan to Pay</h3>
          <QRCodeCanvas value={paymentURL} size={256} />
        </div>
      )}

      {/* After successful payment, allow manual confirmation */}
      {isSubscribed === false && (
        <div>
          <button onClick={confirmSubscription}>Confirm Subscription (After Payment)</button>
        </div>
      )}
    </div>
  );
};

export default AdsBudget;
