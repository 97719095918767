import React, { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth"; // Import your useAuth hook

function ProfileFact() {
  const { currentUser, loading: authLoading } = useAuth(); // Get user info
  const [postedFacts, setPostedFacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Fetch facts when user is authenticated
  useEffect(() => {
    if (!authLoading) {
      if (currentUser) {
        fetchPostedFacts(currentUser);
      } else {
        setLoading(false); // Stop loading if user is not logged in
      }
    }
  }, [currentUser, authLoading]);

  // Fetch user's posted facts from Firestore
  const fetchPostedFacts = async (currentUser) => {
    setLoading(true);
    try {
      const factsQuery = query(
        collection(fireDB, "factQuestions"),
        where("userId", "==", currentUser.uid)
      );
      const factsSnapshot = await getDocs(factsQuery);
      const factsList = factsSnapshot.docs.map((doc) => ({
        id: doc.id,
        fact: doc.data().fact || "No Fact Provided",
        likes: doc.data().likes || 0,
        shares: doc.data().shares || 0,
        comments: doc.data().comments || [],
        pinned: doc.data().pinned || false,
        highlighted: doc.data().highlighted || false,
        highlightedAt: doc.data().highlightedAt || null,
      }));
      setPostedFacts(factsList);
    } catch (error) {
      console.error("Error fetching facts:", error);
      toast.error("Failed to fetch facts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Calculate engagement score
  const calculateEngagementScore = (fact) => {
    return (
      (fact.likes || 0) * 1.5 +
      (fact.comments?.length || 0) * 1.2 +
      (fact.shares || 0) * 1.3
    );
  };

  // Pinned posts
  const pinnedPosts = postedFacts.filter((fact) => fact.pinned);

  // Highlighted posts within the last 72 hours
  const highlightedPosts = postedFacts.filter((fact) => {
    if (!fact.highlighted || !fact.highlightedAt) return false;
    const highlightedAt = fact.highlightedAt.toMillis();
    return (Date.now() - highlightedAt) / (1000 * 60 * 60) <= 72;
  });

  // Show loading spinner while data is fetching
  if (authLoading || loading) {
    return <Spinner />;
  }

  return (
    <div className="dashboard-container">
      {/* Pinned Posts Section */}
      <div className="pinned-posts">
        <h3>Pinned Posts</h3>
        {pinnedPosts.length > 0 ? (
          <ul className="facts-list">
            {pinnedPosts.map((fact) => (
              <li key={fact.id} className="fact-item pinned">
                <div className="fact-details">
                  <p><strong>Fact:</strong> {fact.fact}</p>
                  <div className="fact-performance">
                    <p><i className="fa fa-thumbs-up"></i> {fact.likes} Likes</p>
                    <p><i className="fa fa-share-alt"></i> {fact.shares} Shares</p>
                    <p><i className="fa fa-comment"></i> {fact.comments.length} Comments</p>
                    <p><strong>Engagement Score:</strong> {calculateEngagementScore(fact).toFixed(2)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No pinned posts available.</p>
        )}
      </div>

      {/* Highlighted Posts Section */}
      <div className="highlighted-posts">
        <h3>Highlighted Posts (Last 72 Hours)</h3>
        {highlightedPosts.length > 0 ? (
          <ul className="facts-list">
            {highlightedPosts.map((fact) => (
              <li key={fact.id} className="fact-item highlighted">
                <div className="fact-details">
                  <p><strong>Fact:</strong> {fact.fact}</p>
                  <p><strong>Highlighted At:</strong> {new Date(fact.highlightedAt.toMillis()).toLocaleString()}</p>
                  <div className="fact-performance">
                    <p><i className="fa fa-thumbs-up"></i> {fact.likes} Likes</p>
                    <p><i className="fa fa-share-alt"></i> {fact.shares} Shares</p>
                    <p><i className="fa fa-comment"></i> {fact.comments.length} Comments</p>
                    <p><strong>Engagement Score:</strong> {calculateEngagementScore(fact).toFixed(2)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p>No highlighted posts available in the last 72 hours.</p>
        )}
      </div>
    </div>
  );
}

export default ProfileFact;
