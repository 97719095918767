import { fireDB } from '../../config/firebase';
import { collection, getDocs, addDoc, doc, updateDoc, getDoc, deleteDoc } from 'firebase/firestore';

// Function to fetch all rooms
export const fetchRooms = async () => {
  try {
    const roomsSnapshot = await getDocs(collection(fireDB, 'rooms'));
    const roomsData = roomsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const creatorIds = [...new Set(roomsData.map((room) => room.creatorId))];
    const creatorData = await Promise.all(
      creatorIds.map(async (id) => {
        if (!id) return null;
        const creatorDoc = await getDoc(doc(fireDB, 'users', id));
        return creatorDoc.exists() ? { id, name: creatorDoc.data().name } : null;
      })
    );

    const creators = creatorData.reduce((acc, creator) => {
      if (creator) acc[creator.id] = creator.name;
      return acc;
    }, {});

    return { roomsData, creators };
  } catch (error) {
    console.error('Error fetching rooms:', error);
    return { roomsData: [], creators: {} };
  }
};

// Function to fetch room details by roomId
export const fetchRoomDetails = async (roomId) => {
  try {
    const roomRef = doc(fireDB, 'rooms', roomId);
    const roomSnapshot = await getDoc(roomRef);

    if (roomSnapshot.exists()) {
      const room = { id: roomSnapshot.id, ...roomSnapshot.data() };
      const messages = room.messages || [];
      return { room, messages };
    }
  } catch (error) {
    console.error('Error fetching room details:', error);
  }
  return null;
};

// Function to create a new room
export const createRoom = async (topic, user) => {
  try {
    const newRoom = {
      topic,
      creatorId: user?.userId || null,
      createdAt: new Date(),
      messages: [],
    };

    const roomRef = await addDoc(collection(fireDB, 'rooms'), newRoom);
    return { id: roomRef.id, ...newRoom };
  } catch (error) {
    console.error('Error creating room:', error);
    return null;
  }
};

// Function to send a message to a room
export const sendMessageToRoom = async (roomId, message) => {
  try {
    const roomRef = doc(fireDB, 'rooms', roomId);
    const roomSnapshot = await getDoc(roomRef);

    if (roomSnapshot.exists()) {
      const roomData = roomSnapshot.data();
      const updatedMessages = [...(roomData.messages || []), message];
      await updateDoc(roomRef, { messages: updatedMessages });
      return updatedMessages;
    }
  } catch (error) {
    console.error('Error sending message:', error);
  }
  return null;
};

// Function to edit a message in a room
export const editMessage = async (roomId, messageId, newMessage) => {
  try {
    const roomRef = doc(fireDB, 'rooms', roomId);
    const roomSnapshot = await getDoc(roomRef);

    if (roomSnapshot.exists()) {
      const roomData = roomSnapshot.data();
      const updatedMessages = roomData.messages.map((message) => 
        message.id === messageId ? { ...message, text: newMessage } : message
      );
      await updateDoc(roomRef, { messages: updatedMessages });
      return updatedMessages;
    }
  } catch (error) {
    console.error('Error editing message:', error);
  }
  return null;
};

// Function to delete a message from a room
export const deleteMessage = async (roomId, messageId) => {
  try {
    const roomRef = doc(fireDB, 'rooms', roomId);
    const roomSnapshot = await getDoc(roomRef);

    if (roomSnapshot.exists()) {
      const roomData = roomSnapshot.data();
      const updatedMessages = roomData.messages.filter((message) => message.id !== messageId);
      await updateDoc(roomRef, { messages: updatedMessages });
      return updatedMessages;
    }
  } catch (error) {
    console.error('Error deleting message:', error);
  }
  return null;
};

// Function to pin a message in a room
export const pinMessage = async (roomId, messageId) => {
  try {
    const roomRef = doc(fireDB, 'rooms', roomId);
    const roomSnapshot = await getDoc(roomRef);

    if (roomSnapshot.exists()) {
      const roomData = roomSnapshot.data();
      const updatedMessages = roomData.messages.map((message) =>
        message.id === messageId ? { ...message, pinned: true } : message
      );
      await updateDoc(roomRef, { messages: updatedMessages });
      return updatedMessages;
    }
  } catch (error) {
    console.error('Error pinning message:', error);
  }
  return null;
};


// Function to add a reaction to a message
export const addReactionToMessage = async (roomId, messageId, emoji) => {
  try {
    const roomRef = doc(fireDB, 'rooms', roomId);
    const roomSnapshot = await getDoc(roomRef);

    if (roomSnapshot.exists()) {
      const roomData = roomSnapshot.data();
      const updatedMessages = roomData.messages.map((message) =>
        message.id === messageId
          ? { ...message, reactions: [...(message.reactions || []), emoji] }
          : message
      );
      await updateDoc(roomRef, { messages: updatedMessages });
      return updatedMessages;
    }
  } catch (error) {
    console.error('Error adding reaction to message:', error);
  }
  return null;
};


// Function to delete a room
export const deleteRoom = async (roomId) => {
  try {
    const roomRef = doc(fireDB, 'rooms', roomId);
    await deleteDoc(roomRef);  // This function removes the entire room document
    return true;  // Room deleted successfully
  } catch (error) {
    console.error('Error deleting room:', error);
  }
  return false;
};
