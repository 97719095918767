import React, { useState, useEffect } from "react";
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { fireDB } from "../config/firebase";
import { useAuth } from "../hooks/useAuth";
import Spinner from "../spinner/Spinner";
import './notificationStyle/notifications.css';

const Notifications = ({ navigate }) => {
  const { userId, userNames, loading: authLoading, error: authError } = useAuth(navigate);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!userId) return;

    setLoading(true);
    const notificationsRef = collection(fireDB, "users", userId, "notifications");
    const notificationsQuery = query(notificationsRef, orderBy("timestamp", "desc"));

    const unsubscribe = onSnapshot(
      notificationsQuery,
      (snapshot) => {
        const fetchedNotifications = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setNotifications(fetchedNotifications);
        setLoading(false);
      },
      (err) => {
        console.error("Error fetching notifications:", err);
        setError("Failed to fetch notifications.");
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  if (authLoading) return <p><Spinner/></p>;
  if (authError) return <p>{authError}</p>;

  return (
    <div className="notifications">
      <h2>Notifications</h2>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : (
        <ul>
          {notifications.length > 0 ? (
            notifications.map((notification) => (
              <li key={notification.id} className={notification.read ? "" : "unread"}>
                {notification.type === "follow" && (
                  <p>
                    User <strong>{userNames[notification.senderId] || "Unknown"}</strong> followed you.
                  </p>
                )}
                {notification.type === "message" && (
                  <p>
                    <strong>{userNames[notification.senderId] || "Unknown"}</strong> sent you a message.
                  </p>
                )}
                <span>{new Date(notification.timestamp.toDate()).toLocaleString()}</span>
              </li>
            ))
          ) : (
            <p>No notification yet</p>
          )}
        </ul>
      )}
    </div>
  );
};

export default Notifications;
