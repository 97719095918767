import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  startAfter,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";
import { toast } from "react-toastify";

const PAGE_SIZE = 3;

const PollFetch = () => {
  const [pollQuestions, setPollQuestions] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [hasPolled, setHasPolled] = useState({});

  // Firebase ML Model Function
  const callMLModel = async (pollText) => {
    const functions = getFunctions();
    const predictPollPopularity = httpsCallable(functions, "predictPollPopularity");

    try {
      const result = await predictPollPopularity({ text: pollText });
      return result.data;
    } catch (error) {
      console.error("Error calling ML model:", error);
      return null;
    }
  };

  const fetchPollQuestions = async () => {
    try {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

      const q = lastVisible
        ? query(
            collection(fireDB, "pollQuestions"),
            where("createdAt", ">=", sevenDaysAgo),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(PAGE_SIZE)
          )
        : query(
            collection(fireDB, "pollQuestions"),
            where("createdAt", ">=", sevenDaysAgo),
            orderBy("createdAt", "desc"),
            limit(PAGE_SIZE)
          );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const pollQuestions = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();
            const pollText = data.poll;
            const popularityPrediction = await callMLModel(pollText);
            const pollsArray = Object.values(data.polls || {});

            return {
              id: doc.id,
              userId: data.userId || "Unknown User",
              createdAt: data.createdAt,
              poll: data.poll,
              image: data.image || "",
              link: data.link || "",  // New field included for poll links
              tags: data.tags || [],  // New field included for poll tags
              option_1_Count: data.Option_1 || 0,
              option_2_Count: data.Option_2 || 0,
              likes: data.likes || 0,
              retweets: data.retweets || [],
              comments: data.comments || [],
              polls: pollsArray,
              options: data.options || ["Option_1", "Option_2"],
              popularityPrediction,
              rankingScore: (pollsArray[0] || 0) - (pollsArray[1] || 0),
            };
          })
        );

        const uniqueUserIds = [
          ...new Set(pollQuestions.map((p) => p.userId).filter((id) => id)),
        ];

        const userDocs = await Promise.all(
          uniqueUserIds.map((id) =>
            getDoc(doc(fireDB, "users", id)).then((userDoc) =>
              userDoc.exists() ? { id, ...userDoc.data() } : null
            )
          )
        );

        const userMap = Object.fromEntries(
          userDocs.filter(Boolean).map((user) => [user.id, user])
        );

        const newPolls = pollQuestions.map((poll) => ({
          ...poll,
          user: userMap[poll.userId] || {
            name: "Unknown User",
            profileImage: "",
          },
        }));

        newPolls.sort((a, b) => {
          if (b.rankingScore === a.rankingScore) {
            return b.createdAt.seconds - a.createdAt.seconds;
          }
          return b.rankingScore - a.rankingScore;
        });

        setPollQuestions((prev) => {
          const existingIds = new Set(prev.map((p) => p.id));
          const uniquePolls = newPolls.filter((p) => !existingIds.has(p.id));
          return [...prev, ...uniquePolls];
        });

        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching poll questions:", error);
      toast.error("Error fetching poll questions.");
    }
  };

  useEffect(() => {
    fetchPollQuestions();
  }, []);

  return {
    pollQuestions,
    hasMore,
    fetchPollQuestions,
    hasPolled,
    setHasPolled,
  };
};

export default PollFetch;
