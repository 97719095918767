/* import React, { useState, useEffect } from "react";
import { useAuth } from "../../hooks/useAuth"; // Adjust path as needed
import { fetchRooms, fetchRoomDetails, createRoom, sendMessageToRoom } from "./FirebaseUtils";
import "./discussionrooms.css";
import { useNavigate } from "react-router-dom";

const DiscussionRooms = () => {
  const navigate = useNavigate();
  const { user, userId, loading, userNames, error } = useAuth(navigate);

  const [rooms, setRooms] = useState([]);
  const [newRoomTopic, setNewRoomTopic] = useState("");
  const [currentRoom, setCurrentRoom] = useState(null);
  const [messages, setMessages] = useState("");
  const [roomMessages, setRoomMessages] = useState([]);

  // Fetch rooms on mount
  useEffect(() => {
    const initialize = async () => {
      const { roomsData } = await fetchRooms();
      setRooms(roomsData);
    };
    initialize();
  }, []);

  const handleCreateRoom = async (e) => {
    e.preventDefault();
    if (!newRoomTopic.trim() || !userId) return;

    const newRoom = await createRoom(newRoomTopic, { userId, name: user.displayName });
    if (newRoom) {
      setRooms((prevRooms) => [...prevRooms, newRoom]);
      setNewRoomTopic("");
    }
  };

  const handleJoinRoom = async (roomId) => {
    const roomDetails = await fetchRoomDetails(roomId);
    if (roomDetails) {
      setCurrentRoom(roomDetails.room);
      setRoomMessages(roomDetails.messages);
    }
  };

  const handleExitRoom = () => {
    setCurrentRoom(null);
    setRoomMessages([]);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!messages.trim() || !currentRoom || !userId) return;

    const newMessage = {
      text: messages.trim(),
      userId,
      sender: user.displayName || "Anonymous",
      time: new Date(),
    };

    const updatedMessages = await sendMessageToRoom(currentRoom.id, newMessage);
    if (updatedMessages) {
      setRoomMessages(updatedMessages);
      setMessages("");
    }
  };

  return (
    <div className="discussion-container">
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <>
          <h2>Discussion rooms</h2>

          <form onSubmit={handleCreateRoom}>
            <input
              type="text"
              value={newRoomTopic}
              onChange={(e) => setNewRoomTopic(e.target.value)}
              placeholder="Enter room topic"
              className="input-field"
            />
            <button type="submit" className="create-room-btn">
              Create Room
            </button>
          </form>

          <h3>Available Rooms</h3>
          <ul className="rooms-list">
            {rooms.map((room) => (
              <li key={room.id} onClick={() => handleJoinRoom(room.id)}>
                {room.topic} - Created by {userNames[room.creatorId] || "Unknown"}
              </li>
            ))}
          </ul>

          {currentRoom && (
            <div className="chat-room">
              <h3>{currentRoom.topic}</h3>
              <button onClick={handleExitRoom} className="exit-room-btn">
                Exit Room
              </button>
              <form onSubmit={handleSendMessage}>
                <input
                  type="text"
                  value={messages}
                  onChange={(e) => setMessages(e.target.value)}
                  placeholder="Type a message"
                  className="input-field"
                />
                <button type="submit" className="send-message-btn">
                  Send
                </button>
              </form>
              <div className="messages-list">
                <h4>Messages:</h4>
                <ul>
                  {roomMessages.map((msg, index) => (
                    <li key={index}>
                      <strong>{userNames[msg.userId] || "Unknown"}:</strong> {msg.text}
                      <span className="timestamp">
                        {msg.time.toDate
                          ? msg.time.toDate().toLocaleString()
                          : new Date(msg.time).toLocaleString()}
                      </span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default DiscussionRooms;    */








import React, { useState } from "react";
import RoomsList from "./RoomList";
import ChatRoom from "./ChatRoom";
//import "./discussionrooms.css";

const DiscussionRooms = () => {
  const [currentRoomId, setCurrentRoomId] = useState(null);

  return (
    <div className="discussion-container">
      {!currentRoomId ? (
        <RoomsList onJoinRoom={setCurrentRoomId} />
      ) : (
        <ChatRoom roomId={currentRoomId} onExitRoom={() => setCurrentRoomId(null)} />
      )}
    </div>
  );
};

export default DiscussionRooms;  

