import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../hooks/useAuth"; // Importing the custom useAuth hook
import ProfileIcon from "../components/profile/ProfileIcon";
import { FaTh, FaPen, FaBell } from "react-icons/fa"; // Icons for dashboard and post
import "./navbar.css";

const Navbar = () => {
  const navigate = useNavigate();
  const { user, userId, loading, userNames, error } = useAuth(navigate);

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <div className="logo" onClick={() => navigate("/")}>
          Xnetous
        </div>

        <div className="nav-icons">
          {loading ? (
            <button className="loading-btn" disabled>
              Loading...
            </button>
          ) : user ? (
            <div className="profile-nav">
              <span onClick={() => navigate("/post")} className="post-facts-link">
                <FaPen />
              </span>

              <span onClick={() => navigate("/notifications")} className="notifications-link">
                <FaBell />
              </span>

              <span onClick={() => navigate("/dashboard")} className="dashboard-link">
                <FaTh />
              </span>

              <span
                onClick={() => {
                  const username =
                    userNames[userId]?.replace(/\s+/g, "").toLowerCase() || "user";
                  navigate(`/profile/${username}/${userId}`);
                }}
              >
                <ProfileIcon
                  name={userNames[userId] || "User"} // Display the current user's name
                  profileImage={user.photoURL || ""} // Use the user's photoURL if available
                />
              </span>
            </div>
          ) : null}
        </div>
      </nav>

      {error && <div className="error-message">{error}</div>} {/* Display any errors */}
    </div>
  );
};

export default Navbar;
