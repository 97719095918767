/*import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithRedirect, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, serverTimestamp, doc, addDoc, getDoc, updateDoc, getDocs, collection, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getStorage } from 'firebase/storage'; // Fixed import for Firebase Storage
import { getFunctions, httpsCallable } from 'firebase/functions';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBfKjjvXunhuWGftAyYbqvfjxowT3tMyBo",
  authDomain: "xnetous-6bf3f.firebaseapp.com",
  projectId: "xnetous-6bf3f",
  storageBucket: "xnetous-6bf3f.firebasestorage.app",
  messagingSenderId: "303582552229",
  appId: "1:303582552229:web:9bdf24af5e8cb78cd86d4c",
  measurementId: "G-QTWX3RPJKL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase services
const auth = getAuth(app);

const provider = new GoogleAuthProvider();
const fireDB = getFirestore(app);
const storage = getStorage(app); // Initialize Firebase Storage
// Initialize Firebase Functions
const functions = getFunctions(app);

export { auth, serverTimestamp, provider, fireDB, storage, doc, addDoc, getDoc, updateDoc, getDocs, collection, arrayUnion, arrayRemove, signInWithRedirect, getRedirectResult, onAuthStateChanged };

 
export { functions, httpsCallable };*/






import { initializeApp } from 'firebase/app';
import { getAuth, FacebookAuthProvider, GoogleAuthProvider, signInWithRedirect, getRedirectResult, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, serverTimestamp, doc, addDoc, getDoc, updateDoc, getDocs, collection, arrayUnion, arrayRemove } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';



// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBfKjjvXunhuWGftAyYbqvfjxowT3tMyBo",
  authDomain: "xnetous-6bf3f.firebaseapp.com",
  projectId: "xnetous-6bf3f",
  storageBucket: "xnetous-6bf3f.firebasestorage.app",
  messagingSenderId: "303582552229",
  appId: "1:303582552229:web:9bdf24af5e8cb78cd86d4c",
  measurementId: "G-QTWX3RPJKL"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Facebook Auth Provider
const facebookProvider = new FacebookAuthProvider();
const googleProvider = new GoogleAuthProvider();

// Initialize Firebase services
const auth = getAuth(app);
const fireDB = getFirestore(app);
const storage = getStorage(app); 
const functions = getFunctions(app);

export { auth, facebookProvider, googleProvider, fireDB, storage, doc, addDoc, getDoc, updateDoc, getDocs, collection, arrayUnion, arrayRemove, signInWithRedirect, getRedirectResult, onAuthStateChanged, serverTimestamp, functions, httpsCallable };








