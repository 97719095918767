import React, { useState, useEffect } from "react";
import { useAuth } from "../hooks/useAuth"; // Import the useAuth hook
import { collection, getDocs } from "firebase/firestore"; // Firebase Firestore queries
import { fireDB } from "../config/firebase"; // Firebase config
import * as use from "@tensorflow-models/universal-sentence-encoder"; // TensorFlow's Universal Sentence Encoder
import * as tf from "@tensorflow/tfjs"; // TensorFlow.js
import "./adStyle/adDisplay.css"; // Create this file for styling

const AdsDisplay = () => {
  const { user, loading: authLoading } = useAuth(); // Get user and loading from useAuth hook
  const [ads, setAds] = useState([]); // Initialize ads as an empty array
  const [loadingAds, setLoadingAds] = useState(true);
  const [model, setModel] = useState(null); // To hold the NLP model

  // Load the Universal Sentence Encoder model
  useEffect(() => {
    const loadModel = async () => {
      const loadedModel = await use.load();
      setModel(loadedModel);
    };
    loadModel();
  }, []);

  // Function to calculate similarity score
  const getSimilarity = (text1, text2) => {
    if (!text1 || !text2) return 0; // Prevent calculation if either text is missing
    const embeddings1 = model.embed(text1);
    const embeddings2 = model.embed(text2);
    return embeddings1.dot(embeddings2).arraySync();
  };

  useEffect(() => {
    const fetchAds = async () => {
      if (!user || !model) return; // Wait for user and model to load

      try {
        setLoadingAds(true);
        const adsRef = collection(fireDB, "ads");
        const querySnapshot = await getDocs(adsRef);
        const fetchedAds = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Ensure ads is always an array
        if (!Array.isArray(fetchedAds)) {
          console.error("Fetched ads is not an array:", fetchedAds);
          return;
        }

        // Filter ads based on NLP similarity with user role
        const filteredAds = fetchedAds.filter((ad) => {
          // Ensure the ad has a description and category before comparing
          if (ad.description && ad.category) {
            const similarity = getSimilarity(user.role, ad.description);
            return similarity > 0.5; // Show ads with a similarity score greater than 0.5
          }
          return false;
        });

        setAds(filteredAds);
      } catch (error) {
        console.error("Error fetching ads:", error);
      } finally {
        setLoadingAds(false);
      }
    };

    fetchAds();
  }, [user, model]); // Trigger when user or model is available

  if (authLoading || loadingAds) {
    return <p>Loading ads...</p>;
  }

  return (
    <div className="ads-display-container">
      {Array.isArray(ads) && ads.length > 0 ? ( // Check if ads is an array and has content
        ads.map((ad) => (
          <div key={ad.id} className="ad-card">
            <h3>{ad.title}</h3>
            {ad.mediaUrl && <img src={ad.mediaUrl} alt={ad.title} className="ad-media" />}
            <p>{ad.description}</p>
            <p><strong>Posted by:</strong> {ad.username || user?.displayName || "Anonymous"}</p>
          </div>
        ))
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default AdsDisplay;
