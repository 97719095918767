import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
//import "../../componentsStyle/searchResult.css";
import RecommendationPost from "../../components/recommendation/RecommendationPost";

const SearchResults = () => {
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // Extract query parameters from the URL
  const getQueryParams = () => {
    const params = new URLSearchParams(location.search);
    return {
      category: params.get("category") || "",   // Updated to use 'category' instead of 'q'
      type: params.get("type") || "",           // New type param for filtering by type (vote, quiz, etc.)
      id: params.get("id") || "",               // New id param for fetching a specific question
    };
  };

  const { category, type, id } = getQueryParams();

  // Fetch questions from Firestore
  const fetchQuestions = async () => {
    setLoading(true);
    try {
      // Fetch quiz and vote questions
      const collections = [
        { name: "factQuestions", type: "fact" },
        { name: "pollQuestions", type: "poll" },
        { name: "quizQuestions", type: "quiz" },
        { name: "voteQuestions", type: "vote" },
      ];

      let allQuestions = [];
      for (const { name, type } of collections) {
        const snapshot = await getDocs(collection(fireDB, name));
        const questions = snapshot.docs.map((doc) => ({
          id: doc.id,
          type,
          ...doc.data(),
        }));
        allQuestions = [...allQuestions, ...questions];
      }

      // Filter questions by category, type, and/or question ID
      let filteredQuestions = allQuestions;
      if (category) {
        filteredQuestions = filteredQuestions.filter((q) => q.category === category);
      }
      if (type) {
        filteredQuestions = filteredQuestions.filter((q) => q.type === type);
      }
      if (id) {
        filteredQuestions = filteredQuestions.filter((q) => q.id === id);
      }

      setQuestions(filteredQuestions);
    } catch (error) {
      console.error("Error fetching questions:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, [category, type, id]);  // Trigger effect when category, type, or id changes

  // Navigate to the appropriate detail page based on question type and ID
  const handleQuestionClick = (id, type) => {
    if (type === "vote") {
      navigate(`/voteDetail/${id}`);  // Navigate to the vote detail page
    } else if (type === "poll") {
      navigate(`/pollDetail/${id}`);
    }
    else if (type === "fact") {
      navigate(`/factDetail/${id}`);
    }
    else if (type === "quiz") {
      navigate(`/quizDetail/${id}`);  // Navigate to the quiz detail page
    } else {
      navigate(`/questionDetail/${id}`);  // Navigate to the general question detail page
    }
  };

  return (
    <div className="container">
      {loading ? (
        <div className="loader">Loading...</div>
      ) : (
        <div>
          {questions.length > 0 ? (
            questions.map((question) => (
              <div
                key={question.id}
                className="question-card"
                onClick={() => handleQuestionClick(question.id, question.type)}
              >
                <h4>{question.question || "Untitled Question"}</h4>
                <p>
                  <strong>Category:</strong> {question.category || "General"}
                </p>
                <p>
                  <strong>Created At:</strong>{" "}
                  {question.createdAt
                    ? new Date(question.createdAt.seconds * 1000).toLocaleString()
                    : "N/A"}
                </p>
                {question.src && <p><strong>Source:</strong> {question.src}</p>}
                {question.vertical && <p><strong>Vertical:</strong> {question.vertical}</p>}
              </div>
            ))
          ) : (
            <p>No questions found matching your search criteria.</p>
          )}
        </div>
      )}

      <div className="recommendation-container">
        <RecommendationPost />
      </div>
    </div>
  );
};

export default SearchResults;
