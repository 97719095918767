import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  limit,
  startAfter,
} from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { timeAgo } from "../../../utils/Time";
import { useAuth } from "../../../hooks/useAuth";
import Spinner from "../../../spinner/Spinner";
import InfiniteScroll from "react-infinite-scroll-component"; // Import InfiniteScroll
import "./performance.css";

// Ranking Calculation (Net Score: Agree - Disagree)
const calculateRankScore = (agree, disagree, createdAt) => {
  const netScore = agree - disagree;
  const hoursSinceCreation =
    (Date.now() - createdAt.toMillis()) / (1000 * 60 * 60);
  return netScore / Math.pow(hoursSinceCreation + 2, 1.8); // Decay factor for recency
};

const MostLiked = () => {
  const { user, userNames, loading: userLoading } = useAuth();
  const [loading, setLoading] = useState(true);
  const [trendingData, setTrendingData] = useState([]);
  const [error, setError] = useState(null);
  const [lastVisible, setLastVisible] = useState(null);
  const [totalPostsCount, setTotalPostsCount] = useState(0);
  const [hasMore, setHasMore] = useState(true); // To track if there are more posts to load
  const navigate = useNavigate();

  const fetchMostLiked = async () => {
    setLoading(true);
    setError(null);
    try {
      const limitPerPage = 1; // Adjusted to show more posts per page
      let combinedData = [];
      let lastVisibleDocs = [];
      let totalCount = 0;

      const collections = [
        { name: "factQuestions", type: "Fact" },
        { name: "pollQuestions", type: "Poll" },
        { name: "voteQuestions", type: "Vote" },
        { name: "quizQuestions", type: "Quiz" },
      ];

      for (const collectionData of collections) {
        let queryRef = query(
          collection(fireDB, collectionData.name),
          orderBy("createdAt", "desc"),
          limit(limitPerPage)
        );

        if (lastVisible) {
          queryRef = query(
            collection(fireDB, collectionData.name),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(limitPerPage)
          );
        }

        const snapshot = await getDocs(queryRef);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          type: collectionData.type,
          likes: doc.data().likes || 0,
          dislikes: doc.data().dislikes || 0, // Ensure there's a 'dislikes' field
        }));

        // Filter out posts with 0 or 1 likes
        const filteredData = data.filter((item) => item.likes > 1);

        // Apply the ranking function to each post based on likes and dislikes
        combinedData = [
          ...combinedData,
          ...filteredData.map((item) => ({
            ...item,
            rankScore: calculateRankScore(
              item.likes,
              item.dislikes,
              item.createdAt
            ),
          })),
        ];

        totalCount += snapshot.size;

        if (!snapshot.empty) {
          lastVisibleDocs.push(snapshot.docs[snapshot.docs.length - 1]);
        }
      }

      setTotalPostsCount(totalCount);
      setLastVisible(
        lastVisibleDocs.length > 0
          ? lastVisibleDocs[lastVisibleDocs.length - 1]
          : null
      );

      // If there are no more posts to load, set hasMore to false
      if (combinedData.length < limitPerPage) {
        setHasMore(false);
      }

      // Fetch user details for each post
      const postsWithUserDetails = await Promise.all(
        combinedData.map(async (item) => {
          const userSnapshot = await getDocs(
            query(
              collection(fireDB, "users"),
              where("userId", "==", item.userId)
            )
          );
          const userData = userSnapshot.empty
            ? { name: "Unknown User", profileImage: "" }
            : userSnapshot.docs[0].data();

          return {
            ...item,
            user: {
              name: userData.name || "Unknown User",
              profileImage: userData.profileImage || "",
            },
          };
        })
      );

      setTrendingData((prevData) => [...prevData, ...postsWithUserDetails]);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(`Failed to load data. Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMostLiked();
  }, []);

  const handleNavigate = (id, type) => {
    navigate(`/${type.toLowerCase()}/${id}`);
  };

  if (loading || userLoading) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="performance-page">
      {error ? (
        <div className="error-message">{error}</div>
      ) : (
        <div>
          <div className="total-posts-count">
            <h3>Total Posts: {totalPostsCount > 0 ? totalPostsCount : "0"}</h3>
          </div>

          <InfiniteScroll
            dataLength={trendingData.length} // Length of the data to indicate if more data can be loaded
            next={fetchMostLiked} // Function to fetch more data
            hasMore={hasMore} // Whether there are more posts to load
            loader={<Spinner />} // Loader to show while data is being fetched
            endMessage={<p className="no-more-posts">No more posts to show.</p>} // Message when no more posts are available
          >
            <div className="trending-list">
              {trendingData.length > 0 ? (
                trendingData.map((item) => (
                  <div
                    key={item.id}
                    className="trending-card"
                    onClick={() => handleNavigate(item.id, item.type)}
                  >
                    <div className="card-header">
                      <ProfileIcon
                        name={item.user.name}
                        profileImage={item.user.profileImage || ""}
                        className="profile-icon"
                      />
                      <span className="user-name">{item.user.name}</span>
                      <span className="created-at">
                        {timeAgo(new Date(item.createdAt.seconds * 1000))}
                      </span>
                    </div>
                    <div className="card-body">
                      <p
                        className="post-title"
                        style={{
                          fontSize: "15px",
                          color: "#14171a",
                        }}
                      >
                        {item.fact ||
                          item.poll ||
                          item.vote ||
                          item.quiz ||
                          "No Title"}
                      </p>
                    </div>
                    <div className="card-footer">
                      <div className="popularity">
                        <strong>Likes:</strong> {item.likes}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-data-message">No liked posts found.</p>
              )}
            </div>
          </InfiniteScroll>
        </div>
      )}
    </div>
  );
};

export default MostLiked;
