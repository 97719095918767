import React, { useState, useEffect } from "react";
import Spinner from "../../../spinner/Spinner";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { useAuth } from "../../../hooks/useAuth";
import { timeAgo } from "../../../utils/Time";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import PollChart from "./PollChart";
import PollLike from "../../../components/like/PollLike";
import PollRetweet from "../../../components/retweet/PollRetweet";
import PollComment from "../../../components/comment/PollComment";
import PollShare from "../../../components/share/PollShare";
import "../detailPage.css";
import PostRecommendation from "../../../components/recommendation/PostRecommendation";

const PollDetail = () => {
  const { id } = useParams();
  const { user, loading: userLoading } = useAuth();
  const [pollDetail, setPollDetail] = useState(null);
  const [hasPolled, setHasPolled] = useState(null);
  const [shareDropdownOpen, setShareDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const fetchPollDetail = async () => {
    try {
      const docRef = doc(fireDB, "pollQuestions", id);
      const docSnapshot = await getDoc(docRef);

      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        
        console.log("Poll Detail Data:", data); // ✅ Debugging log

        const userDoc =
          data.userId && (await getDoc(doc(fireDB, "users", data.userId)));

        const user = userDoc?.exists()
          ? { id: data.userId, ...userDoc.data() }
          : { name: "Unknown User", profileImage: "" };

        const poll = {
          id: docSnapshot.id,
          poll: data.poll,
          createdAt: data.createdAt,
          user,
          options: data.options || [],
          polls: Object.values(data.polls || {}),
          totalPolls: Object.values(data.polls || {}).reduce(
            (acc, count) => acc + count,
            0
          ),
          likes: data.likes || 0,
          retweets: data.retweets || [],
          comments: data.comments || [],
          link: data.link || "" // ✅ Ensure link is included
        };

        setPollDetail(poll);
      } else {
        toast.error("Poll not found.");
      }
    } catch (error) {
      console.error("Error fetching poll:", error);
      toast.error("Failed to fetch the poll. Please try again.");
    }
  };

  const handlePollVote = async (option) => {
    if (!user) {
      toast.error("You must be logged in to participate.");
      return;
    }

    if (hasPolled) {
      toast.error("You have already voted in this poll.");
      return;
    }

    try {
      const pollRef = doc(fireDB, "pollQuestions", id);
      await updateDoc(pollRef, {
        [`polls.${pollDetail.options.indexOf(option)}`]: (
          pollDetail.polls[pollDetail.options.indexOf(option)] || 0
        ) + 1,
      });

      setHasPolled(option);
      toast.success(`You voted for: ${option}`);
    } catch (error) {
      toast.error("Failed to submit your vote. Please try again.");
    }
  };

  useEffect(() => {
    fetchPollDetail();
  }, [id]);

  const renderPollingSection = () => (
    <div className="options-container">
      {pollDetail.options.map((option, index) => (
        <button
          key={index}
          className="option-button"
          onClick={() => handlePollVote(option)}
          disabled={hasPolled}
        >
          {option}
        </button>
      ))}
    </div>
  );

  const renderEngagementSection = () => (
    <div className="home-post-footer-icons">
      <PollLike pollId={pollDetail.id} initialLikes={pollDetail.likes} />
      <PollRetweet pollId={pollDetail.id} initialRetweets={pollDetail.retweets} />
      <PollComment poll={pollDetail} isAuthenticated={!!user} />
      <PollShare
        pollId={pollDetail.id}
        poll={pollDetail}
        toggleShareDropdown={() => setShareDropdownOpen(!shareDropdownOpen)}
        shareDropdownOpen={shareDropdownOpen}
      />
    </div>
  );

  const renderChartSection = () =>
    hasPolled && (
      <PollChart
        polls={pollDetail.polls}
        options={pollDetail.options}
        totalPolls={pollDetail.totalPolls}
      />
    );

  if (userLoading) return <Spinner />;
  if (!pollDetail) return <p>Loading poll...</p>;

  return (
 
    <div className="detail-home-post-card">
    <div className="home-post-header">
      <div
        className="user-avatar"
        onClick={() => {
          pollDetail?.user?.id &&
            navigate(
              `/profile/${pollDetail.user.name
                ?.replace(/\s+/g, "")
                .toLowerCase()}/${pollDetail.user.id}`
            );
        }}
      >
        <ProfileIcon
          name={pollDetail?.user?.name}
          profileImage={pollDetail?.user?.profileImage}
        />
      </div>
  
      <div className="user-details">
        <span className="user-name">{pollDetail?.user?.name}</span>
        <span className="post-timestamp">
          {pollDetail?.createdAt?.seconds
            ? timeAgo(new Date(pollDetail.createdAt.seconds * 1000))
            : "Unknown"}
        </span>
      </div>
    </div>
  
    {/* ✅ Display Related Link */}
    {pollDetail?.link && pollDetail?.link.trim() !== "" && (
      <div className="home-post-link">
        <a href={pollDetail.link} target="_blank" rel="noopener noreferrer">
          🔗 View Related Link
        </a>
      </div>
    )}
  
    <p className="detail-home-post-content">{pollDetail?.poll}</p>
  
    {renderPollingSection && renderPollingSection()}
    {renderEngagementSection && renderEngagementSection()}
    {renderChartSection && renderChartSection()}
  
    <PostRecommendation />
  </div>
  
          
  );
};

export default PollDetail;
