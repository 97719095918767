import { collection, getDocs, doc, query, orderBy, limit, startAfter, getDoc, Timestamp } from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions";

const PAGE_SIZE = 3;

const callMLModel = async (quizText) => {
  const functions = getFunctions();
  const predictQuizDifficulty = httpsCallable(functions, "predictQuizDifficulty");

  try {
    const result = await predictQuizDifficulty({ text: quizText });
    return result.data;
  } catch (error) {
    console.error("Error calling ML model:", error);
    return null;
  }
};

const fetchQuizQuestions = async (lastVisible, setLastVisible, setHasMore, setQuizList) => {
  try {
    const quizQuery = lastVisible
      ? query(
          collection(fireDB, "quizQuestions"),
          orderBy("createdAt", "desc"),
          startAfter(lastVisible),
          limit(PAGE_SIZE)
        )
      : query(
          collection(fireDB, "quizQuestions"),
          orderBy("createdAt", "desc"),
          limit(PAGE_SIZE)
        );

    const querySnapshot = await getDocs(quizQuery);

    if (querySnapshot.empty) {
      setHasMore(false);  // No more data to load
      return;
    }

    const quizData = await Promise.all(
      querySnapshot.docs.map(async (doc) => {
        const data = doc.data();
        const quizText = data.question;

        // Call ML model for quiz difficulty prediction
        const difficultyPrediction = await callMLModel(quizText);

        return {
          id: doc.id,
          ...data,
          createdAt: data.createdAt || Timestamp.fromDate(new Date()),
          likes: data.likes || 0,
          comments: data.comments || [],
          retweetedBy: data.retweetedBy || [],
          link: data.link || "", // Handle quiz link field
          tag: data.tag || [], // Handle quiz tagged users field
          selectedAnswer: null,
          feedback: "",
          correctAnswerShown: false,
          difficultyPrediction,
        };
      })
    );

    const userIds = [
      ...new Set(quizData.map((quiz) => quiz.userId).filter(Boolean)),
    ];

    const userProfiles = await Promise.all(
      userIds.map((id) =>
        getDoc(doc(fireDB, "users", id)).then((snapshot) =>
          snapshot.exists() ? { id, ...snapshot.data() } : null
        )
      )
    );

    const userMap = Object.fromEntries(
      userProfiles.filter(Boolean).map((user) => [user.id, user])
    );

    const quizzesWithUser = quizData.map((quiz) => ({
      ...quiz,
      user: userMap[quiz.userId] || {
        name: "Unknown User",
        profileImage: "",
      },
    }));

    // Avoid adding duplicates: check if quiz already exists in the state by ID
    setQuizList((prev) => {
      const updatedQuizList = [...prev];
      quizzesWithUser.forEach((quiz) => {
        if (!updatedQuizList.some((existingQuiz) => existingQuiz.id === quiz.id)) {
          updatedQuizList.push(quiz);
        }
      });
      return updatedQuizList;
    });

    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
  } catch (error) {
    console.error("Error fetching quizzes:", error);
  }
};

export default fetchQuizQuestions;
