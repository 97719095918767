import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { collection, doc, setDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { fireDB, storage } from "../../config/firebase";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

function PostVote({ user, voteToEdit }) {
  const [vote, setVote] = useState("");
  const [category, setCategory] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [link, setLink] = useState(""); // Link for the vote
  const [showPreview, setShowPreview] = useState(false); // Toggle to show preview

  useEffect(() => {
    if (user && voteToEdit) {
      setVote(voteToEdit.vote);
      setCategory(voteToEdit.category);
      setImagePreview(voteToEdit.image || null);
      setLink(voteToEdit.link || "");
    }
  }, [user, voteToEdit]);

  const handlePostVote = async (e) => {
    e.preventDefault();
    if (!vote || !category) {
      toast.error("Please enter a vote and category.");
      return;
    }

    setLoading(true);
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (!currentUser) {
        toast.error("User not authenticated. Please log in.");
        return;
      }

      const voteData = {
        vote,
        category,
        createdAt: new Date(),
        userId: currentUser.uid,
        options: ["Yes", "No"], // Default options
        link: link || null,
      };

      if (image) {
        const storageRef = ref(storage, `voteImages/${new Date().toISOString()}`);
        const uploadTask = uploadBytesResumable(storageRef, image);
        await uploadTask;
        const imageURL = await getDownloadURL(storageRef);
        voteData.image = imageURL;
      }

      const voteRef = voteToEdit
        ? doc(fireDB, "voteQuestions", voteToEdit.id) // If editing, update
        : doc(collection(fireDB, "voteQuestions")); // If new, create

      await setDoc(voteRef, voteData, { merge: !!voteToEdit });

      toast.success(voteToEdit ? "Vote updated successfully!" : "Vote posted successfully!");
      setVote("");
      setCategory("");
      setImage(null);
      setImagePreview(null);
      setLink(""); // Reset link after posting
    } catch (error) {
      toast.error("Failed to post vote.");
    } finally {
      setLoading(false);
    }
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    if (value.length > 50) {
      toast.error("Category cannot exceed 50 characters.");
      return;
    }
    setCategory(value); // Simply set the category as plain text
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith("image/")) {
      setImage(file);
      setImagePreview(URL.createObjectURL(file));
    } else {
      toast.error("Please select a valid image file.");
    }
  };

  return (
    <div className="container">
      <h3 className="post-header">{voteToEdit ? "Edit Vote" : "Create a New Vote"}</h3>
      <form onSubmit={handlePostVote} className="post-form">
        <div className="form-group">
          <label htmlFor="vote" className="form-label">Vote Question</label>
          <textarea
            id="vote"
            value={vote}
            onChange={(e) => setVote(e.target.value)}
            placeholder="Enter your vote question (max 220 characters)"
            maxLength={220}
            className="text-area"
          />
        </div>
        <div className="form-group">
          <label htmlFor="category" className="form-label">Category</label>
          <input
            type="text"
            id="category"
            value={category}
            onChange={handleCategoryChange}
            placeholder="Enter a category (e.g., politics)"
             className="input-field"
          />
        </div>
        <div className="form-group">
          <label htmlFor="link" className="form-label">Optional Link</label>
          <input
            type="url"
            id="link"
            value={link}
            onChange={(e) => setLink(e.target.value)}
            placeholder="Enter an optional link (e.g., https://example.com)"
             className="input-field"
          />
        </div>
        <div className="form-group">
          <label htmlFor="image" className="form-label">Upload Image (Optional)</label>
          <input
            type="file"
            id="image"
            accept="image/*"
            onChange={handleImageChange}
             className="input-field"
          />
        </div>
        {imagePreview && (
          <div style={{ marginTop: "10px" }}>
            <img
              src={imagePreview}
              alt="Uploaded Preview"
              style={{ width: "200px", borderRadius: "8px" }}
            />
            <button
              type="button"
              onClick={() => {
                setImage(null);
                setImagePreview(null);
              }}
              style={{ display: "block", marginTop: "10px", color: "red" }}
            >
              Remove Image
            </button>
          </div>
        )}
        <div className="form-group">
          <label className="form-label">
            <input
              type="checkbox"
              checked={showPreview}
              onChange={() => setShowPreview(!showPreview)}
            />
            Show Preview
          </label>
        </div>
        {showPreview && (
          <div className="preview">
            <h4>Preview:</h4>
            <p><strong>Vote Question:</strong> {vote}</p>
            <p><strong>Category:</strong> {category}</p>
            {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: "200px", borderRadius: "8px" }} />}
            {link && <p><strong>Link:</strong> <a href={link} target="_blank" rel="noopener noreferrer">{link}</a></p>}
          </div>
        )}
        <button type="submit" className="post-button" disabled={loading}>
          {loading ? "Posting..." : voteToEdit ? "Update Vote" : "Post Vote"}
        </button>
      </form>
    </div>
  );
}

export default PostVote;
