import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { auth, fireDB } from "../config/firebase";
import { useAuth } from "../hooks/useAuth"; // Importing the custom hook
import { signOut } from "firebase/auth";
import { toast } from "react-toastify";

const AccountDeactivate = () => {
  const { user, userId, loading } = useAuth(useNavigate()); // Using the custom hook
  const [isProcessing, setIsProcessing] = useState(false);
  const navigate = useNavigate();

  const handleDeactivate = async () => {
    if (!userId) {
      toast.error("No user is logged in or user data is missing.", { autoClose: 3000 });
      return;
    }

    const confirmDeactivate = window.confirm(
      "Are you sure you want to deactivate your account? This action can be reversed by contacting support."
    );

    if (!confirmDeactivate) return;

    setIsProcessing(true);

    try {
      // Update the user's status in Firestore
      const userRef = doc(fireDB, "users", userId);
      await updateDoc(userRef, {
        status: "deactivated",
        deactivatedAt: new Date(),
      });

      // Sign the user out
      await signOut(auth);

      // Navigate to login or home
      toast.success("Your account has been successfully deactivated.", { autoClose: 3000 });
      navigate("/login");
    } catch (error) {
      console.error("Error deactivating account:", error);
      toast.error("Failed to deactivate the account. Please try again later.", { autoClose: 3000 });
    } finally {
      setIsProcessing(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>; // Show a loading state while fetching user info
  }

  return (
    <div className="deactivate-container">
      <h2>Deactivate Account</h2>
      <p>
        Deactivating your account will restrict your access. You can reactivate it later by contacting support.
      </p>
      <button
        className="deactivate-button"
        onClick={handleDeactivate}
        disabled={isProcessing || !userId}
      >
        {isProcessing ? "Processing..." : "Deactivate Account"}
      </button>
    </div>
  );
};

export default AccountDeactivate;
