import React from "react";


const QuizPerformance = ({ performanceData }) => {
  if (!performanceData) return <p>No performance data available.</p>;

  const { totalAnswers, totalCorrect, totalIncorrect } = performanceData;

  if (totalAnswers === 0) return <p>No answers yet.</p>;

  const accuracy = ((totalCorrect / totalAnswers) * 100).toFixed(2);
  const incorrectPercentage = ((totalIncorrect / totalAnswers) * 100).toFixed(2);

  return (
    <div className="quiz-performance-container">
      <h2>Overall Statistics</h2>
      <p>
        <strong>Total Answers:</strong> {totalAnswers}
        <br />
        <strong>Correct Answers:</strong> {totalCorrect} ({accuracy}%)
        <br />
        <strong>Incorrect Answers:</strong> {totalIncorrect} ({incorrectPercentage}%)
      </p>

      <div className="progress-bars">
        <div className="progress-bar-container">
          <label>Correct Answers</label>
          <progress
            className="answer-progress-bar correct"
            value={totalCorrect}
            max={totalAnswers}
          />
        </div>
        <div className="progress-bar-container">
          <label>Incorrect Answers</label>
          <progress
            className="answer-progress-bar incorrect"
            value={totalIncorrect}
            max={totalAnswers}
          />
        </div>
      </div>

      <p className={`accuracy-text ${accuracy >= 75 ? "high-accuracy" : "low-accuracy"}`}>
        Accuracy: {accuracy}%
      </p>
    </div>
  );
};

export default QuizPerformance;
