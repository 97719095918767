import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { fireDB } from "../../../config/firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { useAuth } from "../../../hooks/useAuth";
import { timeAgo } from "../../../utils/Time";
import PollLike from "../../../components/like/PollLike";
import Spinner from "../../../spinner/Spinner";
import PollChart from "./PollChart"; // Your PollChart component
import PollRetweet from "../../../components/retweet/PollRetweet";
import PollComment from "../../../components/comment/PollComment";
import PollShare from "../../../components/share/PollShare";
import PollFetch from "./PollFetch";
import PollOption from "./PollOption";

const PollQuestions = () => {
  const { user, loading: userLoading } = useAuth();
  const {
    pollQuestions,
    hasMore,
    fetchPollQuestions,
    hasPolled,
    setHasPolled,
  } = PollFetch();
  const [shareDropdownOpen, setShareDropdownOpen] = useState({});
  const navigate = useNavigate();

  const handlePoll = async (pollId, option) => {
    if (!user) {
      toast.error("You must be logged in to participate.");
      return;
    }

    if (hasPolled[pollId]) {
      toast.error("You have already participated in this poll.");
      return;
    }

    setHasPolled((prev) => ({ ...prev, [pollId]: true }));

    try {
      const pollRef = doc(fireDB, "pollQuestions", pollId);
      const pollDoc = await getDoc(pollRef);
      const pollData = pollDoc.data();

      // Update poll data with the selected option
      const updatedPolls = { ...pollData.polls };
      const optionIndex = pollData.options.indexOf(option);
      if (optionIndex !== -1) {
        updatedPolls[optionIndex] = (updatedPolls[optionIndex] || 0) + 1;
      }

      await updateDoc(pollRef, {
        polls: updatedPolls,
      });

      toast.success("Your vote has been submitted.");
    } catch (error) {
      toast.error("Failed to submit your vote.");
    }
  };

  const toggleShareDropdown = (pollId) => {
    setShareDropdownOpen((prevState) => ({
      ...prevState,
      [pollId]: prevState[pollId] ? null : true,
    }));
  };

  return (
    <InfiniteScroll
      dataLength={pollQuestions.length}
      next={fetchPollQuestions}
      hasMore={hasMore}
      loader={<Spinner />}
      endMessage={<p>No more questions available.</p>}
    >
      <div>
        {pollQuestions.map((poll) => {
          const totalPolls = poll.polls.reduce((acc, count) => acc + count, 0);

          return (
            <div key={poll.id} className="home-post-card">
              <div className="home-post-header">
                <div
                  className="user-avatar"
                  onClick={() => {
                    poll.user.id &&
                      navigate(
                        `/profile/${poll.user.name
                          .replace(/\s+/g, "")
                          .toLowerCase()}/${poll.user.id}`
                      );
                  }}
                >
                  <ProfileIcon
                    name={poll.user.name}
                    profileImage={poll.user.profileImage}
                  />
                </div>
                <div className="user-details">
                  <span className="user-name">{poll.user.name}</span>
                  <span className="home-post-timestamp">
                    {timeAgo(new Date(poll.createdAt.seconds * 1000))}
                  </span>
                </div>

                <div className="home-post-options-menu">
                  <PollOption pollId={poll.id} userId="currentUserId" />
                </div>
              </div>

              <div>
                <p className="home-post-content">{poll.poll}</p>
              </div>

              {/* Poll Image */}
              {poll.image && (
                <div className="home-image-container">
                  <img
                    src={poll.image}
                    alt={poll.poll}
                    className="home-image"
                  />
                </div>
              )}

              {/* Poll Options */}
              <div className="home-post-actions">
                {hasPolled[poll.id] ? (
                  // After voting, show the percentage results
                  poll.options.map((option, index) => (
                    <div key={index} className="percentage-bar">
                      <span>{option}</span>:{" "}
                      {((poll.polls[index] / totalPolls) * 100).toFixed(2)}%
                    </div>
                  ))
                ) : (
                  // Before voting, show the voting options
                  poll.options.map((option, index) => (
                    <button
                      key={index}
                      className="action-button"
                      onClick={() => handlePoll(poll.id, option)}
                    >
                      {option}
                    </button>
                  ))
                )}
              </div>

              {/* Interactions */}
              <div className="home-post-footer-icons">
                <PollLike pollId={poll.id} initialLikes={poll.likes} />
                <PollRetweet pollId={poll.id} initialRetweets={poll.retweets} />
                <PollShare
                  poll={poll}
                  toggleShareDropdown={toggleShareDropdown}
                  shareDropdownOpen={shareDropdownOpen[poll.id]}
                />
                <PollComment poll={poll} isAuthenticated={true} />
              </div>

              {/* Poll Chart */}
              <div className="graph-feed">
                {hasPolled[poll.id] && (
                  <PollChart options={poll.options} polls={poll.polls} />
                )}
              </div>
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
};

export default PollQuestions;
