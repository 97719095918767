import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { fireDB } from "../config/firebase";
import { auth } from "../config/firebase";

const SaveButton = ({ questionId, questionData }) => {
  const [isSaved, setIsSaved] = useState(false);

  useEffect(() => {
    const checkIfSaved = async () => {
      const userId = auth.currentUser?.uid;
      if (!userId) return;

      try {
        const savedPostDoc = await getDoc(doc(fireDB, "savedPosts", userId));
        if (savedPostDoc.exists()) {
          const savedPosts = savedPostDoc.data();
          setIsSaved(savedPosts[questionId] ? true : false);
        }
      } catch (error) {
        console.error("Error checking save status:", error);
      }
    };

    checkIfSaved();
  }, [questionId]);

  const handleSave = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error("User not logged in.");
      return;
    }

    try {
      const userSavedPostsRef = doc(fireDB, "savedPosts", userId);

      await setDoc(
        userSavedPostsRef,
        {
          [questionId]: {
            question: questionData.question,
            createdAt: questionData.createdAt,
            userId: questionData.userId,
          },
        },
        { merge: true }
      );

      setIsSaved(true);
    } catch (error) {
      console.error("Error saving post:", error);
    }
  };

  return (
    <button onClick={handleSave} disabled={isSaved}>
      {isSaved ? "Saved" : "Save"}
    </button>
  );
};

export default SaveButton;
