import React from "react";
import questions from "./QuizData"; // Ensure QuizData is correctly structured
import "./premiumStyle/QuizAnswer.css";

function QuizAnswer(props) {
  const {
    Score,
    CorrectAnswer,
    answers = [], // Provide default value to avoid errors
    totalTimeTaken,
    handlePlayAgain,
    attempts = [],
  } = props;

  // Calculate accuracy
  const accuracy =
    answers.length > 0
      ? ((CorrectAnswer / answers.length) * 100).toFixed(2)
      : "0.00";

  // Identify performance by topic
  const topicPerformance = {};
  answers.forEach((ans, index) => {
    const question = questions[index] || {}; // Fallback to empty object if index is out of bounds
    const topic = question.topic || "Unknown"; // Fallback to 'Unknown' if topic is missing

    if (!topicPerformance[topic]) {
      topicPerformance[topic] = { correct: 0, total: 0 };
    }
    topicPerformance[topic].total += 1;

    if (ans?.isCorrect) {
      topicPerformance[topic].correct += 1;
    }
  });

  return (
    <div className="quiz-result">
      <h2>Quiz Completed!</h2>
      <div className="result-summary">
        <h3>Score: {Score}</h3>
        <p>
          Correct Answers: {CorrectAnswer} out of {answers.length} (
          {accuracy}% accuracy)
        </p>
        <p>Total Time Taken: {totalTimeTaken} seconds</p>
      </div>

      {/* Performance by Topic */}
      <div className="topic-performance">
        <h4>Performance by Topic</h4>
        {Object.entries(topicPerformance).map(([topic, data]) => (
          <div key={topic}>
            <strong>{topic}:</strong> {data.correct}/{data.total} (
            {((data.correct / data.total) * 100).toFixed(2)}% accuracy)
          </div>
        ))}
      </div>

      {/* Attempt History */}
      <div className="attempt-history">
        <h4>Your Past Attempts</h4>
        {attempts.length > 0 ? (
          <ul>
            {attempts.map((attempt, index) => (
              <li key={index}>
                Attempt {index + 1}: Scored {attempt.score} in{" "}
                {attempt.totalTime} seconds
              </li>
            ))}
          </ul>
        ) : (
          <p>No previous attempts recorded.</p>
        )}
      </div>

      {/* Play Again Button */}
      <button className="play-again-button" onClick={handlePlayAgain}>
        Play Again
      </button>
    </div>
  );
}

export default QuizAnswer;
