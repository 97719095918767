/* import React, { useEffect, useState } from "react";
import {
  doc,
  onSnapshot,
  arrayUnion,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import "./chatStyle/chat.css";

const Chat = () => {
  const { conversationId } = useParams(); // Extract chat ID from URL
  const { userId } = useAuth(); // Current user's ID
  const [messages, setMessages] = useState([]); // Chat messages
  const [newMessage, setNewMessage] = useState(""); // Input for new message

  useEffect(() => {
    if (!conversationId) return;

    // Set up a real-time listener for the chat document
    const chatRef = doc(fireDB, "chats", conversationId);

    const unsubscribe = onSnapshot(chatRef, (snapshot) => {
      if (snapshot.exists()) {
        const chatData = snapshot.data();
        setMessages(chatData.messages || []);
      } else {
        console.error("Chat document does not exist!");
      }
    });

    return () => unsubscribe();
  }, [conversationId]);

  // Function to send a new message
  const sendMessage = async () => {
    if (!newMessage.trim()) return;

    const chatRef = doc(fireDB, "chats", conversationId);

    try {
      const timestamp = Timestamp.now();

      await updateDoc(chatRef, {
        messages: arrayUnion({
          senderId: userId,
          content: newMessage,
          timestamp: timestamp,
        }),
      });

      setNewMessage(""); // Clear input after sending
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  return (
    <div className="chat-container">
    <h2 className="chat-header">Chat</h2>
    
    <div className="chat-messages">
      {messages.length === 0 ? (
        <p className="no-messages">No messages yet.</p>
      ) : (
        messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${
              msg.senderId === userId ? "message-sent" : "message-received"
            }`}
          >
            <p className="message-content">
              <strong>{msg.senderId === userId ? "You" : "Other"}</strong>:{" "}
              {msg.content}
            </p>
            <p className="message-timestamp">
              {new Date(msg.timestamp.seconds * 1000).toLocaleString()}
            </p>
          </div>
        ))
      )}
    </div>
   
    <div className="chat-input">
      <input
        type="text"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        placeholder="Type your message..."
        className="message-input"
      />
      <button onClick={sendMessage} className="send-button">
        Send
      </button>
    </div>
  </div>
  );
};

export default Chat;  */





import React, { useEffect, useState } from "react";
import {
  doc,
  onSnapshot,
  arrayUnion,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { fireDB, storage, addDoc, collection } from "../../config/firebase"; // Import storage
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"; // Firebase storage functions
import { useParams } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import "./chatStyle/chat.css";

const Chat = () => {
  const { conversationId } = useParams();
  const { userId } = useAuth();
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [image, setImage] = useState(null); // Image state

  useEffect(() => {
    if (!conversationId) return;

    const chatRef = doc(fireDB, "chats", conversationId);

    const unsubscribe = onSnapshot(chatRef, (snapshot) => {
      if (snapshot.exists()) {
        setMessages(snapshot.data().messages || []);
      } else {
        console.error("Chat document does not exist!");
      }
    });

    return () => unsubscribe();
  }, [conversationId]);

  // Function to upload an image and return its URL
  const uploadImage = async (file) => {
    const storageRef = ref(storage, `chatImages/${conversationId}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    return new Promise((resolve, reject) => {
      uploadTask.on(
        "state_changed",
        null,
        (error) => reject(error),
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          resolve(url);
        }
      );
    });
  };

  // Function to send a message (text or image)
  const sendMessage = async () => {
    if (!newMessage.trim() && !image) return;
  
    const chatRef = doc(fireDB, "chats", conversationId);
  
    try {
      const timestamp = Timestamp.now();
      let imageUrl = null;
  
      if (image) {
        imageUrl = await uploadImage(image);
        setImage(null);
      }
  
      // Add the new message to the chat document
      await updateDoc(chatRef, {
        messages: arrayUnion({
          senderId: userId,
          content: newMessage,
          imageUrl, // Store image URL if available
          timestamp,
        }),
      });
  
      // Notify the recipient (assuming there's only one other user in the conversation)
      const recipientId = messages[0]?.senderId === userId ? messages[1]?.senderId : messages[0]?.senderId;  // Get the recipient ID
      if (recipientId && recipientId !== userId) {
        const notification = {
          type: "message",
          message: `${userId === recipientId ? "You" : "Someone"} sent a message`,
          timestamp,
          senderId: userId,
          read: false,
        };
  
        // Add the notification to the recipient's notifications collection
        const notificationsRef = collection(fireDB, "users", recipientId, "notifications");
        await addDoc(notificationsRef, notification);
      }
  
      setNewMessage("");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };
  

  return (
    <div className="chat-container">
      <h2 className="chat-header">Chat</h2>

      {/* Display messages */}
      <div className="chat-messages">
        {messages.length === 0 ? (
          <p className="no-messages">No messages yet.</p>
        ) : (
          messages.map((msg, index) => (
            <div
              key={index}
              className={`message ${
                msg.senderId === userId ? "message-sent" : "message-received"
              }`}
            >
              {msg.imageUrl && (
                <img src={msg.imageUrl} alt="Sent" className="message-image" />
              )}
              <p className="message-content">
                <strong>{msg.senderId === userId ? "You" : "Other"}</strong>:{" "}
                {msg.content}
              </p>
              <p className="message-timestamp">
                {new Date(msg.timestamp.seconds * 1000).toLocaleString()}
              </p>
            </div>
          ))
        )}
      </div>

      {/* Input to send a message */}
      <div className="chat-input">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message..."
          className="message-input"
        />
        <input
          type="file"
          accept="image/*"
          onChange={(e) => setImage(e.target.files[0])}
          className="image-input"
        />
        <button onClick={sendMessage} className="send-button">
          Send
        </button>
      </div>
    </div>
  );
};

export default Chat;

