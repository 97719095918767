import React, { useState } from "react";
import { FaRegThumbsUp, FaRegThumbsDown, FaFlag } from "react-icons/fa"; // Importing icons
import { doc, updateDoc, arrayUnion } from "firebase/firestore";
import { fireDB } from "../../../config/firebase"; // Firebase configuration
import { toast } from "react-toastify"; // Toast notifications
import SaveButton from "../../../bookmark/SavedButton";


const FactOption = ({ factId, userId }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [fact, setFacts] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);

  // Toggle the menu (show/hide the three dots menu)
  const toggleMenu = () => setIsOpen((prev) => !prev);

  // Handle "Interested" action
  const handleInterest = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const factRef = doc(fireDB, "factQuestions", factId);
      await updateDoc(factRef, {
        interestedUsers: arrayUnion(userId),
      });
      toast.success("You marked this as Interested.");
    } catch (error) {
      toast.error("Failed to mark as Interested. Please try again.");
    } finally {
      setIsProcessing(false);
      setIsOpen(false);
    }
  };

  // Handle "Not Interested" action
  const handleNotInterested = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const factRef = doc(fireDB, "factQuestions", factId);
      await updateDoc(factRef, {
        notInterestedUsers: arrayUnion(userId),
      });
      toast.success("You marked this as Not Interested.");
    } catch (error) {
      toast.error("Failed to mark as Not Interested. Please try again.");
    } finally {
      setIsProcessing(false);
      setIsOpen(false);
    }
  };

  // Handle "Report" action
  const handleReport = async () => {
    if (isProcessing) return;
    setIsProcessing(true);
    try {
      const reason = prompt("Please provide a reason for reporting:");
      if (!reason) {
        toast.error("Reporting requires a reason.");
        return;
      }
      const factRef = doc(fireDB, "factQuestions", factId);
      await updateDoc(factRef, {
        reportedBy: arrayUnion(userId),
        reportReasons: arrayUnion(reason),
      });
      toast.success("This post has been reported.");
    } catch (error) {
      toast.error("Failed to report the post. Please try again.");
    } finally {
      setIsProcessing(false);
      setIsOpen(false);
    }
  };

  return (
    <div>
      {/* Button to toggle the three dots menu */}
      <div style={{ cursor: 'pointer' }}
 onClick={toggleMenu} disabled={isProcessing}>...</div>

      {/* If the menu is open, show options */}
      {isOpen && (
        <div className="menu">
          <div onClick={handleInterest} disabled={isProcessing} className="menu-button">
            <FaRegThumbsUp /> Interested
          </div>
          <div onClick={handleNotInterested} disabled={isProcessing} className="menu-button">
            <FaRegThumbsDown /> Not Interested
          </div>
          <div onClick={handleReport} disabled={isProcessing} className="menu-button">
            <FaFlag /> Report
          </div>
          <div className="menu-button">
          <SaveButton />
          </div>
        </div>
      )}
    </div>
  );
};

export default FactOption;
