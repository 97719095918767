import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import ProfileIcon from "../../components/profile/ProfileIcon";
import { timeAgo } from "../../utils/Time";
import Spinner from "../../spinner/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { useAuth } from "../../hooks/useAuth";
import LoadingSkeleton from "../../spinner/LoadingSkeleton";
//import "./recommendationStyle/olderRecommendation.css";

const OlderRecommendation = () => {
  const { userNames, loading: userLoading } = useAuth();
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [usersData, setUsersData] = useState({});

  const navigate = useNavigate();
  const ITEMS_PER_PAGE = 5;

  // Calculate the date range for 22 to 28 days ago
  const twentyEightDaysAgo = new Date();
  twentyEightDaysAgo.setDate(twentyEightDaysAgo.getDate() - 28);

  const twentyTwoDaysAgo = new Date();
  twentyTwoDaysAgo.setDate(twentyTwoDaysAgo.getDate() - 22);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const [factSnapshot, pollSnapshot, voteSnapshot, quizSnapshot] = await Promise.all([
          getDocs(query(collection(fireDB, "factQuestions"), orderBy("createdAt", "desc"), where("createdAt", ">=", twentyEightDaysAgo), where("createdAt", "<=", twentyTwoDaysAgo))),
          getDocs(query(collection(fireDB, "pollQuestions"), orderBy("createdAt", "desc"), where("createdAt", ">=", twentyEightDaysAgo), where("createdAt", "<=", twentyTwoDaysAgo))),
          getDocs(query(collection(fireDB, "voteQuestions"), orderBy("createdAt", "desc"), where("createdAt", ">=", twentyEightDaysAgo), where("createdAt", "<=", twentyTwoDaysAgo))),
          getDocs(query(collection(fireDB, "quizQuestions"), orderBy("createdAt", "desc"), where("createdAt", ">=", twentyEightDaysAgo), where("createdAt", "<=", twentyTwoDaysAgo))),
        ]);

        const processSnapshot = (snapshot, type) =>
          snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            type,
            userId: doc.data().userId || "",
          }));

        const combinedData = [
          ...processSnapshot(factSnapshot, "Fact"),
          ...processSnapshot(pollSnapshot, "Poll"),
          ...processSnapshot(voteSnapshot, "Vote"),
          ...processSnapshot(quizSnapshot, "Quiz"),
        ];

        // Fetch user details for all posts
        const userIds = [...new Set(combinedData.map((item) => item.userId).filter(Boolean))];
        const userSnapshots = await Promise.all(
          userIds.map((id) => getDocs(query(collection(fireDB, "users"), where("__name__", "==", id))))
        );

        const usersMap = {};
        userSnapshots.forEach((snapshot) => {
          snapshot.docs.forEach((doc) => {
            usersMap[doc.id] = doc.data();
          });
        });

        setUsersData(usersMap);

        // Attach user details to each post
        const enrichedData = combinedData.map((item) => ({
          ...item,
          user: usersMap[item.userId] || { name: "Anonymous", profileImage: "" }, // Default values
        }));

        setAllData(enrichedData);
        setFilteredData(enrichedData.slice(0, ITEMS_PER_PAGE));
        setHasMoreData(enrichedData.length > ITEMS_PER_PAGE);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const fetchNextPosts = () => {
    const nextPage = currentPage + 1;
    const startIndex = nextPage * ITEMS_PER_PAGE;
    const nextPosts = allData.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    setFilteredData((prevData) => [...prevData, ...nextPosts]);
    setCurrentPage(nextPage);
    setHasMoreData(allData.length > nextPage * ITEMS_PER_PAGE);
  };

  const handleCardClick = (postId, postType) => {
    navigate(`/${postType.toLowerCase()}/${postId}`);
  };

  if (loading || userLoading) return <Spinner />;
  if (error) return <div>{error}</div>;

  return (
    <div className="older-recommendation-container">
      <InfiniteScroll
        dataLength={filteredData.length}
        next={fetchNextPosts}
        hasMore={hasMoreData}
        loader={<LoadingSkeleton />}
        endMessage={<p className="end-message">No more recommended posts.</p>}
      >
        <h2>Recommended Posts</h2>
        <div className="recommendation-list">
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <div key={item.id} className="recommendation-card" onClick={() => handleCardClick(item.id, item.type)}>
                <div className="recommendation-card-header">
                  <ProfileIcon
                    name={item.user.name}
                    profileImage={item.user.profileImage} // Use profileImage from the user object
                    className="profile-icon"
                  />
                  <div className="recommendation-header-details">
                    <span className="user-name">{item.user.name}</span>
                    <span className="created-at">{timeAgo(new Date(item.createdAt.seconds * 1000))}</span>
                  </div>
                </div>
                <p className="post-title">{item.fact || item.poll || item.vote || item.quiz || "No Title"}</p>
              </div>
            ))
          ) : (
            <p className="no-data-message">No recommendations available.</p>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default OlderRecommendation;
