import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { doc, updateDoc, increment } from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import Spinner from "../../../spinner/Spinner";
import { timeAgo } from "../../../utils/Time";
import FactLike from "../../../components/like/FactLike";
import FactRetweet from "../../../components/retweet/FactRetweet";
import FactComment from "../../../components/comment/FactComment";
import FactChart from "./FactChart";
import FactFetch from "./FactFetch";
import FactShare from "../../../components/share/FactShare";
import { toast } from "react-toastify";
import FactOption from "./FactOption";
import "../homeStyle/homeFeed.css";

const FactQuestions = () => {
  const { factQuestions, hasMore, fetchFactQuestions, error } = FactFetch();
  const navigate = useNavigate();
  const [hasSelected, setHasSelected] = useState({});
  const [shareDropdownOpen, setShareDropdownOpen] = useState(null);
  const [facts, setFacts] = useState(factQuestions);

  useEffect(() => {
    setFacts(factQuestions); // Update the facts state whenever new data is fetched
  }, [factQuestions]);

  const handleSelect = async (factId, option) => {
    try {
      const questionRef = doc(fireDB, "factQuestions", factId);
      await updateDoc(questionRef, {
        [`facts.${option}`]: increment(1),
      });

      // Update local state after selecting an option
      setHasSelected((prevSelections) => ({
        ...prevSelections,
        [factId]: option,
      }));

      // Refresh the factQuestions after update
      const updatedFactQuestions = factQuestions.map((fact) =>
        fact.id === factId
          ? {
              ...fact,
              facts: {
                ...fact.facts,
                [option]: (fact.facts[option] || 0) + 1,
              },
            }
          : fact
      );
      setFacts(updatedFactQuestions);

      toast.success(`You selected: ${option}`);
    } catch (error) {
      toast.error("Selection failed. Please try again.");
    }
  };

  const toggleShareDropdown = (factId) => {
    setShareDropdownOpen((prev) => (prev === factId ? null : factId));
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <InfiniteScroll
      dataLength={facts.length}
      next={fetchFactQuestions}
      hasMore={hasMore}
      loader={<Spinner />}
      endMessage={<p>No more facts available.</p>}
    >
      <div>
        {facts.map((fact) => {
          // Ensure that 'agree' and 'disagree' exist, fallback to 0 if not
          const totalFacts =
            (fact.facts?.agree || 0) + (fact.facts?.disagree || 0);

          // Ensure percentages don't divide by zero
          const agreePercentage =
            totalFacts > 0 ? ((fact.facts?.agree || 0) / totalFacts) * 100 : 0;
          const disagreePercentage =
            totalFacts > 0
              ? ((fact.facts?.disagree || 0) / totalFacts) * 100
              : 0;

          console.log(
            `Fact ID: ${fact.id} - Agree: ${agreePercentage}% - Disagree: ${disagreePercentage}%`
          ); // Debugging

          return (
            <div key={fact.id} className="home-post-card">
              {/* User Info */}
              <div className="home-post-header">
                <div
                  className="user-avatar"
                  onClick={() =>
                    fact.user.id &&
                    navigate(
                      `/profile/${fact.user.name
                        .replace(/\s+/g, "")
                        .toLowerCase()}/${fact.user.id}`
                    )
                  }
                >
                  <ProfileIcon
                    name={fact.user.name}
                    profileImage={fact.user.profileImage}
                  />
                </div>

                <div className="user-details">
                  <span className="user-name">{fact.user.name}</span>
                  <span className="post-timestamp">
                    {timeAgo(new Date(fact.createdAt.seconds * 1000))}
                  </span>
                </div>

                <div className="home-post-options-menu">
                  <FactOption factId={fact.id} userId="currentUserId" />
                </div>
              </div>

              {/* Post Content */}
              <div>
                <p className="home-post-content">{fact.fact}</p>
              </div>

              {/* Display Poll Image if it exists */}
              {fact.image && (
                <div className="home-image-container">
                  <img
                    src={fact.image}
                    alt={fact.fact}
                    className="home-image"
                  />
                </div>
              )}

              {/* Interaction Buttons */}
              <div className="home-post-actions">
                <button
                  className={`action-button ${
                    hasSelected[fact.id] === "Agree" ? "selected" : ""
                  }`}
                  onClick={() => handleSelect(fact.id, "Agree")}
                >
                  👍 Agree
                </button>
                <button
                  className={`action-button ${
                    hasSelected[fact.id] === "Disagree" ? "selected" : ""
                  }`}
                  onClick={() => handleSelect(fact.id, "Disagree")}
                >
                  👎 Disagree
                </button>
              </div>

              {/* Popularity Prediction */}
              {fact.popularityPrediction && (
                <div className="home-post-popularity">
                  <p>📊 Popularity Prediction: {fact.popularityPrediction}</p>
                </div>
              )}

              {/* Analytics */}
              <div className="home-post-analytics">
                {hasSelected[fact.id] && (
                  <FactChart
                    agreePercentage={fact.facts?.Agree || 0}
                    disagreePercentage={fact.facts?.Disagree || 0}
                  />
                )}
              </div>

              {/* Additional Options */}
              <div className="home-post-footer-icons">
                <FactLike factId={fact.id} initialLikes={fact.likes} />
                <FactRetweet factId={fact.id} initialRetweets={fact.retweets} />

                <FactComment
                  factId={fact.id}
                  fact={fact}
                  isAuthenticated={true}
                />
                <FactShare
                  key={fact.id}
                  fact={fact}
                  toggleShareDropdown={toggleShareDropdown}
                  shareDropdownOpen={shareDropdownOpen}
                  setFacts={setFacts}
                />
              </div>
            </div>
          );
        })}
      </div>
    </InfiniteScroll>
  );
};

export default FactQuestions;
