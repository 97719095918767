import React from "react";
import "./otherStyle/about.css"; // Assuming you style the page with CSS
import Footer from "../footer/Footer";

const About = () => {
  return (
    <div className="about-container">
      <header className="about-header">
        <h1>Welcome to Xnetous</h1>
        <p>The Social Media Platform of the Future</p>
      </header>

      <section className="about-introduction">
        <h2>What is Xnetous?</h2>
        <p>
          Xnetous is a next-generation social media platform designed to empower
          users to connect, share, and engage in meaningful ways. Unlike
          traditional platforms, Xnetous integrates advanced technologies like
          machine learning (ML) and natural language processing (NLP) to create
          a personalized and interactive experience for all users.
        </p>
      </section>

      <section className="about-features">
        <h2>Key Features</h2>
        <ul>
          <li>
            <strong>User Authentication:</strong> Sign up with your name, email,
            and password, then confirm your account to access the platform.
          </li>
          <li>
            <strong>User Dashboard:</strong> Track profile rank, score,
            accuracy, followers, following, liked posts, and responses.
          </li>
          <li>
            <strong>Advanced Post Options:</strong> Share facts
            (agree/disagree), create polls (two options), engage in yes/no
            votes, and design custom quizzes.
          </li>
          <li>
            <strong>Dynamic Home Feed:</strong> Posts are ranked based on
            engagement using ML and NLP algorithms.
          </li>
          <li>
            <strong>Search and Categories:</strong> Discover posts through a
            dynamic search engine and categorized content with recommended
            posts.
          </li>
          <li>
            <strong>Trending and Recent Feeds:</strong> Latest posts sorted by
            recency and responses, as well as a trend feed based on post
            performance metrics.
          </li>
          <li>
            <strong>Premium Plans:</strong> Unlock rewards, earn based on rank,
            and enjoy advanced features with a ₹126/month membership.
          </li>
          <li>
            <strong>Community Features:</strong> Chat functionality and
            discussion rooms for collaborative interactions.
          </li>
          <li>
            <strong>Settings Page:</strong> Customize themes, update passwords,
            lock profiles, and deactivate accounts.
          </li>
          <li>
            <strong>Advertising:</strong> Premium members can create two free
            ads per month; additional ads cost ₹59 each.
          </li>
        </ul>
      </section>

      <section className="about-premium">
        <h2>Why Choose Premium?</h2>
        <p>
          Our premium membership is designed for users who want to maximize
          their experience on Xnetous. For just <strong>₹126/month</strong>,
          premium members receive:
        </p>
        <ul>
          <li>Eligibility to earn rewards for being a top-ranked user.</li>
          <li>The ability to create up to two free ads per month.</li>
          <li>Exclusive features and early access to new updates.</li>
        </ul>
      </section>

      <section className="about-technology">
        <h2>Technology Behind Xnetous</h2>
        <p>
          Xnetous is built using cutting-edge web technologies to ensure a
          seamless user experience. Our platform leverages:
        </p>
        <ul>
          <li>
            <strong>React.js:</strong> For a dynamic and responsive front-end
            experience.
          </li>
          <li>
            <strong>Node.js and Express:</strong> To power a robust back-end
            infrastructure.
          </li>
          <li>
            <strong>Machine Learning:</strong> For personalized content ranking
            and insights.
          </li>
          <li>
            <strong>Mobile Responsiveness:</strong> Designed for optimal
            performance on all devices, including a dedicated mobile app.
          </li>
        </ul>
      </section>

      <section className="about-community">
        <h2>Building a Safe and Vibrant Community</h2>
        <p>
          Safety and inclusivity are at the core of Xnetous. We provide tools to
          report abuse, moderate content, and maintain a respectful environment
          for all users. Our community guidelines ensure that everyone can enjoy
          a positive and engaging experience.
        </p>
      </section>

      <section className="about-ads">
        <h2>Advertising on Xnetous</h2>
        <p>
          Xnetous offers innovative advertising solutions for users and
          businesses. Premium members can create two free ads per month, with
          additional ads priced at <strong>₹59 each</strong>. Showcase your
          brand to a targeted audience and grow your reach effortlessly.
        </p>
      </section>

      <section className="about-discussion">
        <h2>Engage Through Discussions</h2>
        <p>
          Xnetous fosters meaningful conversations through its discussion rooms.
          Users can create topics, share their thoughts, and collaborate with
          others in real-time discussions based on chosen titles.
        </p>
      </section>

      <section className="about-team">
        <h2>Meet the Team</h2>
        <p>
          Behind Xnetous is a dedicated team of developers, designers, and
          visionaries committed to revolutionizing social media. We work
          tirelessly to ensure our platform remains cutting-edge, user-friendly,
          and impactful.
        </p>
      </section>

      <footer className="about-footer">
        <p>
          Thank you for being part of the Xnetous journey. Together, we can
          create a social media experience like no other. If you have any
          questions or feedback, feel free to reach out to our support team.
        </p>
        <Footer />
      </footer>
    </div>
  );
};

export default About;
