import { useState, useEffect } from "react";
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
} from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { auth } from "../config/firebase";
import "../config/firebase"; // Import the CSS file
import "../authStyle/LoginPage.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [resetEmail, setResetEmail] = useState("");
  const [resetRequested, setResetRequested] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      navigate("/dashboard"); // Redirect to dashboard if already logged in
    }
  }, [navigate]);

  const login = async () => {
    setLoading(true); // Start loading when attempting login
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      toast.success("Login successful");
      localStorage.setItem("user", JSON.stringify(result));
      navigate("/dashboard"); // Redirect to dashboard after login
    } catch (error) {
      if (error.code === "auth/too-many-requests") {
        toast.error(
          "Access to this account has been temporarily disabled due to many failed login attempts. Reset your password to restore access."
        );
      } else {
        toast.error(`Error: ${error.message}`);
      }
      console.log(error);
    } finally {
      setLoading(false); // Stop loading after attempt
    }
  };

  const handlePasswordReset = async () => {
    if (!resetEmail) {
      toast.error("Please enter your email address");
      return;
    }
    setLoading(true); // Start loading for password reset
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      toast.success("Password reset email sent successfully.");
      setResetRequested(true);
    } catch (error) {
      toast.error(`Error: ${error.message}`);
      console.log(error);
    } finally {
      setLoading(false); // Stop loading after reset request
    }
  };

  return (
    <div className="outer-container">
      <div className="login-card">
        <h1 className="login-header">Login</h1>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="email"
          className="login-input"
          placeholder="Email"
          aria-label="Email"
          required
        />
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="login-input"
          placeholder="Password"
          aria-label="Password"
          required
        />
        <button onClick={login} className="login-button" disabled={loading}>
          {loading ? "Logging in..." : "Login"}
        </button>
        <div className="login-footer">
          <p className="login-link">
            Don't have an account?
            <a href="/create-account" className="login-link">
              Create Account
            </a>
          </p>
          <div className="forgot-password">
            {resetRequested ? (
              <p className="reset-notification">
                Check your email for the password reset link.
              </p>
            ) : (
              <>
                <input
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  className="login-input"
                  placeholder="Enter your email to reset password"
                  aria-label="Password reset email"
                  required
                />
                <button
                  onClick={handlePasswordReset}
                  className="forgot-password-button"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Reset Password"}
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
