import { useState } from "react";
import { FaComment, FaTrashAlt } from "react-icons/fa";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase"; // Adjust the import path if needed

const QuizComment = ({ quiz, userId, isAuthenticated, updateQuiz }) => {
  const [newComment, setNewComment] = useState("");
  const [isCommentOpen, setIsCommentOpen] = useState(false); // State to toggle comments dropdown
  const [localComments, setLocalComments] = useState(quiz.comments || []); // Local state for comments
  const maxCommentLength = 40; // Define max length for a comment

  const handleAddComment = async (quizId, comment) => {
    if (comment.trim() === "") return; // Prevent adding empty comments
    if (comment.length > maxCommentLength) return; // Prevent overly long comments

    const quizRef = doc(fireDB, "quizQuestions", quizId);
    const newComments = [...localComments, comment];

    try {
      await updateDoc(quizRef, {
        comments: newComments,
        commentCount: newComments.length,
      }); // Update Firestore with new comment count

      // Update local state after adding the comment
      setLocalComments(newComments);
      if (updateQuiz) {
        updateQuiz(quizId, newComments, "comments"); // Update parent component's state if needed
      }
      setNewComment(""); // Clear the input field after adding
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleDeleteComment = async (quizId, commentIndex) => {
    const quizRef = doc(fireDB, "quizQuestions", quizId);
    const updatedComments = localComments.filter(
      (_, index) => index !== commentIndex
    );

    try {
      await updateDoc(quizRef, {
        comments: updatedComments,
        commentCount: updatedComments.length,
      }); // Update Firestore with new comment count

      // Update local state after deleting the comment
      setLocalComments(updatedComments);
      if (updateQuiz) {
        updateQuiz(quizId, updatedComments, "comments"); // Update parent component's state if needed
      }
    } catch (error) {
      console.error("Error deleting comment:", error);
    }
  };

  const toggleComments = () => {
    setIsCommentOpen(!isCommentOpen); // Toggle the comment section
  };

  return (
    <div>
      {/* Toggle Comments Button */}
      <div className="comment-toggle" onClick={toggleComments}>
        <FaComment size={15} />
        <span className="toggle-text">
          {isCommentOpen ? "hide" : "view"} 
        </span>
        <span className="toggle-count">
          ({localComments.length})
        </span>
      </div>

      {/* Comments Section */}
      {isCommentOpen && (
        <div className="comment-section">
          {localComments.map((comment, index) => (
            <div key={index} className="comment">
              <p className="comment-text">{comment}</p>
              {isAuthenticated && (
                <FaTrashAlt
                  className="comment-delete"
                  onClick={() => handleDeleteComment(quiz.id, index)}
                  size={18}
                />
              )}
            </div>
          ))}

          {/* Add New Comment */}
          {isAuthenticated && (
            <div className="add-comment">
              <input
                type="text"
                value={newComment}
                onChange={(e) =>
                  setNewComment(e.target.value.slice(0, maxCommentLength))
                }
                placeholder="Write your comment here..."
              />
              <p className="remaining-characters">
                {maxCommentLength - newComment.length} characters remaining
              </p>
              <button
                onClick={() => handleAddComment(quiz.id, newComment)}
                disabled={!newComment.trim()}
              >
                Add
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuizComment;
