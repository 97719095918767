import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import Spinner from "../spinner/Spinner";
import ProfileUpdate from "../components/profile/ProfileUpdate";
import { toast } from "react-toastify";
import { fireDB, auth } from "../config/firebase";
import Ranking from "../components/rank/Ranking";
import SubscriptionStatus from "../components/subscription/SubscriptionStatus";
import "../authStyle/dashboard.css";
import PostDashboard from "./PostDashboard";
import Followers from "../components/connection/Followers";
import Following from "../components/connection/Following";
import ProfileView from "../components/profile/ProfileView";
import UserHealth from "../components/user/UserHealth";
import ProfileData from "../components/profile/ProfileData";
import Payout from "../components/subscription/Payout";

function Dashboard() {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const userId = user?.uid; // Get user ID from the authenticated user
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      setLoading(true);
      if (currentUser) {
        setUser(currentUser);
        try {
          const userDoc = doc(fireDB, "users", currentUser.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists()) {
            setUserData(docSnap.data());

            // await fetchFollowers(); // Fetch followers
          } else {
            toast.error("User data not found");
          }
        } catch (error) {
          console.error("Error fetching data:", error);
          toast.error("Failed to load data. Please try again.");
        } finally {
          setLoading(false);
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userRef = doc(fireDB, "users", userId);
        const userSnap = await getDoc(userRef);
        const currentUser = userSnap.data();
      } catch (error) {
        setError("Failed to fetch user data");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    if (userId) {
      const userRef = doc(fireDB, "users", userId);
      const unsubscribe = onSnapshot(userRef, (doc) => {
        if (doc.exists()) {
          const userData = doc.data();
          console.log("Updated user data:", userData); // Debugging log
          setUserData({
            ...userData,
            subscriptionStatus: !!userData.subscription,
          });
        }
      });
      return () => unsubscribe();
    }
  }, [userId]);

  if (loading) {
    return <Spinner />;
  }

  if (!user || !userData) {
    return <div className="dashboard-container">Loading your dashboard...</div>;
  }

  return (
    <div className="dashboard">
      <header className="dashboard-header">
        <div className="">
          <h1>
            Hello, <span className="user-name">{userData.name || "User"}</span>
          </h1>
          <SubscriptionStatus isSubscribed={userData.subscriptionStatus} />
        </div>
        <div className="header-right">
          {userData.subscriptionStatus && (
            <button className="btn green-btn">Subscribed</button>
          )}
        </div>
      </header>

      <ProfileData />

      <section>
        <div>
          {userData.time && (
            <p>
              <strong>Account Created:</strong>{" "}
              {userData.time.toDate().toString()}
            </p>
          )}
        </div>
        <button
          className="btn update-profile-btn"
          onClick={() => setShowUpdateProfile(true)}
        >
          Update Profile
        </button>
        {showUpdateProfile && (
          <ProfileUpdate
            userData={userData}
            onClose={() => setShowUpdateProfile(false)}
          />
        )}
      </section>

      <section className="performance-section">
        <h2>You're going with</h2>
        <Ranking />
       
        <UserHealth />

        <p>Profile Views: {userData.profileViews || 0}</p>
        <ProfileView userId={userData} />
      </section>

      <section className="user-interaction">
        <Followers userId={userId} />
        <Following userId={userId} />
      </section>

      <section className="user-interaction">
        <Link to="/liked-post">Liked</Link>
        <Link to="/responed-post">Resonded</Link>
        <Link to="/saved-post">Bookmark</Link>
      </section>

      <h2> Post</h2>

      <PostDashboard />
    </div>
  );
}

export default Dashboard;
