import React from "react";

const ProfileIcon = ({
  name = "User",
  profileImage,
  size = 40, // Default size is now smaller
  colors = [
    "#FF5733", "#33FF57", "#3357FF", "#FFC300", "#8E44AD", "#1F618D", "#229954",
    "#AF7AC5", "#D35400", "#7D3C98", "#2ECC71", "#2980B9", "#E74C3C", "#F1C40F",
    "#697005", "#DC2C75", "#8BE958", "#0B304C", "#98791D", "#2EF8F1", "#89FA54", "#6DABA6", 
    "#10AFCD", "#818AD2", "#DB882B", "#79D5CD", "#AE78E0", "#93B4A1", "#4B392C", "#443CB8", 
    "#6C4A7B", "#EB9551", "#75F0BD", "#A9E56D", "#AD4B0C", "#DD42DB", "#64B438", "#6E99E9", 
    "#17153D", "#191D3F", "#31824C", "#907BB0", "#54FEB4", "#A88D3E", "#9E1156", "#E7D855", 
    "#221051", "#09D7B3", "#0A254E", "#0C22AF", "#0EE4D4", "#C687B9", "#688788", "#09F2B6", 
    "#E71A1C", "#7FE88F", "#A0F144", "#02ABCE", "#AB12EF", "#FBF63E", "#523A3F", "#861745", 
    "#8094D2", "#B45397", "#6CD9F6", "#AF5EDC", "#2B1BB1", "#353DF4", "#C20903", "#E394E9", 
    "#F083CC", "#6CC6AF", "#0C0F4F", "#39BF55", "#F2E239", "#C14CE7", "#86175C", "#411F79", 
    "#E89F4A", "#0D12B2", "#7C6183", "#39EA9B", "#EDF573", "#FB05E4", "#72F32E", "#A25275", 
    "#A71D5B", "#51FA23", "#2FDAE1", "#B71B79", "#810416", "#53FF56", "#9326B2", "#EC18AA", 
    "#0BFAFF", "#38936F", "#CF437B", "#A15B13", "#478D00", "#54F9E0", "#59AFE6", "#0D6356", 
    "#384CD9", "#5EA3F5", "#8BB82E", "#25A91C", "#C6867F", "#97B1DC", "#8F3EDF", "#A17EF1", 
    "#0E5DE0", "#6AAE4F", "#B3662E", "#581D0E", "#C4B3FC", "#AE397B", "#BFBD2C", "#E47B5F", 
    "#7C2502", "#760728", "#F52FAE", "#97695A", "#AE7823", "#EF1594", "#0B6AC7", "#E69180", 
    "#BB2932", "#3889CA", "#CB6932", "#7C5629", "#EE78EB", "#80278C", "#8EC037", "#DE8DFC", 
    "#94FF1C", "#CC48F4", "#D26913", "#58E4EA", "#23CB36", "#5F8B17", "#FD2B94", "#04956F", 
    "#B11656", "#87B9E8", "#BAD51B", "#7995D6", "#2DB40F", "#2C1B2B", "#2F1BC4", "#360965", 
    "#7B52BD", "#1D58D7", "#BA7BA1", "#96E723", "#677CF1", "#0FDBDC", "#2B0EC5", "#820829", 
    "#3E617E", "#E4D43E", "#0EFB42", "#F7C907", "#3125D8", "#3980BE", "#BB9C52", "#A25742", 
    "#C75C20", "#44F88E", "#FC247E", "#5A31B5", "#73E472", "#707986", "#2D449E", "#C26A9A", 
    "#F9AE93", "#468F8F", "#D16206", "#C7675F", "#92A172", "#51AC67", "#C3E643", "#479E9A", 
    "#8C6696", "#810C1F", "#B64B98", "#A6FE91", "#FDB32B", "#76E313", "#C16AC5", "#81CEDF", 
    "#D6A1BA", "#6811A9", "#9A7A5A", "#6257C0", "#90F9E1", "#909F4C", "#4AC2A3", "#14C6F7", 
    "#39FAA2", "#C1FFB9", "#2F3318", "#1A6894", "#799B64", "#417CE8", "#1160C0", "#DD5BE7", 
    "#1014AC", "#D6ACBB", "#740EC4", "#3411B1", "#B226D6", "#6A2F4F", "#6BD8E1", "#DACC64", 
    "#089278", "#C0E3B9", "#B89379", "#5411E7", "#A8ED57", "#50B80A", "#A41F38", "#AA2638", 
    "#0A8C66", "#E66765", "#4B4A0F", "#831312", "#5BF0AE", "#04DBAD", "#913541", "#196F9D", 
    "#8AD243", "#4D05C4", "#43FA4F", "#17E86F", "#2D8F42", "#217220", "#2C6734", "#BF4219", 
    "#822757", "#920098", "#18E330", "#9AC292", "#C351CA", "#F36FAB", "#CC2774", "#0D45F7", 
    "#EE237D", "#84E682", "#699AB8", "#1259F2", "#358F2B", "#47CE70", "#AF5DA8", "#B18495", 
    "#B0DF6D", "#2258F6", "#2FB6A3", "#EB6DC0", "#021E97", "#6DA7DA", "#7049F5", "#86D54D", 
    "#6FFAE2", "#68634D", "#BDE784", "#5AF31C", "#8F0B3F", "#06ABAF", "#B6C057", "#A1B24F", 
    "#2D36D1", "#0B6C8F", "#4E2CB8", "#E3BD9C", "#AEFCF0", "#BAD46F", "#6BF09A", "#8991E4", 
    "#A03598", "#D36FCC", "#4A1FF7", "#BA593E", "#6855EE", "#4FCE02", "#780E80", "#BFD9EF", 
    "#8C952E", "#009E10", "#74C3D0", "#C674A4", "#857771", "#55B341", "#50A2E3", "#CC1A78", 
    "#60C8A7", "#4FA2A1", "#1FD9CE", "#E4D5B3", "#1BDC7D", "#D33E8E", "#0087D9", "#3383B8", 
    "#CEFED0", "#F99FAD", "#4C9502", "#B9FCA5", "#A103BF", "#D70209", "#6A5C60", "#FA0F78", 
    "#73E32A", "#338F86", "#05433C", "#844536", "#094F13", "#439760", "#A42B1B", "#3DBCDA", 
    "#A53AD5", "#593CCD", "#E8E9EF", "#561D47", "#075CDD", "#BD378B", "#821C11", "#2247D5", 
    "#83578A", "#91DDBF", "#826767", "#07CF6F", "#8623B1", "#03B1C4", "#41CEC1", "#25D1A5", 
    "#4AA62C", "#8457A4", "#62E9EF", "#C1177D", "#C1DF60", "#30286C", "#B8FB10", "#00B4BF", 
    "#9A6040", "#E4D939", "#794DE1", "#DC46B9", "#7539A4", "#B74A96", "#99E079", "#0BC98D", 
    "#0B85C0", "#F9C01B", "#B47EDB", "#0FA7E6", "#7834D1", "#59186A", "#A4A822", "#E8E70B" 
    // Add more colors or import from a separate file
  ],
}) => {
  const generatePlaceholderIcon = (name) => {
    // Get initials (up to 2 characters)
    const initials = name
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase())
      .join("")
      .substring(0, 2);

    // Select a background color from the palette
    const bgColor = colors[
      initials.charCodeAt(0) % colors.length
    ];

    return (
      <div
        aria-label={`${name} icon`}
        style={{
          width: `${size}px`,
          height: `${size}px`,
          backgroundColor: bgColor,
          color: "white",
          fontSize: `${size / 2.5}px`, // Adjust font size to be proportional
          fontWeight: "bold",
          textAlign: "center",
          lineHeight: `${size}px`,
          borderRadius: "50%",
        }}
      >
        {initials}
      </div>
    );
  };

  return (
    <div>
      {profileImage ? (
        <img
          src={profileImage}
          alt={`${name}`}
          style={{
            width: `${size}px`,
            height: `${size}px`,
            borderRadius: "50%",
            objectFit: "cover",
          }}
        />
      ) : (
        generatePlaceholderIcon(name)
      )}
    </div>
  );
};

export default ProfileIcon;
