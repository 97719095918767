import React, { useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import {
  FaShareAlt,
  FaFacebook,
  FaTwitter,
  FaWhatsapp,
  FaLinkedin,
  FaTelegram,
  FaReddit,
  FaEnvelope,
} from "react-icons/fa";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  TelegramShareButton,
  RedditShareButton,
  EmailShareButton,
} from "react-share";
import { doc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";

const FactShare = ({ fact, toggleShareDropdown, shareDropdownOpen, setFacts }) => {
  const [isSharing, setIsSharing] = useState(false);

  const dropdownAnimation = useSpring({
    opacity: shareDropdownOpen === fact.id ? 1 : 0,
    transform: shareDropdownOpen === fact.id ? "scale(1)" : "scale(0.95)",
    display: shareDropdownOpen === fact.id ? "block" : "none",
    config: { tension: 300, friction: 20 },
  });

  const handleShare = async (factId) => {
    if (isSharing) return; // Prevent multiple share attempts
    setIsSharing(true);

    try {
      const factRef = doc(fireDB, "factQuestions", factId);

      // Make sure the share count is initialized
      const currentShares = fact.shares || 0;

      // Optimistically update the share count in the local state
      setFacts((prevFacts) =>
        prevFacts.map((f) =>
          f.id === factId ? { ...f, shares: currentShares + 1 } : f
        )
      );

      // Update the share count in Firestore
      await updateDoc(factRef, {
        shares: currentShares + 1,
      });
    } catch (error) {
      console.error("Error updating share count:", error);
    } finally {
      setIsSharing(false);
    }
  };

  const shareOptions = [
    {
      Button: FacebookShareButton,
      url: `https://xnetous.com/fact/${fact.id}`,
      Icon: FaFacebook,
      description: `Check out this fact: "${fact.fact}"`,
    },
    {
      Button: TwitterShareButton,
      url: `https://xnetous.com/fact/${fact.id}`,
      Icon: FaTwitter,
      description: `Check out this fact: "${fact.fact}"`,
    },
    {
      Button: WhatsappShareButton,
      url: `https://xnetous.com/fact/${fact.id}`,
      Icon: FaWhatsapp,
      description: `Check out this fact: "${fact.fact}"`,
    },
    {
      Button: LinkedinShareButton,
      url: `https://xnetous.com/fact/${fact.id}`,
      Icon: FaLinkedin,
      description: `Check out this fact: "${fact.fact}"`,
    },
    {
      Button: TelegramShareButton,
      url: `https://xnetous.com/fact/${fact.id}`,
      Icon: FaTelegram,
      description: `Check out this fact: "${fact.fact}"`,
    },
    {
      Button: RedditShareButton,
      url: `https://xnetous.com/fact/${fact.id}`,
      Icon: FaReddit,
      description: `Check out this fact: "${fact.fact}"`,
    },
    {
      Button: EmailShareButton,
      url: `https://xnetous.com/fact/${fact.id}`,
      Icon: FaEnvelope,
      description: `Check out this fact: "${fact.fact}"`,
    },
  ];

  return (
    <div className="share-container">
      <div
        className="share-button"
        onClick={() => {
          toggleShareDropdown(fact.id);
          handleShare(fact.id); // Increment the share count when the dropdown opens
        }}
        aria-label={`Share "${fact.fact}"`}
      >
        <FaShareAlt size={16} />
        <span style={{ fontSize: '15px' }} className="share-count">{fact.shares || 0}</span>
      </div>

      <animated.div style={dropdownAnimation} className="share-dropdown">
        {shareOptions.map(({ Button, url, Icon, description }, index) => (
          <Button
            key={index}
            url={url}
            title={description} // Set dynamic title for sharing
            className="share-option"
          >
            <Icon className="share-option-icon" />
          </Button>
        ))}
      </animated.div>
    </div>
  );
};

export default FactShare;
