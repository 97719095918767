import React from "react";

const VoteChart = ({ yes = 0, no = 0 }) => {
  const totalVotes = yes + no;
  const yesPercentage = totalVotes ? ((yes / totalVotes) * 100).toFixed(2) : 0;
  const noPercentage = totalVotes ? ((no / totalVotes) * 100).toFixed(2) : 0;

  return (
    <div style={styles.container}>
      <div style={styles.voteCount} role="presentation" aria-label="Vote results">
        <p style={styles.voteText}>
          <span style={styles.voteLabel}>Yes: </span>{yes} ({yesPercentage}%)
        </p>
        <p style={styles.voteText}>
          <span style={styles.voteLabel}>No: </span>{no} ({noPercentage}%)
        </p>
      </div>
      <div style={styles.progressBarContainer}>
        <div
          style={{ ...styles.progressBar, backgroundColor: "#1DA1F2", width: `${yesPercentage}%` }}
        />
        <div
          style={{ ...styles.progressBar, backgroundColor: "#657786", width: `${noPercentage}%` }}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: "center",
    padding: "12px",
    borderRadius: "12px",
    backgroundColor: "#ffffff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    marginBottom: "12px",
    fontFamily: "'Helvetica Neue', Arial, sans-serif",
  },
  voteCount: {
    display: "flex",
    justifyContent: "center", // Horizontally aligns the text
    gap: "20px", // Space between the 'Yes' and 'No' sections
    marginTop: "10px",
  },
  voteText: {
    fontSize: "14px",
    fontWeight: "500",
    color: "#14171A", // Dark gray, similar to text color in Twitter
    marginBottom: "6px",
  },
  voteLabel: {
    fontWeight: "700",
    color: "#1DA1F2", // Twitter blue for emphasis
  },
  progressBarContainer: {
    display: "flex",
    height: "6px",
    borderRadius: "3px",
    marginTop: "12px",
    overflow: "hidden",
  },
  progressBar: {
    height: "100%",
    transition: "width 0.3s ease-in-out",
  },
};

export default VoteChart;
