import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
  doc,
  getDoc,
} from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { useNavigate } from "react-router-dom";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import { timeAgo } from "../../../utils/Time";
import Spinner from "../../../spinner/Spinner";
import "./latestRespond.css";
import OlderRecommendation from "../../../components/recommendation/OlderRecommendation";

const LatestRespond = () => {
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [error, setError] = useState(null);
  const [lastVisible, setLastVisible] = useState(null); // For infinite scrolling
  const [totalPostsCount, setTotalPostsCount] = useState(0); // Store total post count
  const navigate = useNavigate();

  // Fetch tweets created in the last 48 hours
  const fetchRecentTweets = async () => {
    setLoading(true);
    setError(null);
  
    try {
      const limitPerPage = 3; // Limit per page for infinite scrolling
      const now = new Date();
      const fortyEightHoursAgo = new Date(now.getTime() - 48 * 60 * 60 * 1000); // 48 hours ago
  
      let combinedData = [];
      let lastVisibleDocs = [];
  
      // Define collections to fetch
      const collections = [
        { name: "factQuestions", type: "Fact" },
        { name: "pollQuestions", type: "Poll" },
        { name: "voteQuestions", type: "Vote" },
        { name: "quizQuestions", type: "Quiz" },
      ];
  
      // Fetch posts from each collection
      for (const collectionData of collections) {
        let queryRef = query(
          collection(fireDB, collectionData.name),
          where("createdAt", ">=", fortyEightHoursAgo), // Filter for the last 48 hours
          orderBy("createdAt", "desc"),
          limit(limitPerPage)
        );
  
        if (lastVisible) {
          queryRef = query(
            collection(fireDB, collectionData.name),
            where("createdAt", ">=", fortyEightHoursAgo),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(limitPerPage)
          );
        }
  
        const snapshot = await getDocs(queryRef);
        const data = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          type: collectionData.type,
        }));
  
        combinedData = [...combinedData, ...data];
  
        // Store last visible doc for infinite scroll
        if (!snapshot.empty) {
          lastVisibleDocs.push(snapshot.docs[snapshot.docs.length - 1]);
        }
      }
  
      // Sort posts by retweets in descending order
      combinedData.sort((a, b) => (b.retweets || 0) - (a.retweets || 0));
  
      // Fetch unique user IDs from the combined data
      const userIds = [
        ...new Set(combinedData.map((item) => item.userId).filter(Boolean)),
      ];
  
      // Fetch user profiles
      const userProfiles = await Promise.all(
        userIds.map((id) =>
          getDoc(doc(fireDB, "users", id)).then((snapshot) =>
            snapshot.exists() ? { id, ...snapshot.data() } : null
          )
        )
      );
  
      // Create a user map for quick lookup
      const userMap = Object.fromEntries(
        userProfiles.filter(Boolean).map((user) => [user.id, user])
      );
  
      // Combine user data with post data
      const dataWithUsers = combinedData.map((item) => ({
        ...item,
        user: userMap[item.userId] || {
          name: "Unknown User",
          profileImage: "",
        },
      }));
  
      // Update state with sorted data
      setAllData(dataWithUsers);
  
      // Update total post count
      setTotalPostsCount(dataWithUsers.length);
  
      // Update last visible document
      setLastVisible(
        lastVisibleDocs.length > 0
          ? lastVisibleDocs[lastVisibleDocs.length - 1]
          : null
      );
    } catch (error) {
      console.error("Error fetching data:", error);
      setError(`Failed to load data. Error: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };
  ;

  // Fetch data when the component mounts
  useEffect(() => {
    fetchRecentTweets();
  }, []);

  // Handle navigation to tweet details
  const handleNavigate = (id, type) => {
    navigate(`/${type.toLowerCase()}/${id}`);
  };

  return (
    <div className="latest-post-feed">
      {loading ? (
        <div className="latest-post-loader">
          <Spinner />
        </div>
      ) : error ? (
        <div className="latest-post-error-message">{error}</div>
      ) : (
        <div className="latest-post-trending-list">
          <p className="total-post-count">
            Total Posts Fetched: {totalPostsCount}
          </p>
          {allData.length > 0 ? (
            allData.map((item) => (
              <div
                key={item.id}
                className="latest-post-card"
                onClick={() => handleNavigate(item.id, item.type)}
              >
                <div className="latest-post-card-header">
                  <ProfileIcon
                    name={item.user.name}
                    profileImage={item.user.profileImage || ""}
                    className="latest-post-profile-icon"
                  />
                  <div className="latest-post-user-info">
                    <span className="latest-post-user-name">
                      {item.user.name}
                     {/* {item.user.name[item.userId]?.isSubscribed && (
                        <span
                          className="latest-post-verified-badge"
                          title="Subscribed"
                        >
                          ✔️
                        </span>
                      )}  */}
                    </span>
                    <span className="latest-post-created-at">
                      {timeAgo(new Date(item.createdAt.seconds * 1000))}
                    </span>
                  </div>
                </div>

                <div className="latest-post-card-body">
                  <p className="post-title" style={{
                          fontSize: "15px",
                          color: "#14171a",
                        }}>
                    {item.fact ||
                      item.poll ||
                      item.vote ||
                      item.quiz ||
                      "No Title"}
                  </p>
                </div>

                <div className="latest-post-card-footer">
                  <div className="latest-post-popularity">
                    <strong>Retweets:</strong> {item.retweets || 0}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="latest-post-no-data-message">
              No more responses left.
            </p>
          )}
        </div>
      )}

      <OlderRecommendation />
    </div>
  );
};

export default LatestRespond;
