import React from "react";
import QuizProblem from "./QuizProblem";
import SubscriptionPage from "../../components/subscription/SubscriptionPage";
import "./premiumStyle/premium.css";

function Premium() {
  return (
    <div className="premium-container">
      <h2 className="home-para">
        👋 Welcome to the Premium member of Xnetous!
      </h2>

      <SubscriptionPage className="subscription-container" />

      <div className="premium-context">
        <h3>
          Unlock Your Potential with Xnetous Premium Membership 🌟
        </h3>
        <p>
          At Xnetous, we believe in rewarding talent, creativity, and impact. Our Premium Membership isn’t just a subscription—it’s your gateway to recognition, rewards, and exclusive opportunities to shine in our thriving community. Here’s how it works:
        </p>
        
        <h3>🌟 Become a Premium Member</h3>
        <p>
          With a small monthly fee, you unlock the full potential of Xnetous. Premium members gain access to exclusive features, personalized insights, and most importantly, eligibility for our <strong>performance-based rewards program</strong>.
        </p>
        
        <h3>📈 Performance Fuels Rewards</h3>
        <p>
          Your ranking on Xnetous isn’t just a number—it’s your ticket to earnings! Our innovative ranking system evaluates your posts based on <strong>likes, shares, engagement, and impact</strong>. The better your content performs, the higher you rank, and the more you earn as a premium member.
        </p>
        
        <h3>💎 Rewards for the Best—But Only Premium Members</h3>
        <p>
          At Xnetous, we celebrate excellence, but only premium members can turn their rankings into rewards. Even if you’re ranked #1, you must be a premium member to receive payouts. <strong>Don’t miss out—subscribe and secure your place among the rewarded elite.</strong>
        </p>
        
        <h3>🚀 Exclusive Tools to Boost Your Success</h3>
        <p>
          Premium members gain access to advanced analytics, expert insights, and tools to optimize their performance. Whether you're aiming for the top spot or striving for steady growth, Xnetous equips you to succeed.
        </p>

        <h3>📊 How We Calculate Your Payout</h3>
        <p>
          At Xnetous, your success is directly tied to the engagement your posts receive. We calculate your payout based on your performance, which is determined by factors such as:
        </p>
        <ul>
          <li>💬 Engagement: The number of likes, comments, and shares your posts receive.</li>
          <li>📊 Total Impact: How widely your posts performaning across the platform with ranking, accuracy and score.</li>
          <li>🏆 Ranking: Your position must be in indexing, because Xnetous pay of first 20% of users only.</li>
        </ul>
        <p>
          Only premium members can receive payouts, and the better you perform, the more you earn. Your payout is calculated proportionally to your total engagement compared to the entire platform.
        </p>

        <h3>✨ Why Wait? Elevate Your Journey Today!</h3>
        <p>
          The world deserves to see your best work, and you deserve to be rewarded for it. Join Xnetous Premium now and turn your passion into a pathway for success.
        </p>
        <p>Let’s create something extraordinary—together. 🌟</p>
      </div>

      <div>
        <h2 className="demo-1">
          This quiz application is helpful for those who want to solve world problems.
        </h2>
        <QuizProblem className="quiz-problem-container" />
      </div>
    </div>
  );
}

export default Premium;
