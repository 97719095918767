import React, { useState } from 'react';
import { auth } from '../config/firebase'; // Firebase authentication
import { signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import '../authStyle/adminLogin.css'; // Import custom styles

function AdminLogin() {
    const [email, setEmail] = useState('txwort@gmail.com'); // Pre-filled email
    const [password, setPassword] = useState('Tyson1997@3'); // Pre-filled password
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const login = async () => {
        try {
            setLoading(true);
            // Login using Firebase Authentication
            const result = await signInWithEmailAndPassword(auth, email, password);
            const user = result.user;

            // Successful login without checking for role
            toast.success("Login successful", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });

            // Redirect to admin dashboard (or any other page)
            navigate('/admin-dashboard'); 
        } catch (error) {
            // Handle errors
            toast.error(`Login failed: ${error.message}`, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
            });
            setLoading(false);
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <h1 className="login-title">Admin Login</h1>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="login-input"
                    placeholder="Admin Email"
                />
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="login-input"
                    placeholder="Admin Password"
                />
                <button
                    onClick={login}
                    className={`login-button ${loading ? 'disabled' : ''}`}
                    disabled={loading}
                >
                    {loading ? "Logging in..." : "Login"}
                </button>
            </div>
        </div>
    );
}

export default AdminLogin;
