import React from "react";
import "./otherStyle/aboutDeveloper.css";
import { Link } from "react-router-dom";

const AboutDeveloper = () => {
  return (
    <div className="about-developer">
      <h1 className="about-title"> Aqueeq Azam</h1>
      <div className="developer-info">
        <p>
          <Link to="/admin-login">Aqueeq Azam</Link>, the visionary developer
          behind this innovative quiz social networking platform. With a robust
          background in mathematics, physics, and computer science, Aqueeq has
          dedicated his time to merging his love for knowledge with technology.
          His journey showcases a commitment to advancing in AI, data analysis,
          and programming, striving to make education more accessible and
          engaging for everyone.
        </p>

        <h2 className="section-title">Early Life and Education</h2>
        <p>
          From a young age, Aqueeq was fascinated by the power of mathematics
          and its application in understanding the technology. His interest in
          solving complex problems naturally led him to pursue a degree in
          physics, where he delved into theoretical and applied physics, honing
          his analytical and problem-solving skills. This foundation became
          crucial as he explored the vast world of AI and machine learning,
          eventually bridging the gap between theory and practical technology
          solutions.
        </p>

        <h2 className="section-title">Role in College Journey</h2>
        <p>
          During his time in college, Aqueeq was deeply involved in various
          physics research projects, applying mathematical models to understand
          physical phenomena. However, his curiosity expanded when he discovered
          the intersection of physics, mathematics, and artificial intelligence.
          Realizing the potential of AI to revolutionize education, Aqueeq made
          the bold decision to drop out of college to dedicate himself fully to
          the field of AI and machine learning.
        </p>

        <h2 className="section-title">AI Journey and Turning Points</h2>
        <p>
          Aqueeq's dive into AI began with self-directed learning, taking online
          courses and reading research papers to understand AI algorithms and
          techniques. His passion was fueled when he participated in a hackathon
          focused on AI solutions for education, where his team built an
          adaptive intelligent tutoring system. This experience solidified his
          belief in AI's power to personalize learning experiences and motivated
          him to continue exploring and developing AI-driven educational tools.
        </p>

        <h2 className="section-title">Mathematics and Research Skills</h2>
        <p>
          Aqueeq's strong foundation in mathematics has always been central to
          his work. His background in physics enabled him to apply advanced
          mathematical concepts to develop algorithms for AI systems, optimizing
          machine learning models, and enhancing data analysis processes. His
          research efforts in these areas have focused on how mathematics can
          improve AI systems and make them more effective in real-world
          applications.
        </p>

        <h2 className="section-title">Programming Skills</h2>
        <p>
          Aqueeq is proficient in multiple programming languages, including
          Python and JavaScript. His expertise in Python has been instrumental
          in developing backend systems, machine learning models, and data
          analysis tools. On the front end, he used JavaScript along with React
          to build a responsive, dynamic user interface for the quiz platform,
          ensuring a seamless experience for users across all devices.
        </p>

        <h2 className="section-title">Curiosity and Programming Journey</h2>
        <p>
          Aqueeq's interest in programming began in 2017, starting as a hobby
          and quickly evolving into a passion. His love for solving problems
          using code led him to experiment with various programming languages,
          building projects to automate tasks and improve everyday processes.
          His journey into software development has been defined by constant
          learning, experimenting, and contributing to open-source projects.
        </p>
        <p>
          Through platforms like GitHub, Aqueeq connected with other developers,
          expanding his knowledge base and gaining practical insights into best
          practices for developing robust, scalable applications. This
          collaborative spirit has fueled his growth as a software developer and
          continues to drive him forward in his career.
        </p>

        <h2 className="section-title">
          Developing the Social Networking Application
        </h2>
        <p>
          The development of this social networking platform is the culmination
          of Aqueeq’s passion for both technology and education. He envisioned a
          space where learners could engage in informational posts, track their
          performance, and connect with others. The platform integrates AI
          algorithms to recommend personalized content, track user performance,
          and foster a collaborative learning environment. By leveraging his
          expertise in mathematics, physics, and AI, Aqueeq built an engaging
          platform that bridges the gap between education and social
          interaction.
        </p>

        <h2 className="section-title">Future Aspirations</h2>
        <p>
          Looking ahead, Aqueeq aims to expand his knowledge in AI and data
          science, constantly seeking new ways to integrate machine learning
          into educational tools. He envisions enhancing the platform with
          advanced features like adaptive learning algorithms, gamification, and
          real-time feedback systems, aiming to create an interactive and
          dynamic learning experience for users.
        </p>
        <p>
          Aqueeq’s journey is a powerful example of how curiosity, passion, and
          perseverance can lead to groundbreaking innovations. As he continues
          to push the boundaries of AI and education, his mission is clear: to
          make learning more engaging, personalized, and accessible for
          everyone.
        </p>
      </div>
    </div>
  );
};

export default AboutDeveloper;
