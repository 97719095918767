/* import React, { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import { doc, getDoc, collection, query, where, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PostFacts from "../post/PostFacts";
import { useAuth } from "../../hooks/useAuth";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./posted.css";

function PostedFacts() {
  const { user, userId, loading: authLoading } = useAuth(useNavigate());
  const [postedFacts, setPostedFacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingFact, setEditingFact] = useState(null);

  useEffect(() => {
    if (!authLoading && user) {
      fetchPostedFacts(userId);
    }
  }, [authLoading, userId]);
  
  const fetchPostedFacts = async (userId) => {
    setLoading(true);
    try {
      // Fetch facts from the "factQuestions" collection where the userId matches
      const factsQuery = query(
        collection(fireDB, "factQuestions"),
        where("userId", "==", userId)
      );
      const factsSnapshot = await getDocs(factsQuery);
  
      // Extract fact data from the snapshot
      const factsList = factsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
  
      // Set the fetched facts to state
      setPostedFacts(factsList);
    } catch (error) {
      console.error("Error fetching facts:", error);
      toast.error("Failed to fetch facts. Please try again.");
    } finally {
      setLoading(false);
    }
  };
  

  const handleEditFact = (fact) => {
    setEditingFact(fact);
  };

  const handleUpdateFact = async (updatedFact) => {
    try {
      const factRef = doc(fireDB, "factQuestions", updatedFact.id);
      await updateDoc(factRef, {
        fact: updatedFact.fact,
        category: updatedFact.category,
        image: updatedFact.image,
        likes: updatedFact.likes,
        retweets: updatedFact.retweets,
        shares: updatedFact.shares,
        comments: updatedFact.comments,
      });

      fetchPostedFacts(userId);
      toast.success("Fact updated successfully!");
      setEditingFact(null); // Reset editing state
    } catch (error) {
      toast.error("Failed to update the fact.");
    }
  };

  const handleDeleteFact = (factId) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this fact?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const factRef = doc(fireDB, "factQuestions", factId);
              await deleteDoc(factRef);
              fetchPostedFacts(userId);
              toast.success("Fact deleted!");
            } catch (error) {
              toast.error("Failed to delete the fact.");
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  const calculateEngagementScore = (fact) => {
    return (
      (fact.likes || 0) * 1.0 +
      (fact.retweets || 0) * 1.2 +
      (fact.comments?.length || 0) * 2.0 +
      (fact.shares?.length || 0) * 3.0
    );
  };

  const calculateTotalEngagements = () => {
    return postedFacts.reduce((total, fact) => total + calculateEngagementScore(fact), 0);
  };

  if (authLoading || loading) {
    return <Spinner />;
  }

  return (
    <div className="dashboard-container">
      <PostFacts
        user={user}
        fetchPostedFacts={() => fetchPostedFacts(userId)}
        factToEdit={editingFact}
        setEditingFact={setEditingFact}
        handleUpdateFact={handleUpdateFact}
      />
      <div className="total-stats">
        <h3>Total Facts: {postedFacts.length}</h3>
        <h3>Total Engagements: {calculateTotalEngagements().toFixed(2)}</h3>
      </div>

      {postedFacts.length > 0 ? (
        <ul className="facts-list">
          {postedFacts.map((fact) => (
            <li key={fact.id} className="fact-item">
              <div className="fact-details">
                <p><strong>Fact:</strong> {fact.fact}</p>
                <p><strong>Category:</strong> {fact.category}</p>
                {fact.image ? (
                  <img src={fact.image} alt="Fact" className="fact-image" />
                ) : (
                  <div className="fact-placeholder">No Image Available</div>
                )}
                <div className="fact-performance">
                  <p><i className="fa fa-thumbs-up"></i> {fact.likes || 0} Likes</p>
                  <p><i className="fa fa-repeat"></i> {fact.retweets || 0} Retweets</p>
                  <p><i className="fa fa-share-alt"></i> {fact.shares?.length || 0} Shares</p>
                  <p><i className="fa fa-comment"></i> {fact.comments?.length || 0} Comments</p>
                  <p><strong>Engagement Score:</strong> {calculateEngagementScore(fact).toFixed(2)}</p>
                </div>
                <div className="fact-actions">
                  <button onClick={() => handleEditFact(fact)}>Edit</button>
                  <button onClick={() => handleDeleteFact(fact.id)}>Delete</button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No facts posted yet.</p>
      )}
    </div>
  );
}

export default PostedFacts;  */

import React, { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  deleteDoc,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PostFacts from "../post/PostFacts";
import { useAuth } from "../../hooks/useAuth";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./posted.css";

function PostedFacts() {
  const { user, userId, loading: authLoading } = useAuth(useNavigate());
  const [postedFacts, setPostedFacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingFact, setEditingFact] = useState(null);

  useEffect(() => {
    if (!authLoading && user) {
      fetchPostedFacts(userId);
    }
  }, [authLoading, userId]);

  // Fetch user's posted facts from Firestore
  const fetchPostedFacts = async (userId) => {
    setLoading(true);
    try {
      const factsQuery = query(
        collection(fireDB, "factQuestions"),
        where("userId", "==", userId)
      );
      const factsSnapshot = await getDocs(factsQuery);

      const factsList = factsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPostedFacts(factsList);
    } catch (error) {
      console.error("Error fetching facts:", error);
      toast.error("Failed to fetch facts. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle fact editing
  const handleEditFact = (fact) => {
    setEditingFact(fact);
  };

  // Handle fact update
  const handleUpdateFact = async (updatedFact) => {
    try {
      const factRef = doc(fireDB, "factQuestions", updatedFact.id);

      // Enforce the rule: If pinned, remove highlight & vice versa
      if (updatedFact.pinned) {
        updatedFact.highlighted = false;
      } else if (updatedFact.highlighted) {
        updatedFact.pinned = false;
      }

      await updateDoc(factRef, {
        fact: updatedFact.fact,
        category: updatedFact.category,
        image: updatedFact.image,
        likes: updatedFact.likes,
        retweets: updatedFact.retweets,
        shares: updatedFact.shares,
        comments: updatedFact.comments,
        pinned: updatedFact.pinned || false,
        highlighted: updatedFact.highlighted || false,
      });

      fetchPostedFacts(userId);
      toast.success("Fact updated successfully!");
      setEditingFact(null);
    } catch (error) {
      toast.error("Failed to update the fact.");
    }
  };

  // Handle fact deletion
  const handleDeleteFact = (factId) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this fact?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const factRef = doc(fireDB, "factQuestions", factId);
              await deleteDoc(factRef);
              fetchPostedFacts(userId);
              toast.success("Fact deleted!");
            } catch (error) {
              toast.error("Failed to delete the fact.");
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  // Toggle fact pin status
  const togglePinFact = async (factId, currentPinned, currentHighlighted) => {
    try {
      const factRef = doc(fireDB, "factQuestions", factId);

      await updateDoc(factRef, {
        pinned: !currentPinned,
        highlighted: !currentPinned ? false : currentHighlighted, // Unhighlight if pinning
      });

      fetchPostedFacts(userId);
      toast.success(
        `Fact ${!currentPinned ? "pinned" : "unpinned"} successfully!`
      );
    } catch (error) {
      toast.error("Failed to update pin status.");
    }
  };

  // Toggle fact highlight status
  const toggleHighlightFact = async (
    factId,
    currentHighlighted,
    currentPinned
  ) => {
    try {
      const factRef = doc(fireDB, "factQuestions", factId);

      await updateDoc(factRef, {
        highlighted: !currentHighlighted,
        pinned: !currentHighlighted ? false : currentPinned, // Unpin if highlighting
      });

      fetchPostedFacts(userId);
      toast.success(
        `Fact ${
          !currentHighlighted ? "highlighted" : "unhighlighted"
        } successfully!`
      );
    } catch (error) {
      toast.error("Failed to update highlight status.");
    }
  };

  // Calculate engagement score
  const calculateEngagementScore = (fact) => {
    return (
      (fact.likes || 0) * 1.0 +
      (fact.retweets || 0) * 1.2 +
      (fact.comments?.length || 0) * 2.0 +
      (fact.shares?.length || 0) * 3.0
    );
  };

  const calculateTotalEngagements = () => {
    return postedFacts.reduce(
      (total, fact) => total + calculateEngagementScore(fact),
      0
    );
  };

  if (authLoading || loading) {
    return <Spinner />;
  }

  return (
    <div className="dashboard-container">
      <PostFacts
        user={user}
        fetchPostedFacts={() => fetchPostedFacts(userId)}
        factToEdit={editingFact}
        setEditingFact={setEditingFact}
        handleUpdateFact={handleUpdateFact}
      />
      <div className="total-stats">
        <h3>Total Facts: {postedFacts.length}</h3>
        <h3>Total Engagements: {calculateTotalEngagements().toFixed(2)}</h3>
      </div>

      {postedFacts.length > 0 ? (
        <ul className="facts-list">
          {postedFacts.map((fact) => (
            <li
              key={fact.id}
              className={`fact-item ${fact.pinned ? "pinned" : ""} ${
                fact.highlighted ? "highlighted" : ""
              }`}
            >
              <div className="fact-details">
                {fact.pinned && <span className="pin-icon">📌</span>}
                {fact.highlighted && <span className="highlight-icon">⭐</span>}
                <p>
                  <strong>Fact:</strong> {fact.fact}
                </p>
                <p>
                  <strong>Category:</strong> {fact.category}
                </p>
                {fact.image ? (
                  <img src={fact.image} alt="Fact" className="fact-image" />
                ) : (
                  <div className="fact-placeholder">No Image Available</div>
                )}
                <div className="fact-performance">
                  <p>
                    <i className="fa fa-thumbs-up"></i> {fact.likes || 0} Likes
                  </p>
                  <p>
                    <i className="fa fa-repeat"></i> {fact.retweets || 0}{" "}
                    Retweets
                  </p>
                  <p>
                    <i className="fa fa-share-alt"></i>{" "}
                    {fact.shares?.length || 0} Shares
                  </p>
                  <p>
                    <i className="fa fa-comment"></i>{" "}
                    {fact.comments?.length || 0} Comments
                  </p>
                  <p>
                    <strong>Engagement Score:</strong>{" "}
                    {calculateEngagementScore(fact).toFixed(2)}
                  </p>
                </div>
                <div className="fact-actions">
                  <div
                    onClick={() =>
                      togglePinFact(fact.id, fact.pinned, fact.highlighted)
                    }
                    disabled={fact.highlighted}
                  >
                    {fact.pinned ? "🎯 Unpin" : "🎯 Pin"}
                  </div>
                  <div
                    onClick={() =>
                      toggleHighlightFact(
                        fact.id,
                        fact.highlighted,
                        fact.pinned
                      )
                    }
                    disabled={fact.pinned}
                  >
                    {fact.highlighted ? "🚀 Unhighlight" : "🚀 Highlight"}
                  </div>
                  <div onClick={() => handleEditFact(fact)}>✏️ Edit</div>
                  <div onClick={() => handleDeleteFact(fact.id)}>
                    🗑️ Delete
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No facts posted yet.</p>
      )}
    </div>
  );
}

export default PostedFacts;
