// src/pages/PostCategory.js
import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { fireDB } from "../config/firebase";
import Home from "../pages/home/Home";

function PostCategory() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        
        // Fetch categories from multiple collections
        const factSnapshot = await getDocs(collection(fireDB, "factQuestions"));
        const pollSnapshot = await getDocs(collection(fireDB, "pollQuestions"));
        const voteSnapshot = await getDocs(collection(fireDB, "voteQuestions"));
        const quizSnapshot = await getDocs(collection(fireDB, "quizQuestions"));

        const allCategories = [
          ...new Set([
            ...factSnapshot.docs.map(doc => doc.data().category),
            ...pollSnapshot.docs.map(doc => doc.data().category),
            ...voteSnapshot.docs.map(doc => doc.data().category),
            ...quizSnapshot.docs.map(doc => doc.data().category),
          ]),
        ];

        setCategories(allCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div>
     
      <Home categories={categories} loading={loading} />
    </div>
  );
}

export default PostCategory;
