import React from 'react';
import './offlinePage.css'

const OfflinePage = () => {
  return (
    <div className='offline-header'>
      <h2>Xnetous Waiting for Internet Connection...</h2>
      <p>It looks like you are not connected to the internet. Please check your connection and try again.</p>
      <button onClick={() => window.location.reload()}>Retry</button>
      <p>Here are some tips to help you reconnect:</p>
      <ul>
        <li>Check your Wi-Fi or mobile data connection.</li>
        <li>Restart your router or modem.</li>
        <li>Contact your internet service provider if the issue persists.</li>
      </ul>
    </div>
  );
};

export default OfflinePage;
