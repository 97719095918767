import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import ProfileQuiz from "./ProfileQuiz";
import ProfileVote from "./ProfileVote";
import ProfilePoll from "./ProfilePoll";
import ProfileFact from "./ProfileFact";
import "./profilepostStyle/postViewDisplay.css";

function PostViewDisplay() {
  const [activeTab, setActiveTab] = useState("profilefact");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Data states
  const [factData, setFactData] = useState([]);
  const [pollData, setPollData] = useState([]);
  const [voteData, setVoteData] = useState([]);
  const [quizData, setQuizData] = useState([]);

  const tabs = [
    { id: "profilefact", label: "Facts" },
    { id: "profilepoll", label: "Polls" },
    { id: "profilevote", label: "Votes" },
    { id: "profilequiz", label: "Quizzes" },
  ];

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const factSnapshot = await getDocs(collection(fireDB, "factQuestions"));
        const pollSnapshot = await getDocs(collection(fireDB, "pollQuestions"));
        const voteSnapshot = await getDocs(collection(fireDB, "voteQuestions"));
        const quizSnapshot = await getDocs(collection(fireDB, "quizQuestions"));

        setFactData(factSnapshot.docs.map((doc) => doc.data()));
        setPollData(pollSnapshot.docs.map((doc) => doc.data()));
        setVoteData(voteSnapshot.docs.map((doc) => doc.data()));
        setQuizData(quizSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Failed to load data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []); // Fetch only once on mount

  return (
    <div className="post-display-container">
      <div className="tab-navigation">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={`tab ${activeTab === tab.id ? "active" : ""}`}
            onClick={() => setActiveTab(tab.id)}
          >
            {tab.label}
          </div>
        ))}
      </div>

      {error && <div className="error-message">{error}</div>}

      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div className="tab-content">
          {activeTab === "profilefact" && <ProfileFact data={factData} />}
          {activeTab === "profilepoll" && <ProfilePoll data={pollData} />}
          {activeTab === "profilevote" && <ProfileVote data={voteData} />}
          {activeTab === "profilequiz" && <ProfileQuiz data={quizData} />}
        </div>
      )}
    </div>
  );
}

export default PostViewDisplay;
