import React, { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import { doc, getDoc, collection, query, where, getDocs, updateDoc, deleteDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PostVote from "../post/PostVote";
import { useAuth } from "../../hooks/useAuth";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./posted.css";

function PostedVote() {
  const { user, userId, loading: authLoading } = useAuth(useNavigate());
  const [postedVotes, setPostedVotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingVote, setEditingVote] = useState(null);

  useEffect(() => {
    if (!authLoading && user) {
      fetchPostedVotes(userId);
    }
  }, [authLoading, userId]);

  const fetchPostedVotes = async (userId) => {
    setLoading(true);
    try {
      const votesQuery = query(
        collection(fireDB, "voteQuestions"),
        where("userId", "==", userId)
      );
      const votesSnapshot = await getDocs(votesQuery);
      const votesList = votesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPostedVotes(votesList);
    } catch (error) {
      console.error("Error fetching votes:", error);
      toast.error("Failed to fetch votes. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditVote = (vote) => {
    setEditingVote(vote);
  };

  const handleUpdateVote = async (updatedVote) => {
    try {
      const voteRef = doc(fireDB, "voteQuestions", updatedVote.id);
      await updateDoc(voteRef, {
        question: updatedVote.question,
        options: updatedVote.options,
        likes: updatedVote.likes,
        shares: updatedVote.shares,
        comments: updatedVote.comments,
      });

      fetchPostedVotes(userId);
      toast.success("Vote updated successfully!");
      setEditingVote(null);
    } catch (error) {
      toast.error("Failed to update the vote.");
    }
  };

  const handleDeleteVote = (voteId) => {
    confirmAlert({
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this vote?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            try {
              const voteRef = doc(fireDB, "voteQuestions", voteId);
              await deleteDoc(voteRef);
              fetchPostedVotes(userId);
              toast.success("Vote deleted!");
            } catch (error) {
              toast.error("Failed to delete the vote.");
            }
          },
        },
        { label: "No" },
      ],
    });
  };

  const togglePinVote = async (voteId, pinned, highlighted) => {
    try {
      const voteRef = doc(fireDB, "voteQuestions", voteId);
      await updateDoc(voteRef, { pinned: !pinned });

      fetchPostedVotes(userId);
      toast.success(!pinned ? "Vote pinned! 🎯" : "Vote unpinned!");
    } catch (error) {
      toast.error("Failed to update pin status.");
    }
  };

  const toggleHighlightVote = async (voteId, highlighted, pinned) => {
    try {
      const voteRef = doc(fireDB, "voteQuestions", voteId);
      await updateDoc(voteRef, { highlighted: !highlighted });

      fetchPostedVotes(userId);
      toast.success(!highlighted ? "Vote highlighted! 🚀" : "Vote unhighlighted!");
    } catch (error) {
      toast.error("Failed to update highlight status.");
    }
  };

  const calculateEngagementScore = (vote) => {
    return (
      (vote.likes || 0) * 1.5 +
      (vote.comments?.length || 0) * 1.2 +
      (vote.shares || 0) * 1.3
    );
  };

  const calculateTotalEngagements = () => {
    return postedVotes.reduce((total, vote) => total + calculateEngagementScore(vote), 0);
  };

  if (authLoading || loading) {
    return <Spinner />;
  }

  return (
    <div className="dashboard-container">
      <PostVote
        user={user}
        fetchPostedVotes={() => fetchPostedVotes(userId)}
        voteToEdit={editingVote}
        setEditingVote={setEditingVote}
        handleUpdateVote={handleUpdateVote}
      />

      {/* Total Stats Section */}
      <div className="total-stats">
        <h3>Total Votes: {postedVotes.length}</h3>
        <h3>Total Engagements: {calculateTotalEngagements().toFixed(2)}</h3>
      </div>

      {/* Display Posted Votes */}
      {postedVotes.length > 0 ? (
        <ul className="votes-list">
          {postedVotes.map((vote) => (
            <li key={vote.id} className="vote-item">
              <div className="vote-details">
                <p><strong>Vote Question:</strong> {vote.question}</p>
                <p><strong>Options:</strong> {vote.options.join(", ")}</p>

                {/* Performance Metrics Section */}
                <div className="vote-performance">
                  <p><i className="fa fa-thumbs-up"></i> {vote.likes || 0} Likes</p>
                  <p><i className="fa fa-share-alt"></i> {vote.shares || 0} Shares</p>
                  <p><i className="fa fa-comment"></i> {vote.comments?.length || 0} Comments</p>
                  <p><strong>Engagement Score:</strong> {calculateEngagementScore(vote).toFixed(2)}</p>
                </div>

                {/* Action Buttons for Pin, Highlight, Edit, and Delete */}
                <div className="vote-actions">
                  <button 
                    onClick={() => togglePinVote(vote.id, vote.pinned, vote.highlighted)} 
                    disabled={vote.highlighted}
                  >
                    {vote.pinned ? "🎯 Unpin" : "🎯 Pin"}
                  </button>
                  <button 
                    onClick={() => toggleHighlightVote(vote.id, vote.highlighted, vote.pinned)} 
                    disabled={vote.pinned}
                  >
                    {vote.highlighted ? "🚀 Unhighlight" : "🚀 Highlight"}
                  </button>
                  <button onClick={() => handleEditVote(vote)}>✏️ Edit</button>
                  <button onClick={() => handleDeleteVote(vote.id)}>🗑️ Delete</button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No votes posted yet.</p>
      )}
    </div>
  );
}

export default PostedVote;

