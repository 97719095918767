import React, { useState, useEffect } from "react";
import Spinner from "../../../spinner/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../hooks/useAuth";
import { timeAgo } from "../../../utils/Time";
import { toast } from "react-toastify";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import VoteLike from "../../../components/like/VoteLike";
import VoteChart from "./VoteChart";
import VoteComment from "../../../components/comment/VoteComment";
import VoteRetweet from "../../../components/retweet/VoteRetweet";
import VoteFetch from "./VoteFetch";
import VoteShare from "../../../components/share/VoteShare";
import { fireDB } from "../../../config/firebase";
import { doc, updateDoc, increment, getDoc } from "firebase/firestore";

const PAGE_SIZE = 4;

const VoteQuestions = () => {
  const { user } = useAuth();
  const [voteQuestions, setVoteQuestions] = useState([]);
  const [hasVoted, setHasVoted] = useState({});  // Tracks whether the user has voted on each question
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [shareDropdownOpen, setShareDropdownOpen] = useState(null);
  const navigate = useNavigate();

  // Fetch votes from Firestore and update local state
  const fetchVoteQuestions = async () => {
    try {
      const querySnapshot = await fireDB.collection("voteQuestions").get();
      const voteData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setVoteQuestions(voteData);
    } catch (error) {
      console.error("Error fetching vote questions:", error);
    }
  };

  useEffect(() => {
    fetchVoteQuestions(); // Fetch initial questions on mount
  }, []);

  // Handle Voting
  const handleVote = async (voteId, option) => {
    if (!user) {
      toast.error("You must be logged in to vote.");
      return;
    }

    if (hasVoted[voteId]) {
      toast.error("You have already voted on this question.");
      return;
    }

    try {
      // Get the vote document from Firestore
      const voteDocRef = doc(fireDB, "voteQuestions", voteId);
      const voteDocSnap = await getDoc(voteDocRef);

      if (voteDocSnap.exists()) {
        const voteData = voteDocSnap.data();

        // Update the vote count in Firestore
        await updateDoc(voteDocRef, {
          [`votes.${option === "Yes" ? "agree" : "disagree"}`]: increment(1),
        });

        // Fetch the updated vote data from Firestore
        const updatedVoteDocSnap = await getDoc(voteDocRef);
        const updatedVoteData = updatedVoteDocSnap.data();

        // Update state after successful vote
        setVoteQuestions((prevVotes) =>
          prevVotes.map((vote) =>
            vote.id === voteId
              ? { ...vote, votes: updatedVoteData.votes }
              : vote
          )
        );
        setHasVoted((prevVotes) => ({
          ...prevVotes,
          [voteId]: option,
        }));

        toast.success(`You voted: ${option}`);
      } else {
        toast.error("Vote not found.");
      }
    } catch (error) {
      toast.error("Failed to cast your vote. Please try again.");
    }
  };

  // Toggle Share Dropdown
  const toggleShareDropdown = (voteId) => {
    setShareDropdownOpen((prev) => (prev === voteId ? null : voteId));
  };

  return (
    <>
      <VoteFetch
        lastVisible={lastVisible}
        setVoteQuestions={setVoteQuestions}
        setLastVisible={setLastVisible}
        setHasMore={setHasMore}
      />

      <InfiniteScroll
        dataLength={voteQuestions.length}
        next={() => {}} // Fetch next batch logic here
        hasMore={hasMore}
        loader={<Spinner />}
        endMessage={<p>No more posts to show</p>}
      >
        <div>
          {voteQuestions.length === 0 ? (
            <p>No posts available.</p>
          ) : (
            voteQuestions.map((vote) => {
              const totalVotes =
                (vote.votes?.agree || 0) + (vote.votes?.disagree || 0);

              return (
                <div key={vote.id} className="home-post-card">
                  {/* Post Header */}
                  <div className="home-post-header">
                    <div
                      className="user-avatar"
                      onClick={() =>
                        vote.user.id &&
                        navigate(
                          `/profile/${vote.user.name
                            .replace(/\s+/g, "")
                            .toLowerCase()}/${vote.user.id}`
                        )
                      }
                    >
                      <ProfileIcon
                        name={vote.user.name}
                        profileImage={vote.user.profileImage}
                      />
                    </div>

                    <div className="user-details">
                      <span className="user-name">{vote.user.name}</span>
                      <span className="home-post-timestamp">
                        {timeAgo(new Date(vote.createdAt.seconds * 1000))}
                      </span>
                    </div>
                  </div>

                  {/* Post Content */}
                  <div>
                    <p className="home-post-content">{vote.vote}</p>
                  </div>

                  {/* Poll Image */}
                  {vote.image && (
                    <div className="home-image-container">
                      <img
                        src={vote.image}
                        alt={vote.vote}
                        className="home-image"
                      />
                    </div>
                  )}

                  {/* Actions */}
                  <div className="home-post-actions">
                    <button
                      className="action-button"
                      onClick={() => handleVote(vote.id, "Yes")}
                      disabled={hasVoted[vote.id] === "Yes"}
                    >
                      ✅ Yes
                    </button>
                    <button
                      className="action-button"
                      onClick={() => handleVote(vote.id, "No")}
                      disabled={hasVoted[vote.id] === "No"}
                    >
                      ❌ No
                    </button>
                  </div>

                  {/* Show Vote Results only after voting */}
                  {hasVoted[vote.id] && (
                    <div className="home-post-analytics">
                      <VoteChart
                        yes={vote.votes?.agree || 0}
                        no={vote.votes?.disagree || 0}
                      />
                    </div>
                  )}

                  {/* Footer Icons */}
                  <div className="home-post-footer-icons">
                    <VoteLike voteId={vote.id} initialLikes={vote.likes} />
                    <VoteRetweet
                      voteId={vote.id}
                      initialRetweets={vote.retweets}
                    />
                    <VoteComment
                      voteId={vote.id}
                      vote={vote}
                      isAuthenticated={!!user}
                    />
                    <VoteShare
                      key={vote.id}
                      vote={vote}
                      toggleShareDropdown={toggleShareDropdown}
                      shareDropdownOpen={shareDropdownOpen}
                      setVotes={setVoteQuestions}
                    />
                  </div>
                </div>
              );
            })
          )}
        </div>
      </InfiniteScroll>
    </>
  );
};

export default VoteQuestions;
