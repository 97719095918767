import React from 'react';
import { Link } from 'react-router-dom';
import SocialLogin from './SocialLogin';
import '../authStyle/createAccount.css'

function CreateAccount() {
  return (
    <div className="center-wrapper">
      <div className="create-account-container">
        <h1>Welcome to Xnetous!</h1>
        <p>The next-gen social platform for knowing, learning, and connecting through insightful discussions and engaging posts!</p>
        <div className="social-login">
          <p>Create Account</p>
          <SocialLogin />
        </div>
        <p> or, use this 
          <Link to="/signup">Signup</Link>
        </p>
        <p>
          Already have an account? <Link to="/login">Login</Link>
        </p>
      </div>
    </div>
  );
}

export default CreateAccount;