import React, { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";

function ProfileVote() {
  const { currentUser, authLoading } = useAuth(); // Get user info
  const [loading, setLoading] = useState(true);
  const [postedVotes, setPostedVotes] = useState([]);
  const [pinnedVotes, setPinnedVotes] = useState([]);
  const [highlightedVotes, setHighlightedVotes] = useState([]);

  // Fetch votes only when user is authenticated
  useEffect(() => {
    if (!authLoading) {
      if (currentUser) {
        fetchPostedVotes(currentUser);
      } else {
        setLoading(false); // Stop loading if user is not logged in
      }
    }
  }, [currentUser, authLoading]);

  // Fetch user's posted votes from Firestore
  const fetchPostedVotes = async (currentUser) => {
    setLoading(true);
    try {
      const userRef = doc(fireDB, "users", currentUser.uid);
      const userDoc = await getDoc(userRef);

      // Ensure userDoc exists before accessing data
      const postedVotesIds = userDoc.exists() ? userDoc.data()?.postedVotes || [] : [];

      if (postedVotesIds.length > 0) {
        const votesQuery = query(
          collection(fireDB, "voteQuestions"), // Correct collection name
          where("userId", "==", currentUser.uid)
        );
        const votesSnapshot = await getDocs(votesQuery);
        const votesList = votesSnapshot.docs.map((doc) => ({
          id: doc.id,
          question: doc.data().question || "No question provided",
          options: doc.data().options || [], // Ensure options exist
          likes: doc.data().likes || 0,
          shares: doc.data().shares || 0,
          comments: doc.data().comments || [],
          isPinned: doc.data().isPinned || false,
          isHighlighted: doc.data().isHighlighted || false,
        }));

        setPostedVotes(votesList);
        setPinnedVotes(votesList.filter((vote) => vote.isPinned));
        setHighlightedVotes(votesList.filter((vote) => vote.isHighlighted));
      } else {
        setPostedVotes([]);
        setPinnedVotes([]);
        setHighlightedVotes([]);
      }
    } catch (error) {
      console.error("Error fetching votes:", error);
      toast.error("Failed to fetch votes. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Calculate engagement score
  const calculateEngagementScore = (vote) => {
    return (
      (vote.likes || 0) * 1.5 +
      (vote.comments?.length || 0) * 1.2 +
      (vote.shares || 0) * 1.3
    );
  };

  // Calculate total engagement
  const calculateTotalEngagements = () => {
    return postedVotes.reduce((total, vote) => {
      return total + calculateEngagementScore(vote);
    }, 0);
  };

  // Show loading spinner while data is fetching
  if (authLoading || loading) {
    return <Spinner />;
  }

  return (
    <div className="dashboard-container">
      {/* Total Stats Section */}
      <div className="total-stats">
        <h3>Total Votes: {postedVotes.length}</h3>
        <h3>Total Engagements: {calculateTotalEngagements().toFixed(2)}</h3>
      </div>

      {/* Pinned Votes */}
      <div className="pinned-votes">
        <h3>Pinned Votes</h3>
        {pinnedVotes.length > 0 ? (
          <ul className="votes-list">
            {pinnedVotes.map((vote) => (
              <li key={vote.id} className="vote-item">
                <div className="vote-details">
                  <p><strong>Vote Question:</strong> {vote.question}</p>
                  <p><strong>Options:</strong> {vote.options.join(", ")}</p>
                  <div className="vote-performance">
                    <p><i className="fa fa-thumbs-up"></i> {vote.likes} Likes</p>
                    <p><i className="fa fa-share-alt"></i> {vote.shares} Shares</p>
                    <p><i className="fa fa-comment"></i> {vote.comments.length} Comments</p>
                    <p><strong>Engagement Score:</strong> {calculateEngagementScore(vote).toFixed(2)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : <p>No pinned votes yet.</p>}
      </div>

      {/* Highlighted Votes */}
      <div className="highlighted-votes">
        <h3>Highlighted Votes</h3>
        {highlightedVotes.length > 0 ? (
          <ul className="votes-list">
            {highlightedVotes.map((vote) => (
              <li key={vote.id} className="vote-item">
                <div className="vote-details">
                  <p><strong>Vote Question:</strong> {vote.question}</p>
                  <p><strong>Options:</strong> {vote.options.join(", ")}</p>
                  <div className="vote-performance">
                    <p><i className="fa fa-thumbs-up"></i> {vote.likes} Likes</p>
                    <p><i className="fa fa-share-alt"></i> {vote.shares} Shares</p>
                    <p><i className="fa fa-comment"></i> {vote.comments.length} Comments</p>
                    <p><strong>Engagement Score:</strong> {calculateEngagementScore(vote).toFixed(2)}</p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : <p>No highlighted votes yet.</p>}
      </div>
    </div>
  );
}

export default ProfileVote;
