import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import ProfileIcon from "../../components/profile/ProfileIcon";
import { timeAgo } from "../../utils/Time";
import Spinner from "../../spinner/Spinner";
import InfiniteScroll from "react-infinite-scroll-component";
import './recommendationStyle/postRecommendation.css';
import LoadingSkeleton from "../../spinner/LoadingSkeleton";

const PostRecommendation = () => {
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [usersData, setUsersData] = useState({});

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const category = queryParams.get("category");
  const type = queryParams.get("type");

  const ITEMS_PER_PAGE = 2;

  // Fetching data based on category and type
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const [factSnapshot, pollSnapshot, voteSnapshot, quizSnapshot] = await Promise.all([
          getDocs(query(collection(fireDB, "factQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "pollQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "voteQuestions"), orderBy("createdAt", "desc"))),
          getDocs(query(collection(fireDB, "quizQuestions"), orderBy("createdAt", "desc"))),
        ]);

        const processSnapshot = (snapshot, type) => snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          type,
          userId: doc.data().userId || "",
        }));

        const combinedData = [
          ...processSnapshot(factSnapshot, "Fact"),
          ...processSnapshot(pollSnapshot, "Poll"),
          ...processSnapshot(voteSnapshot, "Vote"),
          ...processSnapshot(quizSnapshot, "Quiz"),
        ];

        // Fetch user details for all posts
        const userIds = [...new Set(combinedData.map((item) => item.userId).filter(Boolean))];
        const userSnapshots = await Promise.all(
          userIds.map((id) => getDocs(query(collection(fireDB, "users"), where("__name__", "==", id))))
        );

        const usersMap = {};
        userSnapshots.forEach((snapshot) => {
          snapshot.docs.forEach((doc) => {
            usersMap[doc.id] = doc.data();
          });
        });

        setUsersData(usersMap);

        // Attach user details to each post
        const enrichedData = combinedData.map((item) => ({
          ...item,
          user: usersMap[item.userId] || { name: "Anonymous", profileImage: "" }, // Default values
        }));

        // Filter posts by category and type
        let filtered = enrichedData;
        if (category) filtered = filtered.filter((item) => item.category === category);
        if (type) filtered = filtered.filter((item) => item.type.toLowerCase() === type.toLowerCase());

        setAllData(filtered);
        setFilteredData(filtered.slice(0, ITEMS_PER_PAGE)); // Initial data load
        setHasMoreData(filtered.length > ITEMS_PER_PAGE);
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [category, type]);

  // Fetch next set of posts
  const fetchNextPosts = () => {
    const nextPage = currentPage + 1;
    const startIndex = nextPage * ITEMS_PER_PAGE;
    const nextPosts = allData.slice(startIndex, startIndex + ITEMS_PER_PAGE);

    setFilteredData((prevData) => [...prevData, ...nextPosts]);
    setCurrentPage(nextPage);
    setHasMoreData(allData.length > nextPage * ITEMS_PER_PAGE);
  };

  const handleCardClick = (postId, postType) => {
    const lowerCaseType = postType.toLowerCase(); // Convert type to lowercase
    navigate(`/${lowerCaseType}/${postId}`); // Navigate to the appropriate route
  };

  if (loading) return <Spinner />;
  if (error) return <div>{error}</div>;

  return (
    <div className="">
      <InfiniteScroll
        dataLength={filteredData.length}
        next={fetchNextPosts}
        hasMore={hasMoreData}
        loader={<LoadingSkeleton />}
        endMessage={<p>No more posts available</p>}
      >
        <div className="recommendation-list">
          <h2>Recommended posts</h2>
          {filteredData.length > 0 ? (
            filteredData.map((item) => (
              <div
                key={item.id}
                className="recommendation-card"
                onClick={() => handleCardClick(item.id, item.type)}
              >
                <div className="recommendation-card-header">
                  <ProfileIcon
                    name={item.user?.name || "Anonymous"}
                    profileImage={item.user?.profileImage || ""}
                  />
                  <div className="recommendation-header-details">
                    <span className="user-name">{item.user?.name || "Anonymous"}</span>
                    <span className="created-at">{timeAgo(new Date(item.createdAt.seconds * 1000))}</span>
                  </div>
                </div>
                <p className="post-recommendad-title">{item.fact || item.poll || item.vote || item.quiz || "No Title"}</p>
                <p className="post-category">Category: {item.category || "Uncategorized"}</p>
              </div>
            ))
          ) : (
            <p>No posts found in this category.</p>
          )}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default PostRecommendation;
