import { useState, useEffect } from "react";
import { collection, getDocs, doc, getDoc, query, where, orderBy, limit, startAfter } from "firebase/firestore";
import { fireDB } from "../../../config/firebase";
import { getFunctions, httpsCallable } from "firebase/functions"; // Import Firebase Functions for ML
import { toast } from "react-toastify";

const PAGE_SIZE = 3;

const getSevenDaysAgo = () => {
  const sevenDaysAgo = new Date();
  sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
  return sevenDaysAgo;
};

const VoteFetch = ({ lastVisible, setVoteQuestions, setLastVisible, setHasMore }) => {
  const [loading, setLoading] = useState(false);

  // Firebase Functions to call the ML model
  const callMLModel = async (voteText) => {
    const functions = getFunctions();
    const predictVotePopularity = httpsCallable(functions, "predictVotePopularity");
    try {
      const result = await predictVotePopularity({ text: voteText });
      return result.data;
    } catch (error) {
      console.error("Error calling ML model:", error);
      return null;
    }
  };

  const fetchVoteQuestions = async () => {
    setLoading(true);

    const sevenDaysAgo = getSevenDaysAgo();
    try {
      const q = lastVisible
        ? query(
            collection(fireDB, "voteQuestions"),
            where("createdAt", ">=", sevenDaysAgo),
            orderBy("createdAt", "desc"),
            startAfter(lastVisible),
            limit(PAGE_SIZE)
          )
        : query(
            collection(fireDB, "voteQuestions"),
            where("createdAt", ">=", sevenDaysAgo),
            orderBy("createdAt", "desc"),
            limit(PAGE_SIZE)
          );

      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const voteQuestions = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const data = doc.data();

            // Call ML model to predict vote popularity
            const voteText = data.vote;
            const popularityPrediction = await callMLModel(voteText);

            return {
              id: doc.id,
              userId: data.userId || "Unknown User",
              createdAt: data.createdAt,
              vote: data.vote,
              image: data.image || "",
              yesCount: data.Yes || 0,
              noCount: data.No || 0,
              likes: data.likes || 0,
              retweets: data.retweets || [],
              comments: data.comments || [],
              link: data.link || "", // Handle link field
              tag: data.tag || [], // Handle tagged users
              popularityPrediction,
              rankingScore: (data.Yes || 0) - (data.No || 0),
            };
          })
        );

        const uniqueUserIds = [
          ...new Set(voteQuestions.map((q) => q.userId).filter((id) => id)),
        ];

        const userDocs = await Promise.all(
          uniqueUserIds.map((id) =>
            getDoc(doc(fireDB, "users", id)).then((userDoc) =>
              userDoc.exists() ? { id, ...userDoc.data() } : null
            )
          )
        );

        const userMap = Object.fromEntries(
          userDocs.filter(Boolean).map((user) => [user.id, user])
        );

        const newVotes = voteQuestions.map((vote) => ({
          ...vote,
          user: userMap[vote.userId] || { name: "Unknown User", profileImage: "" },
        }));

        newVotes.sort((a, b) => {
          if (b.rankingScore === a.rankingScore) {
            return b.createdAt.seconds - a.createdAt.seconds;
          }
          return b.rankingScore - a.rankingScore;
        });

        setVoteQuestions((prev) => {
          const existingIds = new Set(prev.map((v) => v.id));
          const uniqueVotes = newVotes.filter((v) => !existingIds.has(v.id));
          return [...prev, ...uniqueVotes];
        });

        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error("Error fetching vote questions:", error);
      toast.error("Failed to fetch vote questions.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchVoteQuestions();
  }, [lastVisible]);

  return loading ? <p>Loading...</p> : null;
};

export default VoteFetch;
