import React from "react";
import "./loadingSkeleton.css"; // Add custom styles if needed

const LoadingSkeleton = () => {
  return (
    <div className="loading-skeleton">
      <div className="skeleton-box" />
      <div className="skeleton-box" />
      <div className="skeleton-box" />
    </div>
  );
};

export default LoadingSkeleton;
