import React, { useEffect, useState } from "react";
import { collection, query, where, getDocs } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { useAuth } from "../../hooks/useAuth";
import ProfileIcon from "../../components/profile/ProfileIcon";
import Spinner from "../../spinner/Spinner";
import { timeAgo } from "../../utils/Time";
import './userStyle/userLiked.css'

const UserLiked = ({ navigate }) => {
  const {
    userId,
    loading: authLoading,
    userNames,
    error: authError,
    isProfileLocked,
  } = useAuth(navigate);

  const [likedPosts, setLikedPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Collections to fetch liked posts from
  const collections = [
    { name: "voteQuestions", type: "Vote" },
    { name: "factQuestions", type: "Fact" },
    { name: "pollQuestions", type: "Poll" },
    { name: "quizQuestions", type: "Quiz" },
  ];

  useEffect(() => {
    if (!userId) return;

    const fetchLikedPosts = async () => {
      try {
        setError(null);
        setLoading(true);

        // Fetch liked posts from all collections
        const postsPromises = collections.map(async ({ name, type }) => {
          const collectionRef = collection(fireDB, name);
          const q = query(collectionRef, where("likedBy", "array-contains", userId)); // Correct field
          const snapshot = await getDocs(q);

          return snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
            type,
            userName: userNames[doc.data().userId] || "Anonymous",
          }));
        });

        const postsByCollection = await Promise.all(postsPromises);
        const allLikedPosts = postsByCollection.flat();

        setLikedPosts(allLikedPosts);
      } catch (err) {
        console.error("Error fetching liked posts:", err);
        setError("Failed to load liked posts. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchLikedPosts();
  }, [userId, userNames]);

  if (authLoading || loading) {
    return (
      <div className="loader">
        <Spinner />
      </div>
    );
  }

  if (authError || error) {
    return <div className="error-message">{authError || error}</div>;
  }

  if (isProfileLocked) {
    return (
      <div className="locked-profile-message">
        Your profile is locked. Please unlock your profile to view liked posts.
      </div>
    );
  }

  return (
    <div className="user-liked-page">
      <h1>Your Liked Posts</h1>
      {likedPosts.length > 0 ? (
        <div className="liked-posts-list">
          {likedPosts.map((post) => (
            <div key={post.id} className="liked-post-card">
              <div className="post-header">
                <ProfileIcon
                  name={post.userName}
                  profileImage={post.user?.profileImage || ""}
                  className="profile-icon"
                />
                <div className="post-header-details">
                  <span className="user-name">{post.userName}</span>
                  <span className="created-at">
                    {post.createdAt?.seconds
                      ? timeAgo(new Date(post.createdAt.seconds * 1000))
                      : "Unknown Date"}
                  </span>
                </div>
              </div>
              <p className="post-title">
                {post.type}: {post.title || post.fact || post.poll || post.vote || post.quiz || "No Title"}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <p className="no-data-message">You haven't liked any posts yet.</p>
      )}
    </div>
  );
};

export default UserLiked;
