/* import React, { useState, useEffect } from "react";
import { fetchRoomDetails, sendMessageToRoom, editMessage, deleteMessage, pinMessage, addReactionToMessage } from "./FirebaseUtils";
import { useAuth } from "../../hooks/useAuth";
import "./communityStyle/chatRoom.css";

const ChatRoom = ({ roomId, onExitRoom }) => {
  const { user, userId, userNames } = useAuth();
  const [roomDetails, setRoomDetails] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [file, setFile] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const loadRoom = async () => {
      const details = await fetchRoomDetails(roomId);
      setRoomDetails(details.room);
      setMessages(details.messages);
    };
    if (roomId) loadRoom();
  }, [roomId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && !file) return;

    const newMsg = {
      text: newMessage.trim(),
      userId,
      sender: user.displayName || "Anonymous",
      time: new Date(),
      fileUrl: file ? URL.createObjectURL(file) : null,
    };

    const updatedMessages = await sendMessageToRoom(roomId, newMsg);
    if (updatedMessages) {
      setMessages(updatedMessages);
      setNewMessage("");
      setFile(null);
    }
  };

  const handleEditMessage = async (messageId) => {
    if (!editingMessage) return;
    const newText = editingMessage.text.trim();
    if (!newText) return;

    const updatedMessages = await editMessage(roomId, messageId, newText);
    if (updatedMessages) {
      setMessages(updatedMessages);
      setIsEditing(false);
      setEditingMessage(null);
    }
  };

  const handleDeleteMessage = async (messageId, senderId) => {
    if (userId !== senderId && userId !== roomDetails.creatorId) {
      alert("Only the sender or the admin can delete this message!");
      return;
    }

    const confirmDelete = window.confirm("Are you sure you want to delete this message?");
    if (confirmDelete) {
      const updatedMessages = await deleteMessage(roomId, messageId);
      if (updatedMessages) {
        setMessages(updatedMessages);
      }
    }
  };

  const handlePinMessage = async (messageId) => {
    const updatedMessages = await pinMessage(roomId, messageId);
    if (updatedMessages) {
      setMessages(updatedMessages);
    }
  };

  const handleAddReaction = async (messageId, emoji) => {
    const updatedMessages = await addReactionToMessage(roomId, messageId, emoji);
    if (updatedMessages) {
      setMessages(updatedMessages);
    }
  };

  return (
    <div className="chat-room">
      {roomDetails && (
        <div className="room-header">
          <h3>{roomDetails.topic}</h3>
          <p>Admin: {userNames[roomDetails.creatorId] || "Unknown Admin"}</p>
        </div>
      )}
      <button onClick={onExitRoom} className="exit-room-btn">Exit Room</button>

      <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
          className="input-field"
        />
        <input 
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          className="file-input"
        />
        <button type="submit" className="send-message-btn">Send</button>
      </form>

      {isEditing && (
        <div className="edit-message-modal">
          <textarea
            value={editingMessage ? editingMessage.text : ""}
            onChange={(e) => setEditingMessage({ ...editingMessage, text: e.target.value })}
            rows="4"
            placeholder="Edit your message"
          />
          <button onClick={() => handleEditMessage(editingMessage.id)} className="edit-save-btn">Save</button>
          <button onClick={() => setIsEditing(false)} className="cancel-edit-btn">Cancel</button>
        </div>
      )}

      <div className="messages-list">
        <h4>Messages:</h4>
        <ul>
          {messages.map((msg) => (
            <li key={msg.id}>
              <strong>{userNames[msg.userId] || "Unknown"}:</strong> {msg.text}
              {msg.fileUrl && <a href={msg.fileUrl} download>📎 Download</a>}
              <span className="timestamp">{new Date(msg.time).toLocaleString()}</span>

             
             
              <div className="reactions">
               
              {msg.userId === userId && !isEditing && (
                <button onClick={() => { setEditingMessage(msg); setIsEditing(true); }} className="edit-message-btn">✏ Edit</button>
              )}
              {(msg.userId === userId || userId === roomDetails.creatorId) && (
                <button onClick={() => handleDeleteMessage(msg.id, msg.userId)} className="delete-message-btn">🗑 Delete</button>
              )}

              <div onCick={() => handlePinMessage(msg.id)} className="pin-message-btn">📌 Pin</div>

             
              <div onClick={() => handleAddReaction(msg.id, "👍")} className="reaction-btn">👍 Like</div>
              <div onClick={() => handleAddReaction(msg.id, "❤️")} className="reaction-btn">❤️ Love</div>
              <div onClick={() => handleAddReaction(msg.id, "😂")} className="reaction-btn">😂 Joy</div>
              </div>
            
              {msg.reactions && msg.reactions.length > 0 && (
                <div className="reactions-list">
                  {msg.reactions.join(' ')}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChatRoom;  */













import React, { useState, useEffect } from "react";
import { fetchRoomDetails, sendMessageToRoom, editMessage, deleteMessage, pinMessage, addReactionToMessage } from "./FirebaseUtils";
import { useAuth } from "../../hooks/useAuth";
import { collection, addDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import "./communityStyle/chatRoom.css";

const ChatRoom = ({ roomId, onExitRoom }) => {
  const { user, userId, userNames } = useAuth();
  const [roomDetails, setRoomDetails] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [file, setFile] = useState(null);
  const [editingMessage, setEditingMessage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const loadRoom = async () => {
      const details = await fetchRoomDetails(roomId);
      setRoomDetails(details.room);
      setMessages(details.messages);
    };
    if (roomId) loadRoom();
  }, [roomId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim() && !file) return;

    const newMsg = {
      text: newMessage.trim(),
      userId,
      sender: user.displayName || "Anonymous",
      time: new Date(),
      fileUrl: file ? URL.createObjectURL(file) : null,
    };

    const updatedMessages = await sendMessageToRoom(roomId, newMsg);
    if (updatedMessages) {
      setMessages(updatedMessages);
      setNewMessage("");
      setFile(null);

      // Notify admin if the sender is not the admin
      if (userId !== roomDetails.creatorId) {
        const notification = {
          type: "message",
          message: `${user.displayName || "Anonymous"} sent a message`,
          timestamp: new Date(),
          senderId: userId,
          read: false,
        };

        // Add the notification to the admin's notification collection
        const adminNotificationsRef = collection(fireDB, "users", roomDetails.creatorId, "notifications");
        await addDoc(adminNotificationsRef, notification);
      }
    }
  };

  const handleEditMessage = async (messageId) => {
    if (!editingMessage) return;
    const newText = editingMessage.text.trim();
    if (!newText) return;

    const updatedMessages = await editMessage(roomId, messageId, newText);
    if (updatedMessages) {
      setMessages(updatedMessages);
      setIsEditing(false);
      setEditingMessage(null);
    }
  };

  const handleDeleteMessage = async (messageId, senderId) => {
    if (userId !== senderId && userId !== roomDetails.creatorId) {
      alert("Only the sender or the admin can delete this message!");
      return;
    }

    const confirmDelete = window.confirm("Are you sure you want to delete this message?");
    if (confirmDelete) {
      const updatedMessages = await deleteMessage(roomId, messageId);
      if (updatedMessages) {
        setMessages(updatedMessages);
      }
    }
  };

  const handlePinMessage = async (messageId) => {
    const updatedMessages = await pinMessage(roomId, messageId);
    if (updatedMessages) {
      setMessages(updatedMessages);
    }
  };

  const handleAddReaction = async (messageId, emoji) => {
    const updatedMessages = await addReactionToMessage(roomId, messageId, emoji);
    if (updatedMessages) {
      setMessages(updatedMessages);
    }
  };

  return (
    <div className="chat-room">
      {roomDetails && (
        <div className="room-header">
          <h3>{roomDetails.topic}</h3>
          <p>Admin: {userNames[roomDetails.creatorId] || "Unknown Admin"}</p>
        </div>
      )}
      <button onClick={onExitRoom} className="exit-room-btn">Exit Room</button>

      <form onSubmit={handleSendMessage}>
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type a message"
          className="input-field"
        />
        <input 
          type="file"
          onChange={(e) => setFile(e.target.files[0])}
          className="file-input"
        />
        <button type="submit" className="send-message-btn">Send</button>
      </form>

      {isEditing && (
        <div className="edit-message-modal">
          <textarea
            value={editingMessage ? editingMessage.text : ""}
            onChange={(e) => setEditingMessage({ ...editingMessage, text: e.target.value })}
            rows="4"
            placeholder="Edit your message"
          />
          <button onClick={() => handleEditMessage(editingMessage.id)} className="edit-save-btn">Save</button>
          <button onClick={() => setIsEditing(false)} className="cancel-edit-btn">Cancel</button>
        </div>
      )}

      <div className="messages-list">
        <h4>Messages:</h4>
        <ul>
          {messages.map((msg) => (
            <li key={msg.id}>
              <strong>{userNames[msg.userId] || "Unknown"}:</strong> {msg.text}
              {msg.fileUrl && <a href={msg.fileUrl} download>📎 Download</a>}
              <span className="timestamp">{new Date(msg.time).toLocaleString()}</span>

              <div className="reactions">
                {msg.userId === userId && !isEditing && (
                  <button onClick={() => { setEditingMessage(msg); setIsEditing(true); }} className="edit-message-btn">✏ Edit</button>
                )}
                {(msg.userId === userId || userId === roomDetails.creatorId) && (
                  <button onClick={() => handleDeleteMessage(msg.id, msg.userId)} className="delete-message-btn">🗑 Delete</button>
                )}

                <div onClick={() => handlePinMessage(msg.id)} className="pin-message-btn">📌 Pin</div>

                <div onClick={() => handleAddReaction(msg.id, "👍")} className="reaction-btn">👍 Like</div>
                <div onClick={() => handleAddReaction(msg.id, "❤️")} className="reaction-btn">❤️ Love</div>
                <div onClick={() => handleAddReaction(msg.id, "😂")} className="reaction-btn">😂 Joy</div>
              </div>
              {msg.reactions && msg.reactions.length > 0 && (
                <div className="reactions-list">
                  {msg.reactions.join(' ')}
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ChatRoom;

