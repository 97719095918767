import { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { fireDB, auth } from "../../config/firebase"; // Import Firebase services
import { onAuthStateChanged } from "firebase/auth";
import "./profileStyle/ProfileData.css";

const ProfileData = () => {
  const [user, setUser] = useState(null); // Authenticated user
  const [profileData, setProfileData] = useState(null); // Profile data from Firestore
  const [loading, setLoading] = useState(true); // Loading state

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        setUser(authUser);
      } else {
        setUser(null);
        setProfileData(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Fetch profile data from Firestore
  useEffect(() => {
    if (user && user.uid) {
      const fetchProfile = async () => {
        setLoading(true);
        try {
          const userDoc = doc(fireDB, "users", user.uid);
          const docSnap = await getDoc(userDoc);
          if (docSnap.exists()) {
            setProfileData(docSnap.data());
          } else {
            console.error("No such document found for the user.");
          }
        } catch (error) {
          console.error("Error fetching profile data:", error);
        } finally {
          setLoading(false);
        }
      };
      fetchProfile();
    }
  }, [user]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    return <p>You must be logged in to view your profile data.</p>;
  }

  // Ensure profileData is available before trying to render the component
  if (!profileData) {
    return <p>No profile data found.</p>;
  }

  return (
    <div className="profile-data">
      <div className="profile-header">
        <img
          src={profileData.profileImage || "default-avatar.png"}
          alt="Profile"
          className="profile-picture"
        />
        <div className="profile-details">
          <h2>{profileData.name}</h2>
          <p>{profileData.description}</p>
          <div className="profile-stats">
            <span>Role: {profileData.role}</span>
            <span>Institution: {profileData.institution}</span>
            <span>Country: {profileData.country}</span>
            <span>Date of Birth: {profileData.dob}</span>
          </div>
        </div>
      </div>

      {/* Profile Links Section */}
      <div className="profile-links">
        <p>Links:</p>
        {profileData.link ? (
          <a href={profileData.link} target="_blank" rel="noopener noreferrer">
            Website
          </a>
        ) : (
          <p>No website link provided</p>
        )}
        <br />
        {profileData.twitter ? (
          <a href={profileData.twitter} target="_blank" rel="noopener noreferrer">
            Twitter
          </a>
        ) : (
          <p>No Twitter link</p>
        )}
        <br />
        {profileData.instagram ? (
          <a href={profileData.instagram} target="_blank" rel="noopener noreferrer">
            Instagram
          </a>
        ) : (
          <p>No Instagram link</p>
        )}
        <br />
        {profileData.facebook ? (
          <a href={profileData.facebook} target="_blank" rel="noopener noreferrer">
            Facebook
          </a>
        ) : (
          <p>No Facebook link</p>
        )}
        <br />
        {profileData.youtube ? (
          <a href={profileData.youtube} target="_blank" rel="noopener noreferrer">
            YouTube
          </a>
        ) : (
          <p>No YouTube link</p>
        )}
      </div>
    </div>
  );
};

export default ProfileData;
