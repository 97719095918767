import { auth } from "../config/firebase";
import { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./LinkGeneration.css";
import Navbar from "../container/Navbar";
import Sidebar from "../container/Sidebar";

import Home from "../pages/home/Home";

import Trends from "../pages/trends/Trends";
import Explore from "../pages/explore/Explore";
import SearchResults from "../pages/explore/SearchResults";
import QuestionDetail from "../pages/home/quizfeed/QuestionDetail";
import VoteDetail from "../pages/home/votefeed/VoteDetail";
import PollDetail from "../pages/home/pollfeed/PollDetail";
import FactDetail from "../pages/home/factfeed/FactDetail";
import RecommendationPost from "../components/recommendation/RecommendationPost";

import Premium from "../pages/preminum/Premium";

import Dashboard from "../auth/Dashboard";
import PostDashboard from "../auth/PostDashboard";

import DiscussionRooms from "../pages/community/DiscussionRooms";

import Signup from "../auth/Signup";



import About from "../pages/other/About";
import AboutDeveloper from "../pages/other/AboutDeveloper";
import ContactUs from "../pages/other/ContactUs";
import Disclaimer from "../pages/other/Disclaimer";
import PrivacyPolicy from "../pages/other/PrivacyPolicy";
import Support from "../pages/other/Support";
import TermAndCondition from "../pages/other/TermAndCondition";

import PageScroll from "../pages/scroll/PageScroll";
import AdminLogin from "../auth/AdminLogin"
import NotFound from "../web/NotFound";
import OfflinePage from "../web/OfflinePage";
import SubscriptionPage from "../components/subscription/SubscriptionPage";
import AdsComponent from "../Ads/AdsComponents";
import Settings from "../settings/Settings";
import PasswordReset from "../settings/PasswordReset";
import AccountDeactivate from "../settings/AccountDeactivate";
import PostCategory from "../context/PostCategory";
import ThemeToggle from "../settings/ThemeToggle";
import UserProfile from "../components/profile/UserProfile";
import NotificationBell from "../notifications/NatificationBell";
import UserList from "../pages/chat/UserList";
import Chat from "../pages/chat/Chat";
import ProfileLock from "../settings/ProfileLock";
import UserLiked from "../components/user/UserLiked";
import SaveButton from "../hooks/useBookmark";
import Network from "../pages/network/Network";
import Login from "../auth/Login";
import CreateAccount from "../auth/CreateAccount";
import AdminDashboard from "../auth/AdminDashboard";
import Logout from "../settings/Logout";





function LinkGeneration() {
  const [user, setUser] = useState(null);
  
  const [isOnline, setIsOnline] = useState(navigator.onLine); 
  useEffect(() => { 
    const handleOnline = () => setIsOnline(true); 
    const handleOffline = () => setIsOnline(false); 
    window.addEventListener('online', handleOnline); 
    window.addEventListener('offline', handleOffline); 
    return () => { window.removeEventListener('online', handleOnline); 
    window.removeEventListener('offline', handleOffline);
  }; }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribe();
  }, []);

  const [searchQuery, setSearchQuery] = useState("");

  // This function will update the search query
  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  return (
    <div className="link-generation">
  <Router>
    <Navbar onSearch={handleSearch} />
    <Sidebar />
    {isOnline ? (
      <>
        <PageScroll />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/post-category" element={<PostCategory />} />
     
          <Route path="/trends" element={<Trends />} />
          <Route path="/explore" element={<Explore />} />
          <Route path="/search" element={<SearchResults />} />
          <Route path="/explore" element={<Explore searchQuery={searchQuery} />} />
          <Route path="/explore/:categoryName" element={<Explore />} />
          <Route path="/question/:id" element={<QuestionDetail />} />
          <Route path="/poll/:id" element={<PollDetail />} />
          <Route path="/fact/:id" element={<FactDetail />} />
          <Route path="/vote/:id" element={<VoteDetail />} />
          <Route path="/quiz/:id" element={<QuestionDetail />} />

          <Route path="/liked-post" element={<UserLiked />} />
          <Route path="/saved-post" element={<SaveButton />} />

          <Route path="/recommendation" element={<RecommendationPost />} />
          <Route path="/recommendation/:id" element={<RecommendationPost />} />
          <Route path="/premium" element={<Premium />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/dashboard/user/:uid" element={<Dashboard />} />
          <Route path="/dashboard" element={user ? <Dashboard /> : <Navigate to="/login" />} />
          <Route path="/subscription" element={<SubscriptionPage />} />
          <Route path="/discussion-rooms" element={<DiscussionRooms />} />

          <Route path="/post" element={<PostDashboard />} />

          <Route path="/notifications" element={<NotificationBell />} /> 

          <Route path="/chating/*" element={<UserList />} />
          <Route path="/chat/:conversationId" element={<Chat />} />

          <Route path="/create-ads" element={<AdsComponent />} />


          <Route path="/settings" element={<Settings />} />
          <Route path="/settings/profile-lock" element={<ProfileLock />} />
          <Route path="/settings/password-reset" element={<PasswordReset />} />
          <Route path="/settings/deactivate" element={<AccountDeactivate />} />
          <Route path="/settings/theme" element={<ThemeToggle />} />

          <Route path="/about" element={<About />} />
          <Route path="/about-xnetous-developer-aqueeqazam" element={<AboutDeveloper />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/support" element={<Support />} />
          <Route path="/terms-and-conditions" element={<TermAndCondition />} />

          <Route path="/create-account" element={<CreateAccount />} />
          <Route path="/signup" element={<Signup />} />
    
          <Route path="/login" element={<Login />} />
          <Route path="/settings/logout" element={<Logout />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/admin-dashboard" element={<AdminDashboard />} />


    
       <Route path="/profile/:username/:userId" element={<UserProfile />} />

       <Route path="/network" element={<Network />} />


       



          <Route path="/user-profile/:followerId" element={<UserProfile />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </>
    ) : (
      <OfflinePage />
    )}
  </Router>
</div>

  );
}

export default LinkGeneration;

















