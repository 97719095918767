import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import "./profileStyle/UserProfile.css";
import ProfileIcon from "./ProfileIcon";
import Ranking from "../rank/Ranking";
import Follow from "../connection/Follow";
import Followers from "../connection/Followers";
import Following from "../connection/Following";
import PostViewDisplay from "../profilepost/PostViewDisplay";
import Spinner from "../../spinner/Spinner";
import NetworkRole from "../../pages/network/NetworkRole";
import ClickTracking from "../../Ads/ClickTracking"; // Import ClickTracking function
import AdsDisplay from "../../Ads/AdsDisplay";

const UserProfile = () => {
  const { userId } = useParams(); // Access userId from URL parameter
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rank, setRank] = useState(null); // For rank
  const [score, setScore] = useState(null); // For score
  const [accuracy, setAccuracy] = useState(null); // For accuracy

  // Fetch user data and ranking info concurrently using Promise.all
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [userSnap, rankData] = await Promise.all([
          getDoc(doc(fireDB, "users", userId)),
          fetchRankingData(), // Simulate fetching rank, score, accuracy
        ]);

        if (userSnap.exists()) {
          const data = userSnap.data();
          setUserData({
            ...data,
            subscriptionStatus: !!data.subscription, // Convert subscription existence to boolean
          });
        } else {
          setError("No such user found.");
        }

        setRank(rankData.rank); // Example rank
        setScore(rankData.score); // Example score
        setAccuracy(rankData.accuracy); // Example accuracy
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("An error occurred while fetching the data.");
      } finally {
        setLoading(false);
      }
    };

    if (userId) fetchData();
  }, [userId]);

  const fetchRankingData = async () => {
    // Simulate fetching rank, score, and accuracy data
    return { rank: 5, score: 120.45, accuracy: 85.2 };
  };

  const handleAdClick = useCallback(async (adId) => {
    await ClickTracking(adId);
    console.log(`Ad ${adId} clicked`);
  }, []);

  if (loading)
    return (
      <p>
        <Spinner />
      </p>
    );
  if (error) return <p>{error}</p>;

  // Check if the profile is locked
  const isProfileLocked = userData?.isProfileLocked || false;

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="header-content">
          <div className="profile-info">
            <ProfileIcon
              name={userData.name || "User"}
              profileImage={userData.profileImage || ""} // Pass the profileImage here
              size="large"
            />

            <div className="user-info">
              {!isProfileLocked && (
                <Ranking rank={rank} score={score} accuracy={accuracy} />
              )}
            </div>

            <div className="user-details">
              <h2>{userData.name || "User"}</h2>
              <p>{userData.description || "No description provided"}</p>
              <p>Role: {userData.role || "No role provided"}</p>
              <p>
                Institution: {userData.institution || "No institution provided"}
              </p>
              <p>Country: {userData.country || "No country provided"}</p>
              <p>
                Date of Birth: {userData.dob || "No date of birth provided"}
              </p>
              <p>Link: {userData.link || "No link provided"}</p>
              <p>Twitter: {userData.twitter || "No Twitter provided"}</p>
              <p>Instagram: {userData.instagram || "No Instagram provided"}</p>
              <p>Facebook: {userData.facebook || "No Facebook provided"}</p>
              <p>YouTube: {userData.youtube || "No YouTube provided"}</p>
            </div>
          </div>
        </div>

        {!isProfileLocked && <Follow targetUserId={userId} />}

        <div className="connections-section">
        {/* Followers and Following Section */}
        {!isProfileLocked && (
          <div className="connections">
            <Followers userId={userId} />
            <Following userId={userId} />
          </div>
        )}
      </div>
      
      </div>

     

      <div className="posts-section">
        <PostViewDisplay />
      </div>

      <AdsDisplay handleAdClick={handleAdClick} />

      {/* Instagram Link Section */}
      <div className="social-links">
        {userData.instagram && !isProfileLocked && (
          <div className="instagram-link">
            <h4>Instagram:</h4>
            <a
              href={`https://www.instagram.com/${userData.instagram}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {userData.instagram}
            </a>
          </div>
        )}
      </div>

      <div className="recommendations">
        <NetworkRole role={userData.role} currentUserId={userData.id} />
      </div>
    </div>
  );
};

export default UserProfile;
