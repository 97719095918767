import React, { useState } from 'react';
import { auth } from '../config/firebase';
import { sendPasswordResetEmail, confirmPasswordReset } from 'firebase/auth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';


const PasswordReset = () => {
    const [email, setEmail] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [otpSent, setOtpSent] = useState(false); // Track if OTP has been sent
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Function to send OTP to email
    const sendOtp = async () => {
        setLoading(true);
        try {
            await sendPasswordResetEmail(auth, email);
            setOtpSent(true);
            toast.success("OTP sent to your email. Please check your inbox.", { autoClose: 3000 });
        } catch (error) {
            toast.error("Failed to send OTP: " + error.message, { autoClose: 3000 });
        } finally {
            setLoading(false);
        }
    };

    // Function to handle password change after OTP verification
    const changePassword = async () => {
        setLoading(true);
        try {
            const user = auth.currentUser;

            if (user) {
                await user.updatePassword(newPassword);
                toast.success("Password changed successfully", { autoClose: 3000 });
                navigate('/profile'); // Redirect to user profile or another page
            } else {
                toast.error("User not authenticated. Please log in.", { autoClose: 3000 });
            }
        } catch (error) {
            toast.error("Failed to change password: " + error.message, { autoClose: 3000 });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="settings-container">
            <div className="settings-box">
                <h1>Change Password</h1>

                {!otpSent ? (
                    <div>
                        <label htmlFor="email">Enter your email</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="input-field"
                            placeholder="Enter your email"
                        />
                        <button onClick={sendOtp} className="send-otp-button" disabled={loading}>
                            {loading ? 'Sending OTP...' : 'Send OTP'}
                        </button>
                    </div>
                ) : (
                    <div>
                        <label htmlFor="newPassword">Enter new password</label>
                        <input
                            type="password"
                            id="newPassword"
                            name="newPassword"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="input-field"
                            placeholder="Enter your new password"
                        />
                        <button onClick={changePassword} className="change-password-button" disabled={loading}>
                            {loading ? 'Changing Password...' : 'Change Password'}
                        </button>
                    </div>
                )}
                
            </div>
        </div>
    );
};

export default PasswordReset;
