import { useState, useEffect } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import { fireDB, storage, auth } from "../../config/firebase";
import { onAuthStateChanged } from "firebase/auth";
import "./profileStyle/ProfileUpdate.css";

const ProfileUpdate = ({ userData, onClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    role: "",
    institution: "",
    country: "",
    dob: "",
    link: "",
    twitter: "",
    instagram: "",
    facebook: "",
    youtube: "",
  });
  const [image, setImage] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Update formData only when userData changes
  useEffect(() => {
    if (userData) {
      setFormData((prev) => ({
        ...prev,
        ...userData,
      }));
    }
  }, [userData]);

  // Monitor authentication state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (authUser) => {
      setUser(authUser || null);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);

  // Handle input changes with minimal re-render
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  // Handle image selection
  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setImage(e.target.files[0]);
    }
  };

  // Handle profile update
  const handleUpdate = async () => {
    if (!user || !user.uid) {
      toast.error("User is not authenticated.");
      return;
    }

    try {
      const userDoc = doc(fireDB, "users", user.uid);
      let updates = { ...formData };

      // Upload image if selected
      if (image) {
        const imageRef = ref(storage, `profileImages/${user.uid}`);
        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);
        updates.profileImage = imageUrl;
      }

      await updateDoc(userDoc, updates);
      toast.success("Profile updated successfully");
      onClose();
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error(`Failed to update profile: ${error.message}`);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!user) {
    return <p>You must be logged in to update your profile.</p>;
  }

  return (
    <div className="profile-update-modal">
  <div className="profile-update-content">
    <h2>Update Profile</h2>
    <div className="form-group">
      <input
        type="text"
        name="name"
        placeholder="Name"
        value={formData.name}
        onChange={handleChange}
        autoFocus
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="description"
        placeholder="Description"
        value={formData.description}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="role"
        placeholder="Role"
        value={formData.role}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="institution"
        placeholder="Institution"
        value={formData.institution}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="country"
        placeholder="Country"
        value={formData.country}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="date"
        name="dob"
        placeholder="Date of Birth"
        value={formData.dob}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="link"
        placeholder="Link"
        value={formData.link}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="twitter"
        placeholder="Twitter"
        value={formData.twitter}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="instagram"
        placeholder="Instagram"
        value={formData.instagram}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="facebook"
        placeholder="Facebook"
        value={formData.facebook}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input
        type="text"
        name="youtube"
        placeholder="YouTube"
        value={formData.youtube}
        onChange={handleChange}
      />
    </div>
    <div className="form-group">
      <input type="file" accept="image/*" onChange={handleImageChange} />
    </div>
    <div className="button-group">
      <button className="update-button" onClick={handleUpdate}>Update Profile</button>
      <button className="cancel-button" onClick={onClose}>Cancel</button>
    </div>
  </div>
</div>

  );
};

export default ProfileUpdate;
