import React, { useEffect } from "react";
import { doc, updateDoc, increment } from "firebase/firestore";
import { fireDB } from "../../config/firebase";

const ProfileView = ({ userId }) => {
  useEffect(() => {
    const incrementProfileViews = async () => {
      if (!userId) return; // Ensure there's a valid userId
      try {
        const userRef = doc(fireDB, "users", userId);
        await updateDoc(userRef, {
          profileViews: increment(1), // Increment the profileViews field by 1
        });
      } catch (error) {
        console.error("Error incrementing profile views:", error);
      }
    };

    incrementProfileViews(); // Trigger the increment on mount
  }, [userId]);

  return null; // This component doesn't render anything
};

export default ProfileView;
