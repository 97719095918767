import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { AiFillLike, AiOutlineLike } from "react-icons/ai"; // Import icons
import { useSpring, animated } from "@react-spring/web"; // Import react-spring

const VoteLike = ({ voteId, initialLikes, initialLikedByUser }) => {
  const { user } = useAuth();
  const [likes, setLikes] = useState(initialLikes); // Vote's current like count
  const [hasLiked, setHasLiked] = useState(initialLikedByUser); // Check if the user already liked the vote

  // Animation for the like count and button
  const [likeCountAnimation, setLikeCountAnimation] = useSpring(() => ({
    opacity: 0,
    transform: "scale(0.5)",
    color: "black", // Default color
  }));

  const [buttonAnimation, setButtonAnimation] = useSpring(() => ({
    color: "black", // Default color of the like button
  }));

  useEffect(() => {
    const checkIfLiked = async () => {
      if (user) {
        const voteRef = doc(fireDB, "voteQuestions", voteId);
        const voteDoc = await getDoc(voteRef);
        const voteData = voteDoc.data();

        // Check if the user's ID is in the likedBy array
        if (voteData.likedBy && voteData.likedBy.includes(user.uid)) {
          setHasLiked(true);
        }
      }
    };

    checkIfLiked();
  }, [user, voteId]);

  const handleVoteLike = async () => {
    if (!user) {
      toast.error("You must be logged in to like.");
      return;
    }

    if (hasLiked) {
      toast.error("You've already liked this vote.");
      return;
    }

    try {
      const voteRef = doc(fireDB, "voteQuestions", voteId);
      const voteDoc = await getDoc(voteRef);
      const voteData = voteDoc.data();

      // Add user ID to the likedBy array
      const updatedLikedBy = [...(voteData.likedBy || []), user.uid];
      const updatedLikes = updatedLikedBy.length;

      await updateDoc(voteRef, {
        likes: updatedLikes,
        likedBy: updatedLikedBy,
      });

      // Trigger the animation and update the local state
      setLikes(updatedLikes);
      setHasLiked(true);

      // Animate the like count and button when liked
      setLikeCountAnimation({
        opacity: 1,
        transform: "scale(1)",
        color: "red", // Change color of the like count to red
        config: { tension: 150, friction: 10 },
      });

      setButtonAnimation({
        color: "red", // Change color of the like button to red
        config: { tension: 150, friction: 10 },
      });
    } catch (error) {
      toast.error("Error liking the vote. Please try again.");
    }
  };

  return (
    <div className="like-vote-container">
      <animated.div
        className={`like-button ${hasLiked ? "liked" : ""}`}
        onClick={handleVoteLike}
        disabled={hasLiked} // Disable the button if already liked
        style={{
          margin: "0px", color: hasLiked ? "red" : "black", // Apply red color to the button when liked
        }}
      >
        {hasLiked ? (
          <AiFillLike size={18} style={{ marginTop: '20px' }} />
        ) : (
          <AiOutlineLike size={19} />
        )}
      </animated.div>

      {/* Animated Like Count */}
      <animated.span style={likeCountAnimation}>
        {likes} {likes === 1 ? "like" : "likes"}
      </animated.span>
    </div>
  );
};

export default VoteLike;
