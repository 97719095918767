/* import React, { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import { doc, getDoc, collection, query, where, getDocs, deleteDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PostPoll from "../post/PostPoll";
import { useAuth } from "../../hooks/useAuth";
import "./posted.css";

function PostedPoll() {
  const { user, userId, loading: authLoading } = useAuth(useNavigate());
  const [postedPolls, setPostedPolls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingPoll, setEditingPoll] = useState(null); // Track which poll is being edited

  useEffect(() => {
    if (!authLoading && user) {
      fetchPostedPolls(userId);
    }
  }, [authLoading, userId]);

  const fetchPostedPolls = async (userId) => {
    setLoading(true);
    try {
      const pollsQuery = query(
        collection(fireDB, "pollQuestions"),
        where("userId", "==", userId)
      );
      const pollsSnapshot = await getDocs(pollsQuery);
      const pollsList = pollsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPostedPolls(pollsList);
    } catch (error) {
      console.error("Error fetching polls:", error);
      toast.error("Failed to fetch polls. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditPoll = (poll) => {
    setEditingPoll(poll); // Set the poll to be edited
  };

  const handleDeletePoll = async (pollId) => {
    try {
      const pollRef = doc(fireDB, "pollQuestions", pollId);
      await deleteDoc(pollRef);
      toast.success("Poll deleting!");
      fetchPostedPolls(userId); // Reload the polls after deletion
    } catch (error) {
      toast.error("Failed to delete poll.");
    }
  };

  const calculateEngagementScore = (poll) => {
    return (
      (poll.likes || 0) * 1.0 +
      (poll.retweets || 0) * 1.2 +
      (poll.comments?.length || 0) * 2.0 +
      (poll.shares || 0) * 3.0
    );
  };

  const calculateTotalEngagements = () => {
    return postedPolls.reduce((total, poll) => total + calculateEngagementScore(poll), 0);
  };

  if (authLoading || loading) {
    return <Spinner />;
  }

  return (
    <div className="dashboard-container">
      <PostPoll
        user={user}
        fetchPostedPolls={() => fetchPostedPolls(userId)} // Pass the fetch function to PostPoll component
        pollToEdit={editingPoll} // Pass the poll being edited (if any)
        setEditingPoll={setEditingPoll} // Reset the editing poll after updating
      />


      <div className="total-stats">
        <h3>Total Polls: {postedPolls.length}</h3>
        <h3>Total Engagements: {calculateTotalEngagements().toFixed(2)}</h3>
      </div>

      {postedPolls.length > 0 ? (
        <ul className="polls-list">
          {postedPolls.map((poll) => (
            <li key={poll.id} className="poll-item">
              <div className="poll-details">
                <p>
                  <strong>Polls:</strong> {poll.poll}
                </p>
                <p>
                  <strong>Options:</strong> {poll.options.join(", ")}
                </p>

            
                <div className="poll-performance">
                  <p>
                    <i className="fa fa-thumbs-up"></i> {poll.likes || 0} Likes
                  </p>
                  <p>
                    <i className="fa fa-thumbs-up"></i> {poll.retweets || 0} Responds
                  </p>
                  <p>
                    <i className="fa fa-share-alt"></i> {poll.shares || 0} Shares
                  </p>
                  <p>
                    <i className="fa fa-comment"></i> {poll.comments?.length || 0} Comments
                  </p>
                  <p>
                    <strong>Engagement Score:</strong> {calculateEngagementScore(poll).toFixed(2)}
                  </p>
                </div>

          
                <div className="poll-actions">
                  <button onClick={() => handleEditPoll(poll)}>Edit</button>
                  <button onClick={() => handleDeletePoll(poll.id)}>Delete</button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No polls posted yet.</p>
      )}
    </div>
  );
}

export default PostedPoll;  */











import React, { useEffect, useState } from "react";
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import { doc, getDoc, collection, query, where, getDocs, deleteDoc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PostPoll from "../post/PostPoll";
import { useAuth } from "../../hooks/useAuth";
import "./posted.css";
import { FaThumbtack, FaBullseye } from "react-icons/fa";

function PostedPoll() {
  const { user, userId, loading: authLoading } = useAuth(useNavigate());
  const [postedPolls, setPostedPolls] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingPoll, setEditingPoll] = useState(null);

  useEffect(() => {
    if (!authLoading && user) {
      fetchPostedPolls(userId);
    }
  }, [authLoading, userId]);

  const fetchPostedPolls = async (userId) => {
    setLoading(true);
    try {
      const pollsQuery = query(
        collection(fireDB, "pollQuestions"),
        where("userId", "==", userId)
      );
      const pollsSnapshot = await getDocs(pollsQuery);
      const pollsList = pollsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPostedPolls(pollsList);
    } catch (error) {
      console.error("Error fetching polls:", error);
      toast.error("Failed to fetch polls. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleEditPoll = (poll) => {
    setEditingPoll(poll);
  };

  const handleDeletePoll = async (pollId) => {
    try {
      const pollRef = doc(fireDB, "pollQuestions", pollId);
      await deleteDoc(pollRef);
      toast.success("Poll deleted!");
      fetchPostedPolls(userId);
    } catch (error) {
      toast.error("Failed to delete poll.");
    }
  };

  const togglePinPoll = async (poll) => {
    try {
      const pollRef = doc(fireDB, "pollQuestions", poll.id);
      await updateDoc(pollRef, { pinned: !poll.pinned });
      fetchPostedPolls(userId);
      toast.success(poll.pinned ? "Poll unpinned!" : "Poll pinned!");
    } catch (error) {
      toast.error("Failed to update poll pinning.");
    }
  };

  const toggleHighlightPoll = async (poll) => {
    try {
      const pollRef = doc(fireDB, "pollQuestions", poll.id);
      await updateDoc(pollRef, { highlighted: !poll.highlighted });
      fetchPostedPolls(userId);
      toast.success(poll.highlighted ? "Poll unhighlighted!" : "Poll highlighted!");
    } catch (error) {
      toast.error("Failed to update poll highlighting.");
    }
  };

  const calculateEngagementScore = (poll) => {
    return (
      (poll.likes || 0) * 1.0 +
      (poll.retweets || 0) * 1.2 +
      (poll.comments?.length || 0) * 2.0 +
      (poll.shares || 0) * 3.0
    );
  };

  const calculateTotalEngagements = () => {
    return postedPolls.reduce((total, poll) => total + calculateEngagementScore(poll), 0);
  };

  if (authLoading || loading) {
    return <Spinner />;
  }

  return (
    <div className="dashboard-container">
      <PostPoll
        user={user}
        fetchPostedPolls={() => fetchPostedPolls(userId)}
        pollToEdit={editingPoll}
        setEditingPoll={setEditingPoll}
      />

      <div className="total-stats">
        <h3>Total Polls: {postedPolls.length}</h3>
        <h3>Total Engagements: {calculateTotalEngagements().toFixed(2)}</h3>
      </div>

      {postedPolls.length > 0 ? (
        <ul className="polls-list">
          {postedPolls.map((poll) => (
            <li key={poll.id} className="poll-item">
              <div className="poll-details">
                <p><strong>Polls:</strong> {poll.poll}</p>
                <p><strong>Options:</strong> {poll.options.join(", ")}</p>
                <div className="poll-performance">
                  <p><i className="fa fa-thumbs-up"></i> {poll.likes || 0} Likes</p>
                  <p><i className="fa fa-thumbs-up"></i> {poll.retweets || 0} Responds</p>
                  <p><i className="fa fa-share-alt"></i> {poll.shares || 0} Shares</p>
                  <p><i className="fa fa-comment"></i> {poll.comments?.length || 0} Comments</p>
                  <p><strong>Engagement Score:</strong> {calculateEngagementScore(poll).toFixed(2)}</p>
                </div>
                <div className="poll-actions">
                  <button onClick={() => handleEditPoll(poll)}>Edit</button>
                  <button onClick={() => handleDeletePoll(poll.id)}>Delete</button>
                  <button onClick={() => togglePinPoll(poll)}>
                    {poll.pinned ? <FaThumbtack color="gold" /> : <FaThumbtack />} Pin
                  </button>
                  <button onClick={() => toggleHighlightPoll(poll)}>
                    {poll.highlighted ? <FaBullseye color="red" /> : <FaBullseye />} Highlight
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p>No polls posted yet.</p>
      )}
    </div>
  );
}

export default PostedPoll;

