import React from "react";
import Notifications from "./Notifications";

const NotificationBell = ({ userId }) => {
  return (
    <div className="notification-bell">
      <div className="dropdown">
        <Notifications userId={userId} />
      </div>
    </div>
  );
};

export default NotificationBell;
