import React from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const FactChart = ({ agreePercentage, disagreePercentage }) => {
  // Ensure agreePercentage and disagreePercentage are valid numbers
  const validAgreePercentage = !isNaN(agreePercentage) ? agreePercentage : 0;
  const validDisagreePercentage = !isNaN(disagreePercentage) ? disagreePercentage : 0;

  // Log the percentages for debugging
  console.log("Agree Percentage:", validAgreePercentage);
  console.log("Disagree Percentage:", validDisagreePercentage);

  return (
    <div style={styles.container}>
      <Line
        data={{
          labels: ['Agree', 'Disagree'],
          datasets: [
            {
              label: 'Votes (%)',
              data: [validAgreePercentage, validDisagreePercentage],
              backgroundColor: 'rgba(75,192,192,0.4)',
              borderColor: 'rgba(75,192,192,1)',
              borderWidth: 2,
              fill: false,
            },
          ],
        }}
        options={{
          responsive: true,
          plugins: {
            legend: {
              display: false,
            },
          },
          scales: {
            x: {
              title: {
                display: true,
                text: 'Options',
                font: { size: 14, weight: 'bold' },
              },
            },
            y: {
              title: {
                display: true,
                text: 'Percentage (%)',
                font: { size: 14, weight: 'bold' },
              },
              min: 0,
              max: 100,
            },
          },
        }}
      />
      <div style={styles.textContainer}>
        <p style={styles.text}>Agree: {validAgreePercentage.toFixed(2)}%</p>
        <p style={styles.text}>Disagree: {validDisagreePercentage.toFixed(2)}%</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '20px',
    borderRadius: '10px',
    backgroundColor: '#f4f4f4',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '400px',
    margin: 'auto',
    overflow: 'hidden',
  },
  textContainer: {
    marginTop: '15px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#333',
    textAlign: 'center',
  },
  text: {
    margin: '5px 0',
    color: '#555',
  },
};

export default FactChart;
