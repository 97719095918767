import React, { useState, useEffect } from "react";
import Spinner from "../../../spinner/Spinner";
import {
  doc,
  increment,
  setDoc,
  getDoc,
  updateDoc,
  runTransaction,
} from "firebase/firestore";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { fireDB } from "../../../config/firebase";
import { timeAgo } from "../../../utils/Time";
import { useAuth } from "../../../hooks/useAuth";
import ProfileIcon from "../../../components/profile/ProfileIcon";
import QuizLike from "../../../components/like/QuizLike";
import QuizPerformance from "./QuizChart";
import QuizRetweet from "../../../components/retweet/QuizRetweet";
import QuizComment from "../../../components/comment/QuizComment";
import fetchQuizQuestions from "./QuizFetch";
import QuizOption from "./QuizOption";

const QuizQuestions = () => {
  const { user, loading: userLoading } = useAuth();
  const [userId, setUserId] = useState(user?.uid);
  const [lastVisible, setLastVisible] = useState(null);
  const [hasMore, setHasMore] = useState(true);
  const [quizList, setQuizList] = useState([]);
  const [quizPerformances, setQuizPerformances] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (user) setUserId(user.uid);
  }, [user]);

  useEffect(() => {
    fetchQuizQuestions(lastVisible, setLastVisible, setHasMore, setQuizList);
  }, []);

  const handleAnswer = async (selectedOption, quizId) => {
    if (!quizId || !userId) {
      console.error("Quiz ID or User ID is missing.");
      return;
    }

    try {
      // Fetch quiz data
      const quizRef = doc(fireDB, "quizQuestions", quizId);
      const quizSnap = await getDoc(quizRef);

      if (!quizSnap.exists()) {
        console.error("Quiz not found.");
        return;
      }

      const quizData = quizSnap.data();
      const isCorrect = selectedOption === quizData.correctAnswer;

      // Immediate UI Feedback
      setQuizList((prev) =>
        prev.map((quiz) =>
          quiz.id === quizId
            ? {
                ...quiz,
                selectedAnswer: selectedOption,
                feedback: isCorrect ? "Correct!" : "Incorrect",
                correctAnswerShown: true,
              }
            : quiz
        )
      );

      // Transaction for atomic updates
      await runTransaction(fireDB, async (transaction) => {
        // Update quiz performance data
        const performanceRef = doc(fireDB, "quizPerformances", quizId);
        const performanceSnap = await transaction.get(performanceRef);
        const performanceData = performanceSnap.exists()
          ? performanceSnap.data()
          : { totalAnswers: 0, totalCorrect: 0, totalIncorrect: 0 };

        const updatedPerformance = {
          totalAnswers: performanceData.totalAnswers + 1,
          totalCorrect: isCorrect
            ? performanceData.totalCorrect + 1
            : performanceData.totalCorrect,
          totalIncorrect: isCorrect
            ? performanceData.totalIncorrect
            : performanceData.totalIncorrect + 1,
        };

        transaction.set(performanceRef, updatedPerformance, { merge: true });

        // Update user's performance
        const userRef = doc(fireDB, "users", userId);
        transaction.update(userRef, {
          totalAnswers: increment(1),
          totalCorrect: isCorrect ? increment(1) : increment(0),
          totalIncorrect: isCorrect ? increment(0) : increment(1),
        });

        // Update Firestore feedback for the quiz
        transaction.update(quizRef, {
          feedback: isCorrect ? "Correct!" : "Incorrect",
          correctAnswerShown: true,
        });

        // Update local performance state
        setQuizPerformances((prev) => ({
          ...prev,
          [quizId]: updatedPerformance,
        }));
      });
    } catch (error) {
      console.error("Error handling answer:", error);
    }
  };

  const renderQuizOptions = (options, quizId) => (
    <div className="options-container">
      {options.map((option, index) => (
        <button
          key={index}
          className={`option-button-${index % 2 === 0 ? "left" : "right"}`}
          onClick={() => handleAnswer(option, quizId)}
          disabled={quizList.find((quiz) => quiz.id === quizId)?.selectedAnswer} // Disable options if an answer is selected
        >
          {option}
        </button>
      ))}
    </div>
  );

  return (
    <InfiniteScroll
      dataLength={quizList.length}
      next={() =>
        fetchQuizQuestions(lastVisible, setLastVisible, setHasMore, setQuizList)
      }
      hasMore={hasMore}
      loader={
        <p>
          <Spinner />
        </p>
      }
      endMessage={<p>No more posts to show.</p>}
    >
      <div>
        {userLoading ? (
          <Spinner />
        ) : (
          quizList.map((quiz) => (
            <div key={quiz.id} className="home-post-card">
              <div className="home-post-header">
                <div
                  className="user-avatar"
                  onClick={() =>
                    quiz.user.id &&
                    navigate(
                      `/profile/${quiz.user.name
                        .replace(/\s+/g, "")
                        .toLowerCase()}/${quiz.user.id}`
                    )
                  }
                >
                  <ProfileIcon
                    name={quiz.user.name}
                    profileImage={quiz.user.profileImage}
                  />
                </div>
                <div className="user-details">
                  <span className="user-name">{quiz.user.name}</span>
                  <span className="home-post-timestamp">
                    {timeAgo(new Date(quiz.createdAt.seconds * 1000))}
                  </span>
                </div>

                <div>
          <QuizOption quizId={quiz.id} userId={user?.id} quizData={quiz} />
          </div>
              </div>

              <div className="home-post-content">
                <p>{quiz.quiz}</p>
              </div>

         
              {quiz.image && (
                <div className="home-image-container">
                  <img
                    src={quiz.image}
                    alt={quiz.quiz}
                    className="home-image"
                  />
                </div>
              )}

     
              <div className="home-post-actions">
                {quiz.options && renderQuizOptions(quiz.options, quiz.id)}

                <p
                  style={{
                    color: quiz.feedback === "Correct!" ? "green" : "red",
                  }}
                >
                  {quiz.feedback}
                </p>
                {quiz.correctAnswerShown && (
                  <p>
                    <strong>Correct Answer:</strong> {quiz.correctAnswer}
                  </p>
                )}
              </div>
              <div className="home-post-analytics">
                <QuizPerformance performanceData={quizPerformances[quiz.id]} />
              </div>
              <div className="home-post-footer-icons">
                <QuizLike
                  quizId={quiz.id}
                  userId={userId}
                  quiz={quiz}
                  setQuizList={setQuizList}
                />
                <QuizRetweet quizId={quiz.id} userId={userId} quiz={quiz} />
                <QuizComment quiz={quiz} isAuthenticated={true} />
              </div>
            </div>
          ))
        )}
      </div>
    </InfiniteScroll>
  );
};

export default QuizQuestions;  


































