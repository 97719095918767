import React, { useState, useEffect } from "react";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { toast } from "react-toastify";
import { useAuth } from "../../hooks/useAuth";
import { RiRepeatFill, RiRepeatLine } from "react-icons/ri"; // Import retweet icons
import { useSpring, animated } from "@react-spring/web"; // Import react-spring

const RetweetPoll = ({ pollId, initialRetweets, initialRetweetedByUser }) => {
  const { user } = useAuth();
  const [retweets, setRetweets] = useState(initialRetweets); // Poll's current retweet count
  const [hasRetweeted, setHasRetweeted] = useState(initialRetweetedByUser); // Check if the user already retweeted the poll

  // Animation for the retweet count and button
  const [retweetCountAnimation, setRetweetCountAnimation] = useSpring(() => ({
    opacity: 0,
    transform: "scale(0.5)",
    color: "black", // Default color
  }));

  const [buttonAnimation, setButtonAnimation] = useSpring(() => ({
    color: "black", // Default color of the retweet button
  }));

  useEffect(() => {
    const checkIfRetweeted = async () => {
      if (user) {
        const pollRef = doc(fireDB, "pollQuestions", pollId);
        const pollDoc = await getDoc(pollRef);
        
        if (!pollDoc.exists()) {
          toast.error("Poll not found.");
          return;
        }

        const pollData = pollDoc.data();

        // Check if the user's ID is in the retweetedBy array
        if (pollData.retweetedBy && pollData.retweetedBy.includes(user.uid)) {
          setHasRetweeted(true); // If user ID is in retweetedBy, mark as retweeted
        }
      }
    };

    checkIfRetweeted();
  }, [user, pollId]);

  const handlePollRetweet = async () => {
    if (!user) {
      toast.error("You must be logged in to retweet a poll.");
      return;
    }

    if (hasRetweeted) {
      toast.error("You have already retweeted this poll.");
      return;
    }

    try {
      const pollRef = doc(fireDB, "pollQuestions", pollId);
      const pollDoc = await getDoc(pollRef);
      
      if (!pollDoc.exists()) {
        toast.error("Poll not found.");
        return;
      }

      const pollData = pollDoc.data();

      // Add user ID to the retweetedBy array (users who retweeted the poll)
      const updatedRetweetedBy = [...(pollData.retweetedBy || []), user.uid];
      const updatedRetweets = updatedRetweetedBy.length;

      await updateDoc(pollRef, {
        retweets: updatedRetweets, // Update retweet count
        retweetedBy: updatedRetweetedBy, // Add user to retweetedBy array
      });

      // Trigger the animation and update the local state
      setRetweets(updatedRetweets);
      setHasRetweeted(true); // Set hasRetweeted to true to disable the button

      // Animate the retweet count and button when retweeted
      setRetweetCountAnimation({
        opacity: 1,
        transform: "scale(1)",
        color: "green", // Change color of the retweet count to green
        config: { tension: 150, friction: 10 },
      });

      setButtonAnimation({
        color: "green", // Change color of the retweet button to green
        config: { tension: 150, friction: 10 },
      });

      toast.success("You retweeted this poll!");
    } catch (error) {
      toast.error("Error retweeting the poll.");
    }
  };

  return (
    <div className="retweet-poll-container">
      <animated.div
        className={`retweet-button ${hasRetweeted ? "retweeted" : ""}`}
        onClick={handlePollRetweet}
        disabled={hasRetweeted} // Disable the button if already retweeted
        style={{
          color: hasRetweeted ? "green" : buttonAnimation.color, // Apply green color when retweeted
          cursor: hasRetweeted ? "not-allowed" : "pointer", // Change cursor when button is disabled
        }}
      >
        {hasRetweeted ? (
          <RiRepeatFill size={18} style={{ marginTop: '20px' }} />
        ) : (
          <RiRepeatLine size={19} />
        )}
      </animated.div>

      {/* Animated Retweet Count */}
      <animated.span style={retweetCountAnimation}>
        {retweets} {retweets === 1 ? "retweet" : "retweets"}
      </animated.span>
    </div>
  );
};

export default RetweetPoll;
