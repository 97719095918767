import React, { useState, useEffect } from "react";
import { addDoc, collection, getDocs } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { fireDB, storage } from "../config/firebase";
import { useAuth } from "../hooks/useAuth";
import AdsBudget from "./AdsBudget"; // Import the Budget component
import "./adStyle/adForm.css";

const AdForm = ({ navigate }) => {
  const { user, userId, loading, error } = useAuth(navigate);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [media, setMedia] = useState(null);
  const [mediaError, setMediaError] = useState(""); // For file size validation
  const [mediaUrl, setMediaUrl] = useState("");
  const [brandLink, setBrandLink] = useState("");
  const [category, setCategory] = useState("");
  const [ads, setAds] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(false); // Track payment status

  // Fetch ads with categories and clicks directly inside the ads collection
  useEffect(() => {
    const fetchData = async () => {
      try {
        const adSnapshot = await getDocs(collection(fireDB, "ads"));
        const adsData = adSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAds(adsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Helper function to validate URL
  const validateURL = (url) => {
    const pattern = /^(https?:\/\/)?([a-zA-Z0-9.-]+)(\/[^\s]*)?$/;
    return pattern.test(url);
  };

  // Handle media change with file size validation
  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > 5 * 1024 * 1024) { // 5 MB max file size
      setMediaError("File size exceeds 5MB.");
    } else if (file && file.type.startsWith("image/")) {
      setMedia(file);
      setImagePreview(URL.createObjectURL(file)); // Preview the selected image
      setMediaError(""); // Reset error if file is valid
    } else {
      setMediaError("Please select a valid image file.");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUploading(true);

    if (!user) {
      alert("You must be logged in to create an ad!");
      setUploading(false);
      return;
    }

    if (brandLink && !validateURL(brandLink)) {
      alert("Please enter a valid URL for the brand link.");
      setUploading(false);
      return;
    }

    if (!paymentStatus) {
      alert("You must complete the payment to post the ad!");
      setUploading(false);
      return;
    }

    try {
      let uploadedMediaUrl = "";
      if (media) {
        const storageRef = ref(storage, `ads/${userId}/${media.name}`);
        await uploadBytes(storageRef, media);
        uploadedMediaUrl = await getDownloadURL(storageRef);
      }

      const adData = {
        title,
        description,
        mediaUrl: uploadedMediaUrl,
        brandLink,
        category,
        createdAt: new Date(),
        userId,
        username: user.displayName || "Unknown User",
        clicks: 0, // Initialize click count to 0
      };

      await addDoc(collection(fireDB, "ads"), adData);
      alert("Ad created successfully!");
      setTitle("");
      setDescription("");
      setMedia(null);
      setMediaUrl("");
      setBrandLink("");
      setCategory("");
      setShowPreview(false); // Hide preview after submitting
    } catch (error) {
      console.error("Error creating ad:", error);
      alert("Failed to create ad.");
    } finally {
      setUploading(false);
    }
  };

  const handlePreviewToggle = () => {
    setShowPreview(!showPreview);
  };

  const handlePaymentSuccess = () => {
    setPaymentStatus(true); // Payment is successful
  };

  if (loading) {
    return <p>Loading user data...</p>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="ad-form">
        <div className="form-group">
          <label className="input-label">Title:</label>
          <input
            className="input-field"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="input-label">Description:</label>
          <textarea
            className="input-field"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label className="input-label">Category:</label>
          <input
            className="input-field"
            type="text"
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            required
            placeholder="Enter category for the ad"
          />
        </div>
        <div className="form-group">
          <label className="input-label">Media:</label>
          <input
            className="input-field"
            type="file"
            accept="image/*"
            onChange={handleMediaChange}
          />
          {mediaError && <p className="error-message">{mediaError}</p>}
        </div>
        <div className="form-group">
          <label className="input-label">Brand Link:</label>
          <input
            className="input-field"
            type="url"
            value={brandLink}
            onChange={(e) => setBrandLink(e.target.value)}
            placeholder="Enter brand link (Optional)"
          />
        </div>

        {showPreview && (
          <div className="preview">
            <h4>Preview:</h4>
            <p><strong>Title:</strong> {title}</p>
            <p><strong>Description:</strong> {description}</p>
            <p><strong>Category:</strong> {category}</p>
            <img src={imagePreview} alt="Preview" style={{ width: "200px", borderRadius: "8px" }} />
            <p><strong>Link:</strong> <a href={brandLink} target="_blank" rel="noopener noreferrer">{brandLink}</a></p>
            {/* Display the Budget component for payment */}
            <AdsBudget onPaymentSuccess={handlePaymentSuccess} />
          </div>
        )}

        <button type="button" onClick={handlePreviewToggle} className="preview-button">
          {showPreview ? "Hide Preview" : "Show Preview"}
        </button>

        {error && <p className="error-message">{error}</p>}
        <button
          type="submit"
          disabled={uploading || !title || !description || !category || !paymentStatus}
          className="submit-button"
        >
          {uploading ? "Uploading..." : "Create Ad"}
        </button>
      </form>

      <h3 className="ads-title">Your Ads</h3>
      <ul className="ads-list">
        {ads
          .filter((ad) => ad.userId === userId)
          .map((ad) => (
            <li key={ad.id} className="ad-item">
              <h4 className="ad-title">{ad.title}</h4>
              <p className="ad-description">{ad.description}</p>
              <p className="ad-category"><strong>Category:</strong> {ad.category}</p>
              <p className="ad-brand-link"><strong>Brand Link:</strong> {ad.brandLink}</p>
              <p className="ad-clicks"><strong>Clicks:</strong> {ad.clicks}</p> {/* Display clicks */}
              <div className="ad-media">
                <img
                  src={ad.mediaUrl}
                  alt={ad.title}
                  className="ad-image"
                />
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default AdForm;
