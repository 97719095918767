import React from 'react';

const PollChart = ({ options = [], polls = [0, 0] }) => {
  // Ensure polls is an array and contains valid numbers, default to [0, 0] if empty
  const validPolls = Array.isArray(polls) && polls.length === options.length ? polls : [0, 0];

  // Calculate the total votes (handling empty or invalid poll arrays)
  const totalPolls = validPolls.reduce((acc, poll) => acc + poll, 0);

  // Calculate percentages for each option (handling edge case for no votes)
  const percentages = validPolls.map((poll) =>
    totalPolls > 0 ? ((poll / totalPolls) * 100).toFixed(2) : 0
  );

  return (
    <div style={styles.container}>
      {/* Check if options are available and pass correctly */}
      {options.length > 0 && validPolls.length > 0 ? (
        <>
          <div style={styles.pollsContainer}>
            {options.map((option, index) => (
              <div key={index} style={styles.pollOption}>
                <span style={styles.pollLabel}>{option}:</span>
                <span style={styles.pollValue}>{percentages[index]}%</span>
              </div>
            ))}
          </div>
          
          <div style={styles.progressBarsContainer}>
            {percentages.map((percentage, index) => (
              <div key={index} style={styles.progressBarWrapper}>
                <div
                  style={{
                    ...styles.progressBar,
                    width: `${percentage}%`,
                    backgroundColor: index === 0 ? '#1DA1F2' : '#657786', // Twitter blue for 'Yes' and gray for 'No'
                  }}
                />
              </div>
            ))}
          </div>
        </>
      ) : (
        <p style={styles.noDataMessage}>No poll data available</p> // Show message if no data is available
      )}
    </div>
  );
};

const styles = {
  container: {
    textAlign: 'center',
    padding: '16px',
    borderRadius: '12px',
    backgroundColor: '#ffffff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    marginBottom: '16px',
    fontFamily: "'Helvetica Neue', Arial, sans-serif",
  },
  pollsContainer: {
    marginBottom: '12px',
  },
  pollOption: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '600',
    color: '#14171A', // Dark gray, similar to text color in Twitter
    marginBottom: '6px',
  },
  pollLabel: {
    fontWeight: '700',
    color: '#1DA1F2', // Twitter blue for emphasis
  },
  pollValue: {
    color: '#657786', // Lighter gray for values
    marginLeft: '4px',
  },
  progressBarsContainer: {
    marginTop: '12px',
  },
  progressBarWrapper: {
    width: '100%',
    height: '24px',
    backgroundColor: '#e1e8ed', // Light gray for background
    borderRadius: '12px',
    marginBottom: '8px',
  },
  progressBar: {
    height: '100%',
    borderRadius: '12px',
    transition: 'width 0.3s ease-in-out',
  },
  noDataMessage: {
    fontSize: '14px',
    color: '#657786', // Gray color for "No data" message
  },
};

export default PollChart;
