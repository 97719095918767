import React, { useEffect, useState } from "react"; 
import Spinner from "../../spinner/Spinner";
import { fireDB } from "../../config/firebase";
import { doc, getDoc, collection, query, where, getDocs, deleteDoc, updateDoc, arrayRemove } from "firebase/firestore";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import PostQuiz from "../post/PostQuiz";
import { useAuth } from "../../hooks/useAuth";
import "./posted.css";

function PostedQuiz() {
  const { user, userId, loading: authLoading } = useAuth(useNavigate());
  const [postedQuestions, setPostedQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingQuestion, setEditingQuestion] = useState(null);
  const [updatedQuestion, setUpdatedQuestion] = useState("");
  const [updatedOptions, setUpdatedOptions] = useState(["", "", "", ""]);
  const [updatedCorrectAnswer, setUpdatedCorrectAnswer] = useState("");
  const [updatedCategory, setUpdatedCategory] = useState("");
  const [updating, setUpdating] = useState(false); // Loading state for update

  useEffect(() => {
    if (!authLoading && user) {
      fetchPostedQuestions(userId);
    }
  }, [authLoading, userId]);

  const fetchPostedQuestions = async (userId) => {
    setLoading(true);
    try {
      const userRef = doc(fireDB, "users", userId);
      const userDoc = await getDoc(userRef);
      const postedQuestionsIds = userDoc.data()?.postedQuestions || [];

      if (postedQuestionsIds.length > 0) {
        const questionsQuery = query(
          collection(fireDB, "quizQuestions"),
          where("userId", "==", userId)
        );
        const questionsSnapshot = await getDocs(questionsQuery);
        const questionsList = questionsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPostedQuestions(questionsList);
      } else {
        setPostedQuestions([]);
      }
    } catch (error) {
      console.error("Error fetching posted questions:", error);
      toast.error("Failed to fetch posted questions. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (questionId) => {
    try {
      const questionRef = doc(fireDB, "quizQuestions", questionId);
      await deleteDoc(questionRef);

      const userRef = doc(fireDB, "users", userId);
      await updateDoc(userRef, {
        postedQuestions: arrayRemove(questionId),
      });

      setPostedQuestions((prevQuestions) =>
        prevQuestions.filter((q) => q.id !== questionId)
      );
      toast.success("Question deleted successfully!");
    } catch (error) {
      console.error("Error deleting question:", error);
      toast.error("Failed to delete question. Please try again.");
    }
  };

  const handleEdit = (question) => {
    setEditingQuestion(question.id);
    setUpdatedQuestion(question.question);
    setUpdatedOptions(question.options);
    setUpdatedCorrectAnswer(question.correctAnswer);
    setUpdatedCategory(question.category);
  };

  const handleUpdate = async () => {
    if (
      !updatedQuestion ||
      updatedOptions.some((option) => !option) ||
      !updatedCorrectAnswer ||
      !updatedCategory
    ) {
      toast.error("Please fill in all fields");
      return;
    }

    setUpdating(true);
    try {
      const questionRef = doc(fireDB, "quizQuestions", editingQuestion);
      await updateDoc(questionRef, {
        question: updatedQuestion,
        options: updatedOptions,
        correctAnswer: updatedCorrectAnswer,
        category: updatedCategory,
      });

      toast.success("Question updated successfully!");
      setEditingQuestion(null);
      await fetchPostedQuestions(userId);
    } catch (error) {
      console.error("Error updating question:", error);
      toast.error("Failed to update question. Please try again.");
    } finally {
      setUpdating(false);
    }
  };

  const handleCancelEdit = () => {
    setEditingQuestion(null);
  };

  const calculateEngagementScore = (quiz) => {
    return (
      (quiz.likes || 0) * 1.5 +
      (quiz.comments?.length || 0) * 1.2 +
      (quiz.shares || 0) * 1.3
    );
  };

  const calculateTotalEngagements = () => {
    return PostedQuiz.reduce((total, quiz) => total + calculateEngagementScore(quiz), 0);
  };


  if (authLoading || loading) {
    return <Spinner />;
  }

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <PostQuiz
          user={user}
          fetchPostedQuestions={() => fetchPostedQuestions(userId)}
        />

<div className="total-stats">
        <h3>Total Quizzes: {PostedQuiz.length}</h3>
        <h3>Total Engagements: {calculateTotalEngagements().toFixed(2)}</h3>
      </div>

        {/* Display Questions */}
        {postedQuestions.length > 0 ? (
          <ul className="questions-list">
            {postedQuestions.map((question) => (
              <li key={question.id} className="question-item">
                {editingQuestion === question.id ? (
                  <div className="edit-question-form">
                    <input
                      type="text"
                      value={updatedQuestion}
                      onChange={(e) => setUpdatedQuestion(e.target.value)}
                      placeholder="Question"
                    />
                    {updatedOptions.map((option, index) => (
                      <input
                        key={index}
                        type="text"
                        value={option}
                        onChange={(e) =>
                          setUpdatedOptions((prev) =>
                            prev.map((opt, idx) =>
                              idx === index ? e.target.value : opt
                            )
                          )
                        }
                        placeholder={`Option ${index + 1}`}
                      />
                    ))}
                    <input
                      type="text"
                      value={updatedCorrectAnswer}
                      onChange={(e) => setUpdatedCorrectAnswer(e.target.value)}
                      placeholder="Correct Answer"
                    />
                    <input
                      type="text"
                      value={updatedCategory}
                      onChange={(e) => setUpdatedCategory(e.target.value)}
                      placeholder="Category"
                    />
                    <button onClick={handleUpdate} disabled={updating}>
                      {updating ? "Updating..." : "Save Changes"}
                    </button>
                    <button onClick={handleCancelEdit}>Cancel</button>
                  </div>
                ) : (
                  <div>
                    <p>
                      <strong>Post:</strong> {question.quiz}
                    </p>
                    <p>
                      <strong>Options:</strong> {question.options.join(", ")}
                    </p>
                    <p>
                      <strong>Correct Answer:</strong> {question.correctAnswer}
                    </p>
                    <p>
                      <strong>Category:</strong> {question.category}
                    </p>
                    <div className="question-actions">
                      <button onClick={() => handleEdit(question)}>Edit</button>
                      <button onClick={() => handleDelete(question.id)}>
                        Delete
                      </button>
                    </div>
                  </div>
                )}
              </li>
            ))}
          </ul>
        ) : (
          <p>No questions posted yet.</p>
        )}
      </div>
    </div>
  );
}

export default PostedQuiz;
