import React, { useState, useEffect } from "react";
import { getDocs, collection } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import FactQuestions from "./factfeed/FactQuestions";
import VoteQuestions from "./votefeed/VoteQuestions";
import QuizQuestions from "./quizfeed/QuizQuestions";
import PollQuestions from "./pollfeed/PollQuestions";
import './home.css'

function Home() {
  const [activeTab, setActiveTab] = useState("facts");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State to store fetched data
  const [factData, setFactData] = useState([]);
  const [pollData, setPollData] = useState([]);
  const [voteData, setVoteData] = useState([]);
  const [quizData, setQuizData] = useState([]);

  // Tab options
  const tabs = [
    { id: "facts", label: "Facts" },
    { id: "polls", label: "Polls" },
    { id: "votes", label: "Votes" },
    { id: "quizzes", label: "Quizzes" },
  ];

  // Fetch data from Firebase Firestore
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        setLoading(true);
        const factSnapshot = await getDocs(collection(fireDB, "factQuestions"));
        const pollSnapshot = await getDocs(collection(fireDB, "pollQuestions"));
        const voteSnapshot = await getDocs(collection(fireDB, "voteQuestions"));
        const quizSnapshot = await getDocs(collection(fireDB, "quizQuestions"));

        setFactData(factSnapshot.docs.map((doc) => doc.data()));
        setPollData(pollSnapshot.docs.map((doc) => doc.data()));
        setVoteData(voteSnapshot.docs.map((doc) => doc.data()));
        setQuizData(quizSnapshot.docs.map((doc) => doc.data()));
      } catch (error) {
        console.error("Error fetching categories:", error);
        setError("Failed to load data. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="home-container">
      {/* Tab Navigation */}
      <div className="tab-navigation">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`tab ${activeTab === tab.id ? "active" : ""}`}
          >
            {tab.label}
          </div>
        ))}
      </div>

      {/* Error Message */}
      {error && <div className="error-message">{error}</div>}

      {/* Loading Spinner */}
      {loading ? (
        <div className="loading-spinner">Loading...</div>
      ) : (
        <div>
          {activeTab === "facts" && <FactQuestions data={factData} />}
          {activeTab === "polls" && <PollQuestions data={pollData} />}
          {activeTab === "votes" && <VoteQuestions data={voteData} />}
          {activeTab === "quizzes" && <QuizQuestions data={quizData} />}
        </div>
      )}
    </div>
  );
}

export default Home;
