import React, { useState, useEffect, useCallback } from "react";
import { collection, getDocs, query, updateDoc, doc, getDoc } from "firebase/firestore";
import { fireDB } from "../../config/firebase";
import { useAuth } from "../../hooks/useAuth";
import "./ranking.css";

const Ranking = ({ navigate }) => {
    const { userId, userNames, loading: authLoading, error } = useAuth(navigate);
    const [loading, setLoading] = useState(true);
    const [currentUserData, setCurrentUserData] = useState(null);

    // Function to calculate post engagement score
    const calculatePostEngagement = (post) => {
        const likes = (post.likes || 0) * 1.0;
        const retweets = (post.retweetedBy?.length || 0) * 1.2;
        const comments = (post.comments?.length || 0) * 2.0;
        const shares = (post.shares || 0) * 3.0;
        return likes + retweets + comments + shares;
    };

    // Function to fetch user scores, accuracy, and rank
    const fetchUsersAndScores = useCallback(async () => {
        try {
            setLoading(true);
            const questionCollections = ["factQuestions", "pollQuestions", "voteQuestions", "quizQuestions"];
            const allQuestions = [];

            // Fetch all posts from different collections
            for (const collectionName of questionCollections) {
                const questionQuery = query(collection(fireDB, collectionName));
                const questionSnapshot = await getDocs(questionQuery);
                questionSnapshot.forEach((doc) => allQuestions.push(doc.data()));
            }

            // Fetch user stats (total posts, engagement score) from Firestore
            const userStatsPromises = Object.keys(userNames).map(async (id) => {
                const userDoc = await getDoc(doc(fireDB, "users", id));
                const userData = userDoc.exists() ? userDoc.data() : {};
                return { id, ...userData };
            });

            const userStats = await Promise.all(userStatsPromises);

            // Calculate scores and accuracy for each user
            const usersWithScores = userStats.map((user) => {
                const userPosts = allQuestions.filter((post) => post.userId === user.id);
                const engagementScore = userPosts.reduce((total, post) => total + calculatePostEngagement(post), 0);

                // Calculate accuracy based on total posts and total engagement
                const totalPosts = userPosts.length || 0;
                const totalEngagement = userPosts.reduce((total, post) => total + calculatePostEngagement(post), 0);
                const accuracy = totalEngagement > 0 ? ((totalPosts / totalEngagement) * 100).toFixed(2) : "N/A";

                return {
                    id: user.id,
                    name: userNames[user.id] || "Unknown",
                    engagementScore,
                    accuracy,
                };
            });

            // Sort users by engagement score (highest first)
            const sortedUsers = usersWithScores.sort((a, b) => b.engagementScore - a.engagementScore);

            if (userId) {
                const currentUser = sortedUsers.find((user) => user.id === userId);
                if (currentUser) {
                    const userRank = sortedUsers.findIndex((user) => user.id === userId) + 1;

                    setCurrentUserData({
                        ...currentUser,
                        rank: userRank,
                    });

                    // Store rank, score, and accuracy in Firestore
                    const userRef = doc(fireDB, "users", userId);
                    await updateDoc(userRef, {
                        rank: userRank,
                        score: currentUser.engagementScore,
                        accuracy: currentUser.accuracy,
                    });
                }
            }
        } catch (err) {
            console.error("Error fetching ranking data:", err);
        } finally {
            setLoading(false);
        }
    }, [userNames, userId]);

    useEffect(() => {
        if (!authLoading && userNames) {
            fetchUsersAndScores();
        }
    }, [authLoading, userNames, fetchUsersAndScores]);

    return (
        <div className="ranking-container" style={{ padding: "1rem" }}>
            {loading || authLoading ? (
                <p>Loading info...</p>
            ) : error ? (
                <p>{error}</p>
            ) : (
                <>
                    {currentUserData && (
                        <div className="current-user-ranking">
                            <div className="ranking-item">
                                <span>Rank:</span>
                                <strong>{currentUserData.rank}</strong>
                            </div>
                            <div className="ranking-item">
                                <span>Engagement Score:</span>
                                <strong>{currentUserData.engagementScore.toFixed(2)}</strong>
                            </div>
                            <div className="ranking-item">
                                <span>Accuracy:</span>
                                <strong>{currentUserData.accuracy}%</strong>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Ranking;



