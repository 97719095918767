import React from 'react';
import { Link } from 'react-router-dom';  // Use Link for internal navigation
import './settings.css';  // Add your styles here

const Settings = () => {
  return (
    <div className="settings-container">
      <h2>Settings</h2>
      <div className="settings-links">
      <Link to="/settings/profile-lock" className="settings-link">
          Profile Lock
        </Link>
      <Link to="/settings/theme" className="settings-link">
          Change Theme
        </Link>
        <Link to="/settings/password-reset" className="settings-link">
          Change Password
        </Link>
        <Link to="/settings/deactivate" className="settings-link">
          Deactivate Account
        </Link>
        <Link to="/settings/logout" className="settings-link">
          Logout
        </Link>
      </div>
    </div>
  );
};

export default Settings;
