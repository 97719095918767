import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { toast } from 'react-toastify';
import { QRCodeCanvas } from 'qrcode.react';
import './subscriptionStyle/paymentGateway.css'

const PaymentGateway = ({ onPaymentSuccess }) => {
  const [paymentURL, setPaymentURL] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [paymentCompleted, setPaymentCompleted] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const { user, userId, loading, userNames } = useAuth(); // Fetch user data

  const handlePayment = () => {
    if (isProcessing) return;

    setIsProcessing(true);

    // Generate the UPI link for PhonePe payment
    const paymentLink = `upi://pay?pa=txwort@oksbi&pn=${userNames[userId] || 'User'}&am=126&cu=INR`;


    // Generate a unique transaction ID (in real-world, this should be generated by PhonePe or your backend)
    const generatedTransactionId = 'mock-transaction-id'; 
    setTransactionId(generatedTransactionId);

    // Set the UPI payment URL for the QR code
    setPaymentURL(paymentLink);
    toast.success('Payment initiated, please scan the QR code.');

    // Set isProcessing to false immediately after generating the payment link (no auto activation)
    setIsProcessing(false);
  };

  const trackPaymentStatus = async (transactionId) => {
    try {
      // Simulate a call to an API or backend to check the payment status
      console.log(`Tracking payment status for transaction ID: ${transactionId}`);

      // Simulate payment success here, you should replace this with an actual check
      setTimeout(() => {
        setPaymentCompleted(true); // Payment successful
        toast.success('Payment successful!');
        onPaymentSuccess(); // Notify parent component that payment is successful
      }, 5000); // Simulate payment confirmation delay (5 seconds)
    } catch (error) {
      console.error('Error tracking payment:', error);
      toast.error('Failed to track payment status.');
    }
  };

  return (
    <div className="payment-gateway">
      <h2>Payment Gateway</h2>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <div>
          <p>Welcome, {userNames[userId] || 'User'}!</p>
          <p>Your subscription amount is ₹126</p>
          <button onClick={handlePayment} disabled={isProcessing}>
            {isProcessing ? 'Processing Payment...' : 'Pay Now'}
          </button>
        </div>
      )}

      {/* Display QR Code for Payment */}
      {paymentURL && !isProcessing && !paymentCompleted && (
        <div className="qr-code-container">
          <h3>Scan to Pay</h3>
          <QRCodeCanvas value={paymentURL} size={256} />
        </div>
      )}

      {/* Show payment success message after payment completion */}
      {paymentCompleted && (
        <div>
          <h3>Subscription Activated</h3>
          <p>Your subscription is now active! Enjoy the premium features.</p>
        </div>
      )}

      {/* Button to manually check payment status */}
      {paymentURL && !paymentCompleted && (
        <button onClick={() => trackPaymentStatus(transactionId)}>Click to Check Payment Status</button>
      )}
    </div>
  );
};

export default PaymentGateway;

